import {ApolloError} from '@apollo/client'
import CopyIcon from '@rsuite/icons/legacy/Copy'
import PlusIcon from '@rsuite/icons/legacy/Plus'
import TrashIcon from '@rsuite/icons/legacy/Trash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Badge,
  Button,
  Col,
  Form,
  IconButton,
  Message,
  Modal,
  Row,
  toaster,
  Tooltip,
  Whisper
} from 'rsuite'

import {
  FullPoll,
  PollAnswerWithVoteCount,
  PollExternalVote,
  useCreatePollAnswerMutation,
  useDeletePollAnswerMutation
} from '../../api'

function getTotalUserVotesByAnswerId(poll: FullPoll, answerId: string): number {
  const answers = poll?.answers
  if (!answers) {
    return 0
  }
  return (
    answers
      .filter(answer => answer.id === answerId)
      .reduce((total, answer) => total + answer.votes, 0) || 0
  )
}

function getTotalExternalVotesByAnswerId(poll: FullPoll, answerId: string): number {
  const externalVoteSources = poll?.externalVoteSources
  if (!externalVoteSources) {
    return 0
  }
  return (
    externalVoteSources.reduce(
      (total, voteSource) =>
        total + getTotalExternalVoteSourcesByAnswerId(answerId, voteSource.voteAmounts),
      0
    ) || 0
  )
}

function getTotalExternalVoteSourcesByAnswerId(
  answerId: string,
  pollExternalVotes?: PollExternalVote[] | null
): number {
  if (!pollExternalVotes) {
    return 0
  }
  return (
    pollExternalVotes
      .filter(externalVote => externalVote.answerId === answerId)
      .reduce((total, externalVote) => total + externalVote.amount, 0) || 0
  )
}

function getTotalVotesByAnswerId(poll: FullPoll, answerId: string): number {
  return (
    getTotalUserVotesByAnswerId(poll, answerId) + getTotalExternalVotesByAnswerId(poll, answerId)
  )
}

interface PollAnswersProps {
  poll?: FullPoll
  onPollChange(poll: FullPoll): void
}

export function PollAnswers({poll, onPollChange}: PollAnswersProps) {
  const {t} = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [answerToDelete, setAnswerToDelete] = useState<PollAnswerWithVoteCount | undefined>(
    undefined
  )
  const [newAnswer, setNewAnswer] = useState<string>('')
  const [createAnswerMutation, {loading}] = useCreatePollAnswerMutation()
  const [deleteAnswerMutation] = useDeletePollAnswerMutation()

  const onErrorToast = (error: ApolloError) => {
    toaster.push(
      <Message type="error" showIcon closable duration={3000}>
        {error.message}
      </Message>
    )
  }

  /**
   * FUNCTIONS
   */
  async function createAnswer() {
    if (!poll) {
      return
    }
    if (!newAnswer) {
      toaster.push(
        <Message type="error" showIcon closable duration={3000}>
          {t('pollAnswer.answerMissing')}
        </Message>
      )
      return
    }
    const answer = await createAnswerMutation({
      variables: {
        pollId: poll.id,
        answer: newAnswer
      }
    })
    const savedAnswer = answer?.data?.createPollAnswer
    if (savedAnswer) {
      const updatedPoll = {...poll}
      updatedPoll.answers?.push(savedAnswer as PollAnswerWithVoteCount)
      onPollChange(updatedPoll)
    }
    setNewAnswer('')
  }

  async function deleteAnswer(): Promise<void> {
    setModalOpen(false)
    if (!answerToDelete) {
      return
    }
    const answer = await deleteAnswerMutation({
      variables: {
        deletePollAnswerId: answerToDelete.id
      },
      onError: onErrorToast
    })

    const updatedPoll = {...poll} as FullPoll | undefined
    // delete answer
    const deletedAnswer = answer?.data?.deletePollAnswer
    if (!deletedAnswer || !updatedPoll?.answers) {
      return
    }
    const deleteIndex = updatedPoll?.answers?.findIndex(
      tmpAnswer => tmpAnswer.id === deletedAnswer.id
    )
    if (deleteIndex < 0) {
      return
    }
    updatedPoll.answers.splice(deleteIndex, 1)

    // delete external vote sources
    updatedPoll.externalVoteSources?.forEach(tmpSource => {
      tmpSource.voteAmounts = tmpSource.voteAmounts?.filter(
        (tmpVoteAmount: PollExternalVote) => tmpVoteAmount.answerId !== deletedAnswer.id
      )
    })
    onPollChange(updatedPoll)
  }

  async function updateAnswer(updatedAnswer: PollAnswerWithVoteCount) {
    if (!poll) {
      return
    }
    const updatedAnswers = poll.answers ? [...poll.answers] : []
    const answerIndex = updatedAnswers.findIndex(tempAnswer => tempAnswer.id === updatedAnswer.id)
    if (answerIndex < 0) {
      return
    }
    updatedAnswers[answerIndex] = updatedAnswer

    onPollChange({
      ...poll,
      answers: updatedAnswers
    })
  }

  function generateUrlParams(answer: PollAnswerWithVoteCount): undefined | string {
    if (!poll) {
      return undefined
    }
    if (!answer) {
      return undefined
    }
    return `?pollId=${poll.id}&answerId=${answer.id}`
  }
  async function copyUrlParamsIntoClipboard(answer: PollAnswerWithVoteCount): Promise<void> {
    const urlParams = generateUrlParams(answer)
    if (!urlParams) {
      return
    }
    try {
      await navigator.clipboard.writeText(urlParams)
      toaster.push(
        <Message type="success" showIcon closable duration={3000}>
          {t('pollAnswer.urlCopied')}
        </Message>
      )
    } catch (e) {
      toaster.push(
        <Message type="error" showIcon closable duration={3000}>
          {t('pollAnswer.urlCopyingFailed')}
        </Message>
      )
    }
  }

  return (
    <>
      <Row style={{alignItems: 'center'}}>
        {poll?.answers?.map(answer => (
          <div key={`answer-${answer.id}`}>
            <Col xs={16} style={{paddingRight: '30px'}}>
              <Badge
                style={{width: '100%'}}
                content={`${getTotalVotesByAnswerId(poll, answer.id)} ${t('pollAnswer.votes')}`}>
                <Form.Control
                  name={`answer-${answer.id}`}
                  value={answer.answer || t('pollEditView.defaultAnswer')}
                  onChange={(value: string) => {
                    updateAnswer({
                      ...answer,
                      answer: value
                    })
                  }}
                />
              </Badge>
            </Col>
            {/* copy link btn */}
            <Col xs={8}>
              <IconButton
                icon={<TrashIcon />}
                circle
                size={'sm'}
                appearance="ghost"
                color="red"
                style={{marginRight: '10px'}}
                onClick={() => {
                  setAnswerToDelete(answer)
                  setModalOpen(true)
                }}
              />
              <Whisper speaker={<Tooltip>{t('pollAnswer.copyVoteUrl')}</Tooltip>}>
                <IconButton
                  icon={<CopyIcon />}
                  circle
                  size="sm"
                  appearance="ghost"
                  onClick={() => copyUrlParamsIntoClipboard(answer)}
                />
              </Whisper>
            </Col>
          </div>
        ))}
      </Row>
      {/* adding new poll answer */}
      <Row>
        <Col xs={16}>
          <Form.Control
            name="createNewFormAnswer"
            placeholder={t('pollAnswer.insertYourNewAnswer')}
            value={newAnswer}
            onChange={(value: string) => {
              setNewAnswer(value)
            }}
          />
        </Col>
        <Col xs={8}>
          <IconButton
            icon={<PlusIcon />}
            loading={loading}
            appearance="primary"
            onClick={createAnswer}>
            {t('pollEditView.addAndSaveNewAnswer')}
          </IconButton>
        </Col>
      </Row>

      {/* delete modal */}
      <Modal
        open={modalOpen}
        size="xs"
        onClose={() => {
          setModalOpen(false)
        }}>
        <Modal.Title>{t('pollAnswer.deleteModalTitle')}</Modal.Title>
        <Modal.Body>{t('pollAnswer.deleteModalBody', {answer: answerToDelete?.answer})}</Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={() => deleteAnswer()}>
            {t('pollAnswer.deleteBtn')}
          </Button>
          <Button appearance="subtle" onClick={() => setModalOpen(false)}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
