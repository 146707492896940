// THIS FILE IS AUTOGENERATED, EDIT WITH CAUTION
import {Node} from 'slate'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** A hexidecimal color value. */
  Color: string;
  RichText: Node[];
  Slug: string;
  /** A valid vote value */
  VoteValue: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  Value: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: File;
};

export type AllowedSettingVals = {
  __typename?: 'AllowedSettingVals';
  stringChoice?: Maybe<Array<Maybe<Scalars['String']>>>;
  boolChoice?: Maybe<Scalars['Boolean']>;
};

export type Article = {
  __typename?: 'Article';
  id: Scalars['ID'];
  shared: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  draft?: Maybe<ArticleRevision>;
  published?: Maybe<ArticleRevision>;
  pending?: Maybe<ArticleRevision>;
  latest: ArticleRevision;
};

export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  nodes: Array<Article>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ArticleFilter = {
  title?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  pending?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Scalars['ID']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type ArticleInput = {
  slug?: Maybe<Scalars['Slug']>;
  preTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  lead?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  properties: Array<PropertiesInput>;
  canonicalUrl?: Maybe<Scalars['String']>;
  imageID?: Maybe<Scalars['ID']>;
  authorIDs: Array<Scalars['ID']>;
  shared: Scalars['Boolean'];
  breaking: Scalars['Boolean'];
  hideAuthor: Scalars['Boolean'];
  socialMediaTitle?: Maybe<Scalars['String']>;
  socialMediaDescription?: Maybe<Scalars['String']>;
  socialMediaAuthorIDs: Array<Scalars['ID']>;
  socialMediaImageID?: Maybe<Scalars['ID']>;
  blocks: Array<BlockInput>;
};

export type ArticleNavigationLink = BaseNavigationLink & {
  __typename?: 'ArticleNavigationLink';
  label: Scalars['String'];
  article?: Maybe<Article>;
};

export type ArticleNavigationLinkInput = {
  label: Scalars['String'];
  articleID: Scalars['ID'];
};

export type ArticleRevision = {
  __typename?: 'ArticleRevision';
  revision: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  publishAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  hideAuthor: Scalars['Boolean'];
  preTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  properties: Array<Properties>;
  canonicalUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  image?: Maybe<Image>;
  authors: Array<Maybe<Author>>;
  breaking: Scalars['Boolean'];
  socialMediaTitle?: Maybe<Scalars['String']>;
  socialMediaDescription?: Maybe<Scalars['String']>;
  socialMediaAuthors: Array<Author>;
  socialMediaImage?: Maybe<Image>;
  blocks: Array<Block>;
};

export enum ArticleSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  PublishAt = 'PUBLISH_AT',
  PublishedAt = 'PUBLISHED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type ArticleTeaser = {
  __typename?: 'ArticleTeaser';
  style: TeaserStyle;
  image?: Maybe<Image>;
  preTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  article?: Maybe<Article>;
};

export type ArticleTeaserInput = {
  style: TeaserStyle;
  imageID?: Maybe<Scalars['ID']>;
  preTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  articleID: Scalars['ID'];
};

export type Author = {
  __typename?: 'Author';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['Slug'];
  url: Scalars['String'];
  links?: Maybe<Array<AuthorLink>>;
  bio?: Maybe<Scalars['RichText']>;
  jobTitle?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
};

export type AuthorConnection = {
  __typename?: 'AuthorConnection';
  nodes: Array<Author>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AuthorFilter = {
  name?: Maybe<Scalars['String']>;
};

export type AuthorInput = {
  name: Scalars['String'];
  slug: Scalars['Slug'];
  links?: Maybe<Array<AuthorLinkInput>>;
  bio?: Maybe<Scalars['RichText']>;
  jobTitle?: Maybe<Scalars['String']>;
  imageID?: Maybe<Scalars['ID']>;
};

export type AuthorLink = {
  __typename?: 'AuthorLink';
  title: Scalars['String'];
  url: Scalars['String'];
};

export type AuthorLinkInput = {
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum AuthorSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Name = 'NAME'
}

export type AuthProvider = {
  __typename?: 'AuthProvider';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type AvailablePaymentMethod = {
  __typename?: 'AvailablePaymentMethod';
  paymentMethods: Array<PaymentMethod>;
  paymentPeriodicities: Array<PaymentPeriodicity>;
  forceAutoRenewal: Scalars['Boolean'];
};

export type AvailablePaymentMethodInput = {
  paymentMethodIDs: Array<Scalars['String']>;
  paymentPeriodicities: Array<PaymentPeriodicity>;
  forceAutoRenewal: Scalars['Boolean'];
};

export type BaseNavigationLink = {
  label: Scalars['String'];
};

export type BildwurfAdBlock = {
  __typename?: 'BildwurfAdBlock';
  zoneID: Scalars['String'];
};

export type BildwurfAdBlockInput = {
  zoneID: Scalars['String'];
};

export type Block = RichTextBlock | ImageBlock | ImageGalleryBlock | ListicleBlock | FacebookPostBlock | FacebookVideoBlock | InstagramPostBlock | TwitterTweetBlock | VimeoVideoBlock | YouTubeVideoBlock | SoundCloudTrackBlock | PolisConversationBlock | TikTokVideoBlock | BildwurfAdBlock | EmbedBlock | HtmlBlock | PollBlock | CommentBlock | LinkPageBreakBlock | TitleBlock | QuoteBlock | TeaserGridBlock | TeaserGridFlexBlock;

export type BlockInput = {
  richText?: Maybe<RichTextBlockInput>;
  image?: Maybe<ImageBlockInput>;
  imageGallery?: Maybe<ImageGalleryBlockInput>;
  listicle?: Maybe<ListicleBlockInput>;
  title?: Maybe<TitleBlockInput>;
  quote?: Maybe<QuoteBlockInput>;
  facebookPost?: Maybe<FacebookPostBlockInput>;
  facebookVideo?: Maybe<FacebookVideoBlockInput>;
  instagramPost?: Maybe<InstagramPostBlockInput>;
  twitterTweet?: Maybe<TwitterTweetBlockInput>;
  vimeoVideo?: Maybe<VimeoVideoBlockInput>;
  youTubeVideo?: Maybe<YouTubeVideoBlockInput>;
  soundCloudTrack?: Maybe<SoundCloudTrackBlockInput>;
  polisConversation?: Maybe<PolisConversationBlockInput>;
  tikTokVideo?: Maybe<TikTokVideoBlockInput>;
  bildwurfAd?: Maybe<BildwurfAdBlockInput>;
  embed?: Maybe<EmbedBlockInput>;
  html?: Maybe<HtmlBlockInput>;
  poll?: Maybe<PollBlockInput>;
  comment?: Maybe<CommentBlockInput>;
  linkPageBreak?: Maybe<LinkPageBreakBlockInput>;
  teaserGrid?: Maybe<TeaserGridBlockInput>;
  teaserGridFlex?: Maybe<TeaserGridFlexBlockInput>;
};


export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  guestUsername?: Maybe<Scalars['String']>;
  guestUserImage?: Maybe<Image>;
  user?: Maybe<User>;
  tags?: Maybe<Array<Tag>>;
  authorType: CommentAuthorType;
  itemID: Scalars['ID'];
  itemType: CommentItemType;
  parentComment?: Maybe<Comment>;
  revisions: Array<CommentRevision>;
  source?: Maybe<Scalars['String']>;
  state: CommentState;
  rejectionReason?: Maybe<CommentRejectionReason>;
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
};

export enum CommentAuthorType {
  Author = 'Author',
  Team = 'Team',
  VerifiedUser = 'VerifiedUser',
  GuestUser = 'GuestUser'
}

export type CommentBlock = {
  __typename?: 'CommentBlock';
  filter: CommentBlockFilter;
  comments: Array<Comment>;
};

export type CommentBlockFilter = {
  __typename?: 'CommentBlockFilter';
  item?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Scalars['ID']>>;
  comments?: Maybe<Array<Scalars['ID']>>;
};

export type CommentBlockInput = {
  filter: CommentBlockInputFilter;
};

export type CommentBlockInputFilter = {
  item?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Scalars['ID']>>;
  comments?: Maybe<Array<Scalars['ID']>>;
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  nodes: Array<Comment>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CommentFilter = {
  item?: Maybe<Scalars['ID']>;
  tags?: Maybe<Array<Scalars['ID']>>;
  states?: Maybe<Array<CommentState>>;
};

export enum CommentItemType {
  Article = 'Article',
  Page = 'Page'
}

export type CommentRatingSystemAnswer = {
  __typename?: 'CommentRatingSystemAnswer';
  id: Scalars['ID'];
  ratingSystemId: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
  type: RatingSystemType;
};

export enum CommentRejectionReason {
  Misconduct = 'Misconduct',
  Spam = 'Spam'
}

export type CommentRevision = {
  __typename?: 'CommentRevision';
  text?: Maybe<Scalars['RichText']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type CommentRevisionUpdateInput = {
  text?: Maybe<Scalars['RichText']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
};

export enum CommentSort {
  ModifiedAt = 'ModifiedAt',
  CreatedAt = 'CreatedAt'
}

export enum CommentState {
  Approved = 'Approved',
  PendingApproval = 'PendingApproval',
  PendingUserChanges = 'PendingUserChanges',
  Rejected = 'Rejected'
}

export type CreatedToken = {
  __typename?: 'CreatedToken';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  token: Scalars['String'];
};

export type CreatePeerInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  hostURL: Scalars['String'];
  token: Scalars['String'];
};


export type DateFilter = {
  date?: Maybe<Scalars['DateTime']>;
  comparison: DateFilterComparison;
};

export enum DateFilterComparison {
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  Equal = 'EQUAL',
  Lower = 'LOWER',
  LowerOrEqual = 'LOWER_OR_EQUAL'
}


export type EmbedBlock = {
  __typename?: 'EmbedBlock';
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  styleCustom?: Maybe<Scalars['String']>;
  sandbox?: Maybe<Scalars['String']>;
};

export type EmbedBlockInput = {
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  styleCustom?: Maybe<Scalars['String']>;
  sandbox?: Maybe<Scalars['String']>;
};

export type ExternalNavigationLink = BaseNavigationLink & {
  __typename?: 'ExternalNavigationLink';
  label: Scalars['String'];
  url: Scalars['String'];
};

export type ExternalNavigationLinkInput = {
  label: Scalars['String'];
  url: Scalars['String'];
};

export type FacebookPostBlock = {
  __typename?: 'FacebookPostBlock';
  userID: Scalars['String'];
  postID: Scalars['String'];
};

export type FacebookPostBlockInput = {
  userID: Scalars['String'];
  postID: Scalars['String'];
};

export type FacebookVideoBlock = {
  __typename?: 'FacebookVideoBlock';
  userID: Scalars['String'];
  videoID: Scalars['String'];
};

export type FacebookVideoBlockInput = {
  userID: Scalars['String'];
  videoID: Scalars['String'];
};

export type FlexAlignment = {
  __typename?: 'FlexAlignment';
  i: Scalars['String'];
  x: Scalars['Int'];
  y: Scalars['Int'];
  w: Scalars['Int'];
  h: Scalars['Int'];
  static: Scalars['Boolean'];
};

export type FlexAlignmentInput = {
  i: Scalars['String'];
  x: Scalars['Int'];
  y: Scalars['Int'];
  w: Scalars['Int'];
  h: Scalars['Int'];
  static: Scalars['Boolean'];
};

export type FlexTeaser = {
  __typename?: 'FlexTeaser';
  alignment: FlexAlignment;
  teaser?: Maybe<Teaser>;
};

export type FlexTeaserInput = {
  teaser?: Maybe<TeaserInput>;
  alignment: FlexAlignmentInput;
};

export type FullCommentRatingSystem = {
  __typename?: 'FullCommentRatingSystem';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  answers: Array<CommentRatingSystemAnswer>;
};

export type FullPoll = {
  __typename?: 'FullPoll';
  id: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
  opensAt: Scalars['DateTime'];
  closedAt?: Maybe<Scalars['DateTime']>;
  answers?: Maybe<Array<PollAnswerWithVoteCount>>;
  externalVoteSources?: Maybe<Array<PollExternalVoteSource>>;
};

export type GalleryImageEdge = {
  __typename?: 'GalleryImageEdge';
  caption?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
};

export type GalleryImageEdgeInput = {
  caption?: Maybe<Scalars['String']>;
  imageID?: Maybe<Scalars['ID']>;
};

export type HtmlBlock = {
  __typename?: 'HTMLBlock';
  html?: Maybe<Scalars['String']>;
};

export type HtmlBlockInput = {
  html?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  filename?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  fileSize: Scalars['Int'];
  extension: Scalars['String'];
  mimeType: Scalars['String'];
  format: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  focalPoint?: Maybe<Point>;
  url?: Maybe<Scalars['String']>;
  transformURL?: Maybe<Scalars['String']>;
};


export type ImageTransformUrlArgs = {
  input?: Maybe<ImageTransformation>;
};

export type ImageBlock = {
  __typename?: 'ImageBlock';
  image?: Maybe<Image>;
  caption?: Maybe<Scalars['String']>;
};

export type ImageBlockInput = {
  caption?: Maybe<Scalars['String']>;
  imageID?: Maybe<Scalars['ID']>;
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  nodes: Array<Image>;
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
};

export type ImageFilter = {
  title?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type ImageGalleryBlock = {
  __typename?: 'ImageGalleryBlock';
  images: Array<GalleryImageEdge>;
};

export type ImageGalleryBlockInput = {
  images?: Maybe<Array<Maybe<GalleryImageEdgeInput>>>;
};

export enum ImageOutput {
  Png = 'PNG',
  Jpeg = 'JPEG',
  Webp = 'WEBP'
}

export enum ImageRotation {
  Auto = 'AUTO',
  Rotate_0 = 'ROTATE_0',
  Rotate_90 = 'ROTATE_90',
  Rotate_180 = 'ROTATE_180',
  Rotate_270 = 'ROTATE_270'
}

export enum ImageSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export type ImageTransformation = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  rotation?: Maybe<ImageRotation>;
  quality?: Maybe<Scalars['Float']>;
  output?: Maybe<ImageOutput>;
};

export type InputPoint = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type InstagramPostBlock = {
  __typename?: 'InstagramPostBlock';
  postID: Scalars['String'];
};

export type InstagramPostBlockInput = {
  postID: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  mail: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  manuallySetAsPaidByUserId?: Maybe<Scalars['ID']>;
  items: Array<InvoiceItem>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  total: Scalars['Int'];
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  nodes: Array<Invoice>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type InvoiceFilter = {
  mail?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['Date']>;
  canceledAt?: Maybe<Scalars['Date']>;
  userID?: Maybe<Scalars['ID']>;
  subscriptionID?: Maybe<Scalars['ID']>;
};

export type InvoiceInput = {
  mail: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  subscriptionID?: Maybe<Scalars['ID']>;
  manuallySetAsPaidByUserId?: Maybe<Scalars['ID']>;
  items: Array<InvoiceItemInput>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  amount: Scalars['Int'];
  total: Scalars['Int'];
};

export type InvoiceItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
};

export enum InvoiceSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  PaidAt = 'PAID_AT'
}

export type JwtToken = {
  __typename?: 'JWTToken';
  token: Scalars['String'];
  expiresAt: Scalars['String'];
};

export type LinkPageBreakBlock = {
  __typename?: 'LinkPageBreakBlock';
  text?: Maybe<Scalars['String']>;
  richText: Scalars['RichText'];
  linkURL?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  hideButton: Scalars['Boolean'];
  styleOption?: Maybe<Scalars['String']>;
  layoutOption?: Maybe<Scalars['String']>;
  templateOption?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
};

export type LinkPageBreakBlockInput = {
  text?: Maybe<Scalars['String']>;
  richText: Scalars['RichText'];
  linkURL?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  hideButton: Scalars['Boolean'];
  styleOption?: Maybe<Scalars['String']>;
  templateOption?: Maybe<Scalars['String']>;
  layoutOption?: Maybe<Scalars['String']>;
  imageID?: Maybe<Scalars['ID']>;
};

export type ListicleBlock = {
  __typename?: 'ListicleBlock';
  items: Array<ListicleItem>;
};

export type ListicleBlockInput = {
  items?: Maybe<Array<Maybe<ListicleItemInput>>>;
};

export type ListicleItem = {
  __typename?: 'ListicleItem';
  title: Scalars['String'];
  image?: Maybe<Image>;
  richText: Scalars['RichText'];
};

export type ListicleItemInput = {
  title: Scalars['String'];
  imageID?: Maybe<Scalars['ID']>;
  richText: Scalars['RichText'];
};

export type MemberPlan = {
  __typename?: 'MemberPlan';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  image?: Maybe<Image>;
  description?: Maybe<Scalars['RichText']>;
  tags?: Maybe<Array<Scalars['String']>>;
  active: Scalars['Boolean'];
  amountPerMonthMin: Scalars['Int'];
  availablePaymentMethods: Array<AvailablePaymentMethod>;
};

export type MemberPlanConnection = {
  __typename?: 'MemberPlanConnection';
  nodes: Array<MemberPlan>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MemberPlanFilter = {
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type MemberPlanInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  imageID?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['RichText']>;
  tags?: Maybe<Array<Scalars['String']>>;
  active: Scalars['Boolean'];
  amountPerMonthMin: Scalars['Int'];
  availablePaymentMethods: Array<AvailablePaymentMethodInput>;
};

export enum MemberPlanSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export type Mutation = {
  __typename?: 'Mutation';
  updatePeerProfile: PeerProfile;
  createPeer: Peer;
  updatePeer: Peer;
  deletePeer?: Maybe<Peer>;
  createSession: SessionWithToken;
  createSessionWithJWT: SessionWithToken;
  createSessionWithOAuth2Code: SessionWithToken;
  revokeSession: Scalars['Boolean'];
  revokeActiveSession: Scalars['Boolean'];
  sessions: Array<Session>;
  sendJWTLogin: Scalars['String'];
  sendWebsiteLogin: Scalars['String'];
  createToken: CreatedToken;
  deleteToken?: Maybe<CreatedToken>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  resetUserPassword?: Maybe<User>;
  deleteUser?: Maybe<User>;
  createSubscription?: Maybe<Subscription>;
  updateSubscription?: Maybe<Subscription>;
  deleteSubscription?: Maybe<Subscription>;
  createUserRole?: Maybe<UserRole>;
  updateUserRole?: Maybe<UserRole>;
  deleteUserRole?: Maybe<UserRole>;
  createNavigation?: Maybe<Navigation>;
  updateNavigation?: Maybe<Navigation>;
  deleteNavigation?: Maybe<Navigation>;
  createAuthor?: Maybe<Author>;
  updateAuthor?: Maybe<Author>;
  deleteAuthor?: Maybe<Author>;
  uploadImage?: Maybe<Image>;
  updateImage?: Maybe<Image>;
  deleteImage?: Maybe<Image>;
  createArticle: Article;
  updateArticle?: Maybe<Article>;
  deleteArticle?: Maybe<Article>;
  publishArticle?: Maybe<Article>;
  unpublishArticle?: Maybe<Article>;
  duplicateArticle: Article;
  createPage: Page;
  updatePage?: Maybe<Page>;
  deletePage?: Maybe<Page>;
  publishPage?: Maybe<Page>;
  unpublishPage?: Maybe<Page>;
  duplicatePage: Page;
  createMemberPlan?: Maybe<MemberPlan>;
  updateMemberPlan?: Maybe<MemberPlan>;
  deleteMemberPlan?: Maybe<MemberPlan>;
  createPaymentMethod?: Maybe<PaymentMethod>;
  updatePaymentMethod?: Maybe<PaymentMethod>;
  deletePaymentMethod?: Maybe<PaymentMethod>;
  createInvoice?: Maybe<Invoice>;
  createPaymentFromInvoice?: Maybe<Payment>;
  updateInvoice?: Maybe<Invoice>;
  deleteInvoice?: Maybe<Invoice>;
  updateComment: Comment;
  createComment: Comment;
  approveComment: Comment;
  rejectComment: Comment;
  requestChangesOnComment: Comment;
  deleteComment: Comment;
  updateSettingList?: Maybe<Array<Maybe<Setting>>>;
  createRatingSystemAnswer: CommentRatingSystemAnswer;
  updateRatingSystem: FullCommentRatingSystem;
  deleteRatingSystemAnswer: CommentRatingSystemAnswer;
  createPoll?: Maybe<PollWithAnswers>;
  createPollAnswer?: Maybe<PollAnswer>;
  createPollExternalVoteSource?: Maybe<PollExternalVoteSource>;
  updatePoll?: Maybe<FullPoll>;
  deletePoll?: Maybe<FullPoll>;
  deletePollAnswer?: Maybe<PollAnswerWithVoteCount>;
  deletePollExternalVoteSource?: Maybe<PollExternalVoteSource>;
  createTag?: Maybe<Tag>;
  updateTag?: Maybe<Tag>;
  deleteTag?: Maybe<Tag>;
};


export type MutationUpdatePeerProfileArgs = {
  input: PeerProfileInput;
};


export type MutationCreatePeerArgs = {
  input: CreatePeerInput;
};


export type MutationUpdatePeerArgs = {
  id: Scalars['ID'];
  input: UpdatePeerInput;
};


export type MutationDeletePeerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSessionArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateSessionWithJwtArgs = {
  jwt: Scalars['String'];
};


export type MutationCreateSessionWithOAuth2CodeArgs = {
  name: Scalars['String'];
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};


export type MutationRevokeSessionArgs = {
  id: Scalars['ID'];
};


export type MutationSendJwtLoginArgs = {
  url: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendWebsiteLoginArgs = {
  email: Scalars['String'];
};


export type MutationCreateTokenArgs = {
  input: TokenInput;
};


export type MutationDeleteTokenArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UserInput;
};


export type MutationResetUserPasswordArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
  sendMail?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSubscriptionArgs = {
  input: SubscriptionInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['ID'];
  input: SubscriptionInput;
};


export type MutationDeleteSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationCreateUserRoleArgs = {
  input: UserRoleInput;
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['ID'];
  input: UserRoleInput;
};


export type MutationDeleteUserRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateNavigationArgs = {
  input: NavigationInput;
};


export type MutationUpdateNavigationArgs = {
  id: Scalars['ID'];
  input: NavigationInput;
};


export type MutationDeleteNavigationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateAuthorArgs = {
  input: AuthorInput;
};


export type MutationUpdateAuthorArgs = {
  id: Scalars['ID'];
  input: AuthorInput;
};


export type MutationDeleteAuthorArgs = {
  id: Scalars['ID'];
};


export type MutationUploadImageArgs = {
  input: UploadImageInput;
};


export type MutationUpdateImageArgs = {
  id: Scalars['ID'];
  input: UpdateImageInput;
};


export type MutationDeleteImageArgs = {
  id: Scalars['ID'];
};


export type MutationCreateArticleArgs = {
  input: ArticleInput;
};


export type MutationUpdateArticleArgs = {
  id: Scalars['ID'];
  input: ArticleInput;
};


export type MutationDeleteArticleArgs = {
  id: Scalars['ID'];
};


export type MutationPublishArticleArgs = {
  id: Scalars['ID'];
  publishAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type MutationUnpublishArticleArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateArticleArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePageArgs = {
  input: PageInput;
};


export type MutationUpdatePageArgs = {
  id: Scalars['ID'];
  input: PageInput;
};


export type MutationDeletePageArgs = {
  id: Scalars['ID'];
};


export type MutationPublishPageArgs = {
  id: Scalars['ID'];
  publishAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};


export type MutationUnpublishPageArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicatePageArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMemberPlanArgs = {
  input: MemberPlanInput;
};


export type MutationUpdateMemberPlanArgs = {
  id: Scalars['ID'];
  input: MemberPlanInput;
};


export type MutationDeleteMemberPlanArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationUpdatePaymentMethodArgs = {
  id: Scalars['ID'];
  input: PaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInvoiceArgs = {
  input: InvoiceInput;
};


export type MutationCreatePaymentFromInvoiceArgs = {
  input: PaymentFromInvoiceInput;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID'];
  input: InvoiceInput;
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCommentArgs = {
  id: Scalars['ID'];
  revision?: Maybe<CommentRevisionUpdateInput>;
  userID?: Maybe<Scalars['ID']>;
  guestUsername?: Maybe<Scalars['String']>;
  guestUserImageID?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateCommentArgs = {
  text?: Maybe<Scalars['RichText']>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
  itemID: Scalars['ID'];
  parentID?: Maybe<Scalars['ID']>;
  itemType: CommentItemType;
};


export type MutationApproveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationRejectCommentArgs = {
  id: Scalars['ID'];
  rejectionReason: CommentRejectionReason;
};


export type MutationRequestChangesOnCommentArgs = {
  id: Scalars['ID'];
  rejectionReason: CommentRejectionReason;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSettingListArgs = {
  value?: Maybe<Array<Maybe<UpdateSettingArgs>>>;
};


export type MutationCreateRatingSystemAnswerArgs = {
  ratingSystemId: Scalars['ID'];
  type?: Maybe<RatingSystemType>;
  answer?: Maybe<Scalars['String']>;
};


export type MutationUpdateRatingSystemArgs = {
  ratingSystemId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<UpdateCommentRatingSystemAnswer>>;
};


export type MutationDeleteRatingSystemAnswerArgs = {
  id: Scalars['ID'];
};


export type MutationCreatePollArgs = {
  opensAt?: Maybe<Scalars['DateTime']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
};


export type MutationCreatePollAnswerArgs = {
  pollId: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
};


export type MutationCreatePollExternalVoteSourceArgs = {
  pollId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
};


export type MutationUpdatePollArgs = {
  pollId: Scalars['ID'];
  opensAt?: Maybe<Scalars['DateTime']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<UpdatePollAnswer>>;
  externalVoteSources?: Maybe<Array<UpdatePollExternalVoteSources>>;
};


export type MutationDeletePollArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePollAnswerArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePollExternalVoteSourceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateTagArgs = {
  tag?: Maybe<Scalars['String']>;
  type: TagType;
};


export type MutationUpdateTagArgs = {
  id: Scalars['ID'];
  tag?: Maybe<Scalars['String']>;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};

export type Navigation = {
  __typename?: 'Navigation';
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  links: Array<NavigationLink>;
};

export type NavigationInput = {
  key: Scalars['String'];
  name: Scalars['String'];
  links: Array<NavigationLinkInput>;
};

export type NavigationLink = PageNavigationLink | ArticleNavigationLink | ExternalNavigationLink;

export type NavigationLinkInput = {
  page?: Maybe<PageNavigationLinkInput>;
  article?: Maybe<ArticleNavigationLinkInput>;
  external?: Maybe<ExternalNavigationLinkInput>;
};

export type OAuth2Account = {
  __typename?: 'OAuth2Account';
  type: Scalars['String'];
  provider: Scalars['String'];
  scope: Scalars['String'];
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['ID'];
  shared: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  draft?: Maybe<PageRevision>;
  published?: Maybe<PageRevision>;
  pending?: Maybe<PageRevision>;
  latest: PageRevision;
};

export type PageConnection = {
  __typename?: 'PageConnection';
  nodes: Array<Page>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type PageInput = {
  slug: Scalars['Slug'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  properties: Array<PropertiesInput>;
  imageID?: Maybe<Scalars['ID']>;
  socialMediaTitle?: Maybe<Scalars['String']>;
  socialMediaDescription?: Maybe<Scalars['String']>;
  socialMediaImageID?: Maybe<Scalars['ID']>;
  blocks: Array<BlockInput>;
};

export type PageNavigationLink = BaseNavigationLink & {
  __typename?: 'PageNavigationLink';
  label: Scalars['String'];
  page?: Maybe<Page>;
};

export type PageNavigationLinkInput = {
  label: Scalars['String'];
  pageID: Scalars['ID'];
};

export type PageRevision = {
  __typename?: 'PageRevision';
  revision: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  publishAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['Slug'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  properties: Array<Properties>;
  url: Scalars['String'];
  image?: Maybe<Image>;
  socialMediaTitle?: Maybe<Scalars['String']>;
  socialMediaDescription?: Maybe<Scalars['String']>;
  socialMediaImage?: Maybe<Image>;
  blocks: Array<Block>;
};

export enum PageSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  PublishAt = 'PUBLISH_AT',
  PublishedAt = 'PUBLISHED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type PageTeaser = {
  __typename?: 'PageTeaser';
  style: TeaserStyle;
  image?: Maybe<Image>;
  preTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
};

export type PageTeaserInput = {
  style: TeaserStyle;
  imageID?: Maybe<Scalars['ID']>;
  preTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  pageID: Scalars['ID'];
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  intentID?: Maybe<Scalars['String']>;
  intentSecret?: Maybe<Scalars['String']>;
  state: PaymentState;
  invoice: Invoice;
  intentData?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
  paymentData?: Maybe<Scalars['String']>;
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  nodes: Array<Payment>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PaymentFilter = {
  intentID?: Maybe<Scalars['String']>;
};

export type PaymentFromInvoiceInput = {
  invoiceID: Scalars['String'];
  paymentMethodID?: Maybe<Scalars['ID']>;
  paymentMethodSlug?: Maybe<Scalars['Slug']>;
  successURL?: Maybe<Scalars['String']>;
  failureURL?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['Slug'];
  description: Scalars['String'];
  paymentProvider: PaymentProvider;
  active: Scalars['Boolean'];
};

export type PaymentMethodInput = {
  name: Scalars['String'];
  slug: Scalars['Slug'];
  description: Scalars['String'];
  paymentProviderID: Scalars['String'];
  active: Scalars['Boolean'];
};

export enum PaymentPeriodicity {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Biannual = 'BIANNUAL',
  Yearly = 'YEARLY'
}

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PaymentProviderCustomer = {
  __typename?: 'PaymentProviderCustomer';
  paymentProviderID: Scalars['String'];
  customerID: Scalars['String'];
};

export enum PaymentSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export enum PaymentState {
  Created = 'Created',
  Submitted = 'Submitted',
  RequiresUserAction = 'RequiresUserAction',
  Processing = 'Processing',
  Paid = 'Paid',
  Canceled = 'Canceled',
  Declined = 'Declined'
}

export type Peer = {
  __typename?: 'Peer';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  isDisabled?: Maybe<Scalars['Boolean']>;
  hostURL: Scalars['String'];
  profile?: Maybe<PeerProfile>;
};

export type PeerArticle = {
  __typename?: 'PeerArticle';
  peer: Peer;
  peeredArticleURL: Scalars['String'];
  article: Article;
};

export type PeerArticleConnection = {
  __typename?: 'PeerArticleConnection';
  nodes: Array<PeerArticle>;
  pageInfo: UnidirectionalPageInfo;
  totalCount: Scalars['Int'];
};

export type PeerArticleTeaser = {
  __typename?: 'PeerArticleTeaser';
  style: TeaserStyle;
  image?: Maybe<Image>;
  preTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  peer?: Maybe<Peer>;
  articleID: Scalars['ID'];
  article?: Maybe<Article>;
};

export type PeerArticleTeaserInput = {
  style: TeaserStyle;
  imageID?: Maybe<Scalars['ID']>;
  preTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  peerID: Scalars['ID'];
  articleID: Scalars['ID'];
};

export type PeerProfile = {
  __typename?: 'PeerProfile';
  name: Scalars['String'];
  logo?: Maybe<Image>;
  themeColor: Scalars['Color'];
  themeFontColor: Scalars['Color'];
  hostURL: Scalars['String'];
  websiteURL: Scalars['String'];
  callToActionText: Scalars['RichText'];
  callToActionURL: Scalars['String'];
  callToActionImageURL?: Maybe<Scalars['String']>;
  callToActionImage?: Maybe<Image>;
};

export type PeerProfileInput = {
  name: Scalars['String'];
  logoID?: Maybe<Scalars['ID']>;
  themeColor: Scalars['Color'];
  themeFontColor: Scalars['Color'];
  callToActionText: Scalars['RichText'];
  callToActionURL: Scalars['String'];
  callToActionImageURL?: Maybe<Scalars['String']>;
  callToActionImageID?: Maybe<Scalars['ID']>;
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['String'];
  description: Scalars['String'];
  deprecated: Scalars['Boolean'];
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type PolisConversationBlock = {
  __typename?: 'PolisConversationBlock';
  conversationID: Scalars['String'];
};

export type PolisConversationBlockInput = {
  conversationID: Scalars['String'];
};

export type Poll = {
  __typename?: 'Poll';
  id: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
  opensAt: Scalars['DateTime'];
  closedAt?: Maybe<Scalars['DateTime']>;
};

export type PollAnswer = {
  __typename?: 'PollAnswer';
  id: Scalars['ID'];
  pollId: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
};

export type PollAnswerWithVoteCount = {
  __typename?: 'PollAnswerWithVoteCount';
  id: Scalars['ID'];
  pollId: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
  votes: Scalars['Int'];
};

export type PollBlock = {
  __typename?: 'PollBlock';
  poll?: Maybe<FullPoll>;
};

export type PollBlockInput = {
  pollId?: Maybe<Scalars['ID']>;
};

export type PollConnection = {
  __typename?: 'PollConnection';
  nodes: Array<Poll>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type PollExternalVote = {
  __typename?: 'PollExternalVote';
  id: Scalars['ID'];
  answerId: Scalars['ID'];
  amount?: Maybe<Scalars['VoteValue']>;
};

export type PollExternalVoteSource = {
  __typename?: 'PollExternalVoteSource';
  id: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  voteAmounts?: Maybe<Array<PollExternalVote>>;
};

export type PollFilter = {
  openOnly?: Maybe<Scalars['Boolean']>;
};

export enum PollSort {
  OpensAt = 'OPENS_AT',
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export type PollWithAnswers = {
  __typename?: 'PollWithAnswers';
  id: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
  opensAt: Scalars['DateTime'];
  closedAt?: Maybe<Scalars['DateTime']>;
  answers?: Maybe<Array<PollAnswer>>;
};

export type Properties = {
  __typename?: 'Properties';
  key: Scalars['String'];
  value: Scalars['String'];
  public: Scalars['Boolean'];
};

export type PropertiesInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  public: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  remotePeerProfile?: Maybe<PeerProfile>;
  createJWTForUser?: Maybe<JwtToken>;
  peerProfile: PeerProfile;
  peers?: Maybe<Array<Peer>>;
  peer?: Maybe<Peer>;
  me?: Maybe<User>;
  sessions: Array<Session>;
  authProviders: Array<AuthProvider>;
  user?: Maybe<User>;
  users: UserConnection;
  subscription?: Maybe<Subscription>;
  subscriptions: SubscriptionConnection;
  subscriptionsAsCsv?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRole>;
  userRoles: UserRoleConnection;
  permissions?: Maybe<Array<Permission>>;
  tokens: Array<Token>;
  navigation?: Maybe<Navigation>;
  navigations: Array<Navigation>;
  author?: Maybe<Author>;
  authors: AuthorConnection;
  image?: Maybe<Image>;
  images: ImageConnection;
  comment?: Maybe<Comment>;
  comments: CommentConnection;
  article?: Maybe<Article>;
  articles: ArticleConnection;
  peerArticle?: Maybe<Article>;
  peerArticles: PeerArticleConnection;
  articlePreviewLink?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
  pages: PageConnection;
  pagePreviewLink?: Maybe<Scalars['String']>;
  memberPlan?: Maybe<MemberPlan>;
  memberPlans: MemberPlanConnection;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethods: Array<PaymentMethod>;
  paymentProviders: Array<PaymentProvider>;
  invoice?: Maybe<Invoice>;
  invoices: InvoiceConnection;
  payment?: Maybe<Payment>;
  payments: PaymentConnection;
  setting?: Maybe<Setting>;
  settings: Array<Setting>;
  ratingSystem: FullCommentRatingSystem;
  tags?: Maybe<TagConnection>;
  polls?: Maybe<PollConnection>;
  poll?: Maybe<FullPoll>;
};


export type QueryRemotePeerProfileArgs = {
  hostURL: Scalars['String'];
  token: Scalars['String'];
};


export type QueryCreateJwtForUserArgs = {
  userId: Scalars['String'];
  expiresInMinutes: Scalars['Int'];
};


export type QueryPeerArgs = {
  id: Scalars['ID'];
};


export type QueryAuthProvidersArgs = {
  redirectUri?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUsersArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserFilter>;
  sort?: Maybe<UserSort>;
  order?: Maybe<SortOrder>;
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID'];
};


export type QuerySubscriptionsArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<SubscriptionFilter>;
  sort?: Maybe<SubscriptionSort>;
  order?: Maybe<SortOrder>;
};


export type QuerySubscriptionsAsCsvArgs = {
  filter?: Maybe<SubscriptionFilter>;
};


export type QueryUserRoleArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserRolesArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserRoleFilter>;
  sort?: Maybe<UserRoleSort>;
  order?: Maybe<SortOrder>;
};


export type QueryNavigationArgs = {
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['ID']>;
};


export type QueryAuthorArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['Slug']>;
};


export type QueryAuthorsArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<AuthorFilter>;
  sort?: Maybe<AuthorSort>;
  order?: Maybe<SortOrder>;
};


export type QueryImageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryImagesArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<ImageFilter>;
  sort?: Maybe<ImageSort>;
  order?: Maybe<SortOrder>;
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommentsArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<CommentFilter>;
  sort?: Maybe<CommentSort>;
  order?: Maybe<SortOrder>;
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
};


export type QueryArticlesArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<ArticleFilter>;
  sort?: Maybe<ArticleSort>;
  order?: Maybe<SortOrder>;
};


export type QueryPeerArticleArgs = {
  peerID: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryPeerArticlesArgs = {
  cursors?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  sort?: Maybe<ArticleSort>;
  order?: Maybe<SortOrder>;
  peerFilter?: Maybe<Scalars['String']>;
  filter?: Maybe<ArticleFilter>;
};


export type QueryArticlePreviewLinkArgs = {
  id: Scalars['ID'];
  hours: Scalars['Int'];
};


export type QueryPageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPagesArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<ArticleFilter>;
  sort?: Maybe<PageSort>;
  order?: Maybe<SortOrder>;
};


export type QueryPagePreviewLinkArgs = {
  id: Scalars['ID'];
  hours: Scalars['Int'];
};


export type QueryMemberPlanArgs = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['Slug']>;
};


export type QueryMemberPlansArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<MemberPlanFilter>;
  sort?: Maybe<MemberPlanSort>;
  order?: Maybe<SortOrder>;
};


export type QueryPaymentMethodArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryInvoiceArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryInvoicesArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<InvoiceFilter>;
  sort?: Maybe<InvoiceSort>;
  order?: Maybe<SortOrder>;
};


export type QueryPaymentArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPaymentsArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<PaymentFilter>;
  sort?: Maybe<PaymentSort>;
  order?: Maybe<SortOrder>;
};


export type QuerySettingArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryTagsArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<TagFilter>;
  sort?: Maybe<TagSort>;
  order?: Maybe<SortOrder>;
};


export type QueryPollsArgs = {
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<PollFilter>;
  sort?: Maybe<PollSort>;
  order?: Maybe<SortOrder>;
};


export type QueryPollArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QuoteBlock = {
  __typename?: 'QuoteBlock';
  quote?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
};

export type QuoteBlockInput = {
  quote?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
};

export enum RatingSystemType {
  Star = 'STAR'
}


export type RichTextBlock = {
  __typename?: 'RichTextBlock';
  richText: Scalars['RichText'];
};

export type RichTextBlockInput = {
  richText: Scalars['RichText'];
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  user: User;
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
};

export type SessionWithToken = {
  __typename?: 'SessionWithToken';
  id: Scalars['ID'];
  user: User;
  token: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
};

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['ID'];
  name: SettingName;
  value: Scalars['Value'];
  settingRestriction?: Maybe<SettingRestriction>;
};

export enum SettingName {
  AllowGuestCommenting = 'ALLOW_GUEST_COMMENTING',
  AllowGuestCommentRating = 'ALLOW_GUEST_COMMENT_RATING',
  AllowGuestPollVoting = 'ALLOW_GUEST_POLL_VOTING',
  SendLoginJwtExpiresMin = 'SEND_LOGIN_JWT_EXPIRES_MIN',
  ResetPasswordJwtExpiresMin = 'RESET_PASSWORD_JWT_EXPIRES_MIN',
  PeeringTimeoutMs = 'PEERING_TIMEOUT_MS',
  InvoiceReminderFreq = 'INVOICE_REMINDER_FREQ',
  InvoiceReminderMaxTries = 'INVOICE_REMINDER_MAX_TRIES'
}

export type SettingRestriction = {
  __typename?: 'SettingRestriction';
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  inputLength?: Maybe<Scalars['Int']>;
  allowedValues?: Maybe<AllowedSettingVals>;
};


export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SoundCloudTrackBlock = {
  __typename?: 'SoundCloudTrackBlock';
  trackID: Scalars['String'];
};

export type SoundCloudTrackBlockInput = {
  trackID: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  memberPlan: MemberPlan;
  paymentPeriodicity: PaymentPeriodicity;
  monthlyAmount: Scalars['Int'];
  autoRenew: Scalars['Boolean'];
  startsAt: Scalars['DateTime'];
  paidUntil?: Maybe<Scalars['DateTime']>;
  paymentMethod: PaymentMethod;
  properties: Array<Properties>;
  deactivation?: Maybe<SubscriptionDeactivation>;
};

export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  nodes: Array<Subscription>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SubscriptionDeactivation = {
  __typename?: 'SubscriptionDeactivation';
  date: Scalars['DateTime'];
  reason: SubscriptionDeactivationReason;
};

export type SubscriptionDeactivationInput = {
  date: Scalars['DateTime'];
  reason: SubscriptionDeactivationReason;
};

export enum SubscriptionDeactivationReason {
  None = 'NONE',
  UserSelfDeactivated = 'USER_SELF_DEACTIVATED',
  InvoiceNotPaid = 'INVOICE_NOT_PAID'
}

export type SubscriptionFilter = {
  startsAt?: Maybe<DateFilter>;
  paidUntil?: Maybe<DateFilter>;
  startsAtFrom?: Maybe<DateFilter>;
  startsAtTo?: Maybe<DateFilter>;
  paidUntilFrom?: Maybe<DateFilter>;
  paidUntilTo?: Maybe<DateFilter>;
  deactivationDateFrom?: Maybe<DateFilter>;
  deactivationDateTo?: Maybe<DateFilter>;
  deactivationReason?: Maybe<SubscriptionDeactivationReason>;
  autoRenew?: Maybe<Scalars['Boolean']>;
  paymentMethodID?: Maybe<Scalars['String']>;
  memberPlanID?: Maybe<Scalars['String']>;
  paymentPeriodicity?: Maybe<PaymentPeriodicity>;
  userHasAddress?: Maybe<Scalars['Boolean']>;
  userID?: Maybe<Scalars['ID']>;
};

export type SubscriptionInput = {
  userID: Scalars['ID'];
  memberPlanID: Scalars['String'];
  paymentPeriodicity: PaymentPeriodicity;
  monthlyAmount: Scalars['Int'];
  autoRenew: Scalars['Boolean'];
  startsAt: Scalars['DateTime'];
  paidUntil?: Maybe<Scalars['DateTime']>;
  paymentMethodID: Scalars['String'];
  properties: Array<PropertiesInput>;
  deactivation?: Maybe<SubscriptionDeactivationInput>;
};

export type SubscriptionPeriod = {
  __typename?: 'SubscriptionPeriod';
  id: Scalars['ID'];
  invoiceID: Scalars['ID'];
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  paymentPeriodicity: PaymentPeriodicity;
};

export enum SubscriptionSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  tag?: Maybe<Scalars['String']>;
  type?: Maybe<TagType>;
};

export type TagConnection = {
  __typename?: 'TagConnection';
  nodes: Array<Tag>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TagFilter = {
  type?: Maybe<TagType>;
  tag?: Maybe<Scalars['String']>;
};

export enum TagSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Tag = 'TAG'
}

export enum TagType {
  Comment = 'Comment'
}

export type Teaser = ArticleTeaser | PeerArticleTeaser | PageTeaser;

export type TeaserGridBlock = {
  __typename?: 'TeaserGridBlock';
  teasers: Array<Maybe<Teaser>>;
  numColumns: Scalars['Int'];
};

export type TeaserGridBlockInput = {
  teasers: Array<Maybe<TeaserInput>>;
  numColumns: Scalars['Int'];
};

export type TeaserGridFlexBlock = {
  __typename?: 'TeaserGridFlexBlock';
  flexTeasers: Array<Maybe<FlexTeaser>>;
};

export type TeaserGridFlexBlockInput = {
  flexTeasers: Array<FlexTeaserInput>;
};

export type TeaserInput = {
  article?: Maybe<ArticleTeaserInput>;
  peerArticle?: Maybe<PeerArticleTeaserInput>;
  page?: Maybe<PageTeaserInput>;
};

export enum TeaserStyle {
  Default = 'DEFAULT',
  Light = 'LIGHT',
  Text = 'TEXT'
}

export type TikTokVideoBlock = {
  __typename?: 'TikTokVideoBlock';
  videoID: Scalars['String'];
  userID: Scalars['String'];
};

export type TikTokVideoBlockInput = {
  videoID: Scalars['String'];
  userID: Scalars['String'];
};

export type TitleBlock = {
  __typename?: 'TitleBlock';
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
};

export type TitleBlockInput = {
  title?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type TokenInput = {
  name: Scalars['String'];
};

export type TwitterTweetBlock = {
  __typename?: 'TwitterTweetBlock';
  userID: Scalars['String'];
  tweetID: Scalars['String'];
};

export type TwitterTweetBlockInput = {
  userID: Scalars['String'];
  tweetID: Scalars['String'];
};

export type UnidirectionalPageInfo = {
  __typename?: 'UnidirectionalPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type UpdateCommentRatingSystemAnswer = {
  id: Scalars['ID'];
  type?: Maybe<RatingSystemType>;
  answer?: Maybe<Scalars['String']>;
};

export type UpdateImageInput = {
  filename?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  link?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  focalPoint?: Maybe<InputPoint>;
};

export type UpdatePeerInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  hostURL?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type UpdatePollAnswer = {
  id: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
};

export type UpdatePollExternalVote = {
  id: Scalars['ID'];
  amount?: Maybe<Scalars['VoteValue']>;
};

export type UpdatePollExternalVoteSources = {
  id: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  voteAmounts?: Maybe<Array<UpdatePollExternalVote>>;
};

export type UpdateSettingArgs = {
  name: SettingName;
  value: Scalars['Value'];
};


export type UploadImageInput = {
  file: Scalars['Upload'];
  filename?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  link?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  focalPoint?: Maybe<InputPoint>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  preferredName?: Maybe<Scalars['String']>;
  address?: Maybe<UserAddress>;
  userImage?: Maybe<Image>;
  active: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  properties: Array<Properties>;
  roles: Array<UserRole>;
  paymentProviderCustomers: Array<PaymentProviderCustomer>;
  oauth2Accounts: Array<OAuth2Account>;
  subscriptions: Array<UserSubscription>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  company?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UserAddressInput = {
  company?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserFilter = {
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UserInput = {
  name: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  preferredName?: Maybe<Scalars['String']>;
  address?: Maybe<UserAddressInput>;
  userImageID?: Maybe<Scalars['ID']>;
  active: Scalars['Boolean'];
  properties: Array<PropertiesInput>;
  roleIDs?: Maybe<Array<Scalars['String']>>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  systemRole: Scalars['Boolean'];
  permissions: Array<Permission>;
};

export type UserRoleConnection = {
  __typename?: 'UserRoleConnection';
  nodes: Array<UserRole>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserRoleFilter = {
  name?: Maybe<Scalars['String']>;
};

export type UserRoleInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  permissionIDs?: Maybe<Array<Scalars['String']>>;
};

export enum UserRoleSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export enum UserSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Name = 'NAME',
  FirstName = 'FIRST_NAME'
}

export type UserSubscription = {
  __typename?: 'UserSubscription';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  paymentPeriodicity: PaymentPeriodicity;
  monthlyAmount: Scalars['Int'];
  autoRenew: Scalars['Boolean'];
  startsAt: Scalars['DateTime'];
  paidUntil?: Maybe<Scalars['DateTime']>;
  properties: Array<Properties>;
  deactivation?: Maybe<SubscriptionDeactivation>;
  periods: Array<SubscriptionPeriod>;
  memberPlan: MemberPlan;
  invoices: Array<Invoice>;
};


export type VimeoVideoBlock = {
  __typename?: 'VimeoVideoBlock';
  videoID: Scalars['String'];
};

export type VimeoVideoBlockInput = {
  videoID: Scalars['String'];
};


export type YouTubeVideoBlock = {
  __typename?: 'YouTubeVideoBlock';
  videoID: Scalars['String'];
};

export type YouTubeVideoBlockInput = {
  videoID: Scalars['String'];
};

export type MutationArticleFragment = (
  { __typename?: 'Article' }
  & Pick<Article, 'id'>
  & { draft?: Maybe<(
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'publishedAt' | 'updatedAt' | 'revision'>
  )>, pending?: Maybe<(
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'publishAt' | 'revision'>
  )>, published?: Maybe<(
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'publishedAt' | 'updatedAt' | 'publishAt' | 'revision'>
  )>, latest: (
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'publishedAt' | 'updatedAt' | 'publishAt' | 'revision'>
  ) }
);

export type ArticleRefFragment = (
  { __typename?: 'Article' }
  & Pick<Article, 'id' | 'createdAt' | 'modifiedAt'>
  & { draft?: Maybe<(
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'revision'>
  )>, pending?: Maybe<(
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'publishAt' | 'revision'>
  )>, published?: Maybe<(
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'publishedAt' | 'updatedAt' | 'revision'>
  )>, latest: (
    { __typename?: 'ArticleRevision' }
    & Pick<ArticleRevision, 'publishedAt' | 'updatedAt' | 'revision' | 'preTitle' | 'title' | 'lead' | 'canonicalUrl'>
    & { authors: Array<Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'name'>
    )>>, image?: Maybe<(
      { __typename?: 'Image' }
      & ImageRefFragment
    )> }
  ) }
);

export type ArticleListQueryVariables = Exact<{
  filter?: Maybe<ArticleFilter>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<ArticleSort>;
}>;


export type ArticleListQuery = (
  { __typename?: 'Query' }
  & { articles: (
    { __typename?: 'ArticleConnection' }
    & Pick<ArticleConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Article' }
      & ArticleRefFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type PeerArticleListQueryVariables = Exact<{
  filter?: Maybe<ArticleFilter>;
  cursors?: Maybe<Scalars['String']>;
  peerFilter?: Maybe<Scalars['String']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<ArticleSort>;
}>;


export type PeerArticleListQuery = (
  { __typename?: 'Query' }
  & { peerArticles: (
    { __typename?: 'PeerArticleConnection' }
    & Pick<PeerArticleConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'PeerArticle' }
      & Pick<PeerArticle, 'peeredArticleURL'>
      & { peer: (
        { __typename?: 'Peer' }
        & PeerWithProfileFragment
      ), article: (
        { __typename?: 'Article' }
        & ArticleRefFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'UnidirectionalPageInfo' }
      & Pick<UnidirectionalPageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type CreateArticleMutationVariables = Exact<{
  input: ArticleInput;
}>;


export type CreateArticleMutation = (
  { __typename?: 'Mutation' }
  & { createArticle: (
    { __typename?: 'Article' }
    & MutationArticleFragment
  ) }
);

export type UpdateArticleMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ArticleInput;
}>;


export type UpdateArticleMutation = (
  { __typename?: 'Mutation' }
  & { updateArticle?: Maybe<(
    { __typename?: 'Article' }
    & MutationArticleFragment
  )> }
);

export type PublishArticleMutationVariables = Exact<{
  id: Scalars['ID'];
  publishAt: Scalars['DateTime'];
  publishedAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
}>;


export type PublishArticleMutation = (
  { __typename?: 'Mutation' }
  & { publishArticle?: Maybe<(
    { __typename?: 'Article' }
    & MutationArticleFragment
  )> }
);

export type UnpublishArticleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnpublishArticleMutation = (
  { __typename?: 'Mutation' }
  & { unpublishArticle?: Maybe<(
    { __typename?: 'Article' }
    & MutationArticleFragment
  )> }
);

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteArticleMutation = (
  { __typename?: 'Mutation' }
  & { deleteArticle?: Maybe<(
    { __typename?: 'Article' }
    & MutationArticleFragment
  )> }
);

export type DuplicateArticleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateArticleMutation = (
  { __typename?: 'Mutation' }
  & { duplicateArticle: (
    { __typename?: 'Article' }
    & MutationArticleFragment
  ) }
);

export type ArticlePreviewLinkQueryVariables = Exact<{
  id: Scalars['ID'];
  hours: Scalars['Int'];
}>;


export type ArticlePreviewLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'articlePreviewLink'>
);

export type ArticleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArticleQuery = (
  { __typename?: 'Query' }
  & { article?: Maybe<(
    { __typename?: 'Article' }
    & Pick<Article, 'id' | 'shared'>
    & { draft?: Maybe<(
      { __typename?: 'ArticleRevision' }
      & Pick<ArticleRevision, 'publishAt'>
    )>, pending?: Maybe<(
      { __typename?: 'ArticleRevision' }
      & Pick<ArticleRevision, 'publishAt'>
    )>, published?: Maybe<(
      { __typename?: 'ArticleRevision' }
      & Pick<ArticleRevision, 'publishedAt' | 'updatedAt' | 'publishAt'>
    )>, latest: (
      { __typename?: 'ArticleRevision' }
      & Pick<ArticleRevision, 'publishedAt' | 'updatedAt' | 'publishAt' | 'revision' | 'slug' | 'preTitle' | 'title' | 'lead' | 'seoTitle' | 'tags' | 'url' | 'canonicalUrl' | 'hideAuthor' | 'breaking' | 'socialMediaTitle' | 'socialMediaDescription'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & ImageRefFragment
      )>, properties: Array<(
        { __typename?: 'Properties' }
        & Pick<Properties, 'key' | 'value' | 'public'>
      )>, authors: Array<Maybe<(
        { __typename?: 'Author' }
        & AuthorRefFragment
      )>>, socialMediaAuthors: Array<(
        { __typename?: 'Author' }
        & AuthorRefFragment
      )>, socialMediaImage?: Maybe<(
        { __typename?: 'Image' }
        & ImageRefFragment
      )>, blocks: Array<(
        { __typename?: 'RichTextBlock' }
        & FullBlock_RichTextBlock_Fragment
      ) | (
        { __typename?: 'ImageBlock' }
        & FullBlock_ImageBlock_Fragment
      ) | (
        { __typename?: 'ImageGalleryBlock' }
        & FullBlock_ImageGalleryBlock_Fragment
      ) | (
        { __typename?: 'ListicleBlock' }
        & FullBlock_ListicleBlock_Fragment
      ) | (
        { __typename?: 'FacebookPostBlock' }
        & FullBlock_FacebookPostBlock_Fragment
      ) | (
        { __typename?: 'FacebookVideoBlock' }
        & FullBlock_FacebookVideoBlock_Fragment
      ) | (
        { __typename?: 'InstagramPostBlock' }
        & FullBlock_InstagramPostBlock_Fragment
      ) | (
        { __typename?: 'TwitterTweetBlock' }
        & FullBlock_TwitterTweetBlock_Fragment
      ) | (
        { __typename?: 'VimeoVideoBlock' }
        & FullBlock_VimeoVideoBlock_Fragment
      ) | (
        { __typename?: 'YouTubeVideoBlock' }
        & FullBlock_YouTubeVideoBlock_Fragment
      ) | (
        { __typename?: 'SoundCloudTrackBlock' }
        & FullBlock_SoundCloudTrackBlock_Fragment
      ) | (
        { __typename?: 'PolisConversationBlock' }
        & FullBlock_PolisConversationBlock_Fragment
      ) | (
        { __typename?: 'TikTokVideoBlock' }
        & FullBlock_TikTokVideoBlock_Fragment
      ) | (
        { __typename?: 'BildwurfAdBlock' }
        & FullBlock_BildwurfAdBlock_Fragment
      ) | (
        { __typename?: 'EmbedBlock' }
        & FullBlock_EmbedBlock_Fragment
      ) | (
        { __typename?: 'HTMLBlock' }
        & FullBlock_HtmlBlock_Fragment
      ) | (
        { __typename?: 'PollBlock' }
        & FullBlock_PollBlock_Fragment
      ) | (
        { __typename?: 'CommentBlock' }
        & FullBlock_CommentBlock_Fragment
      ) | (
        { __typename?: 'LinkPageBreakBlock' }
        & FullBlock_LinkPageBreakBlock_Fragment
      ) | (
        { __typename?: 'TitleBlock' }
        & FullBlock_TitleBlock_Fragment
      ) | (
        { __typename?: 'QuoteBlock' }
        & FullBlock_QuoteBlock_Fragment
      ) | (
        { __typename?: 'TeaserGridBlock' }
        & FullBlock_TeaserGridBlock_Fragment
      ) | (
        { __typename?: 'TeaserGridFlexBlock' }
        & FullBlock_TeaserGridFlexBlock_Fragment
      )> }
    ) }
  )> }
);

export type CreateSessionMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateSessionMutation = (
  { __typename?: 'Mutation' }
  & { createSession: (
    { __typename?: 'SessionWithToken' }
    & Pick<SessionWithToken, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
      & { roles: Array<(
        { __typename?: 'UserRole' }
        & FullUserRoleFragment
      )> }
    ) }
  ) }
);

export type GetAuthProvidersQueryVariables = Exact<{
  redirectUri: Scalars['String'];
}>;


export type GetAuthProvidersQuery = (
  { __typename?: 'Query' }
  & { authProviders: Array<(
    { __typename?: 'AuthProvider' }
    & Pick<AuthProvider, 'name' | 'url'>
  )> }
);

export type CreateSessionWithOAuth2CodeMutationVariables = Exact<{
  redirectUri: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
}>;


export type CreateSessionWithOAuth2CodeMutation = (
  { __typename?: 'Mutation' }
  & { createSessionWithOAuth2Code: (
    { __typename?: 'SessionWithToken' }
    & Pick<SessionWithToken, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
      & { roles: Array<(
        { __typename?: 'UserRole' }
        & FullUserRoleFragment
      )> }
    ) }
  ) }
);

export type CreateSessionWithJwtMutationVariables = Exact<{
  jwt: Scalars['String'];
}>;


export type CreateSessionWithJwtMutation = (
  { __typename?: 'Mutation' }
  & { createSessionWithJWT: (
    { __typename?: 'SessionWithToken' }
    & Pick<SessionWithToken, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
      & { roles: Array<(
        { __typename?: 'UserRole' }
        & FullUserRoleFragment
      )> }
    ) }
  ) }
);

export type AuthorRefFragment = (
  { __typename?: 'Author' }
  & Pick<Author, 'id' | 'name' | 'jobTitle'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )> }
);

export type FullAuthorFragment = (
  { __typename?: 'Author' }
  & Pick<Author, 'slug' | 'bio' | 'createdAt'>
  & { links?: Maybe<Array<(
    { __typename?: 'AuthorLink' }
    & Pick<AuthorLink, 'title' | 'url'>
  )>> }
  & AuthorRefFragment
);

export type AuthorListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<AuthorSort>;
}>;


export type AuthorListQuery = (
  { __typename?: 'Query' }
  & { authors: (
    { __typename?: 'AuthorConnection' }
    & Pick<AuthorConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Author' }
      & FullAuthorFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type AuthorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AuthorQuery = (
  { __typename?: 'Query' }
  & { author?: Maybe<(
    { __typename?: 'Author' }
    & FullAuthorFragment
  )> }
);

export type CreateAuthorMutationVariables = Exact<{
  input: AuthorInput;
}>;


export type CreateAuthorMutation = (
  { __typename?: 'Mutation' }
  & { createAuthor?: Maybe<(
    { __typename?: 'Author' }
    & FullAuthorFragment
  )> }
);

export type UpdateAuthorMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AuthorInput;
}>;


export type UpdateAuthorMutation = (
  { __typename?: 'Mutation' }
  & { updateAuthor?: Maybe<(
    { __typename?: 'Author' }
    & FullAuthorFragment
  )> }
);

export type DeleteAuthorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAuthorMutation = (
  { __typename?: 'Mutation' }
  & { deleteAuthor?: Maybe<(
    { __typename?: 'Author' }
    & FullAuthorFragment
  )> }
);

type FullTeaser_ArticleTeaser_Fragment = (
  { __typename?: 'ArticleTeaser' }
  & Pick<ArticleTeaser, 'style' | 'preTitle' | 'title' | 'lead'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )>, article?: Maybe<(
    { __typename?: 'Article' }
    & ArticleRefFragment
  )> }
);

type FullTeaser_PeerArticleTeaser_Fragment = (
  { __typename?: 'PeerArticleTeaser' }
  & Pick<PeerArticleTeaser, 'style' | 'preTitle' | 'title' | 'lead' | 'articleID'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )>, peer?: Maybe<(
    { __typename?: 'Peer' }
    & PeerWithProfileFragment
  )>, article?: Maybe<(
    { __typename?: 'Article' }
    & ArticleRefFragment
  )> }
);

type FullTeaser_PageTeaser_Fragment = (
  { __typename?: 'PageTeaser' }
  & Pick<PageTeaser, 'style' | 'preTitle' | 'title' | 'lead'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )>, page?: Maybe<(
    { __typename?: 'Page' }
    & PageRefFragment
  )> }
);

export type FullTeaserFragment = FullTeaser_ArticleTeaser_Fragment | FullTeaser_PeerArticleTeaser_Fragment | FullTeaser_PageTeaser_Fragment;

type FullBlock_RichTextBlock_Fragment = (
  { __typename: 'RichTextBlock' }
  & Pick<RichTextBlock, 'richText'>
);

type FullBlock_ImageBlock_Fragment = (
  { __typename: 'ImageBlock' }
  & Pick<ImageBlock, 'caption'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )> }
);

type FullBlock_ImageGalleryBlock_Fragment = (
  { __typename: 'ImageGalleryBlock' }
  & { images: Array<(
    { __typename?: 'GalleryImageEdge' }
    & Pick<GalleryImageEdge, 'caption'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & ImageRefFragment
    )> }
  )> }
);

type FullBlock_ListicleBlock_Fragment = (
  { __typename: 'ListicleBlock' }
  & { items: Array<(
    { __typename?: 'ListicleItem' }
    & Pick<ListicleItem, 'title' | 'richText'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & ImageRefFragment
    )> }
  )> }
);

type FullBlock_FacebookPostBlock_Fragment = (
  { __typename: 'FacebookPostBlock' }
  & Pick<FacebookPostBlock, 'userID' | 'postID'>
);

type FullBlock_FacebookVideoBlock_Fragment = (
  { __typename: 'FacebookVideoBlock' }
  & Pick<FacebookVideoBlock, 'userID' | 'videoID'>
);

type FullBlock_InstagramPostBlock_Fragment = (
  { __typename: 'InstagramPostBlock' }
  & Pick<InstagramPostBlock, 'postID'>
);

type FullBlock_TwitterTweetBlock_Fragment = (
  { __typename: 'TwitterTweetBlock' }
  & Pick<TwitterTweetBlock, 'userID' | 'tweetID'>
);

type FullBlock_VimeoVideoBlock_Fragment = (
  { __typename: 'VimeoVideoBlock' }
  & Pick<VimeoVideoBlock, 'videoID'>
);

type FullBlock_YouTubeVideoBlock_Fragment = (
  { __typename: 'YouTubeVideoBlock' }
  & Pick<YouTubeVideoBlock, 'videoID'>
);

type FullBlock_SoundCloudTrackBlock_Fragment = (
  { __typename: 'SoundCloudTrackBlock' }
  & Pick<SoundCloudTrackBlock, 'trackID'>
);

type FullBlock_PolisConversationBlock_Fragment = (
  { __typename: 'PolisConversationBlock' }
  & Pick<PolisConversationBlock, 'conversationID'>
);

type FullBlock_TikTokVideoBlock_Fragment = (
  { __typename: 'TikTokVideoBlock' }
  & Pick<TikTokVideoBlock, 'userID' | 'videoID'>
);

type FullBlock_BildwurfAdBlock_Fragment = (
  { __typename: 'BildwurfAdBlock' }
  & Pick<BildwurfAdBlock, 'zoneID'>
);

type FullBlock_EmbedBlock_Fragment = (
  { __typename: 'EmbedBlock' }
  & Pick<EmbedBlock, 'url' | 'title' | 'width' | 'height' | 'styleCustom' | 'sandbox'>
);

type FullBlock_HtmlBlock_Fragment = (
  { __typename: 'HTMLBlock' }
  & Pick<HtmlBlock, 'html'>
);

type FullBlock_PollBlock_Fragment = (
  { __typename: 'PollBlock' }
  & { poll?: Maybe<(
    { __typename?: 'FullPoll' }
    & Pick<FullPoll, 'id' | 'question'>
  )> }
);

type FullBlock_CommentBlock_Fragment = (
  { __typename: 'CommentBlock' }
  & { filter: (
    { __typename?: 'CommentBlockFilter' }
    & Pick<CommentBlockFilter, 'item' | 'tags' | 'comments'>
  ), comments: Array<(
    { __typename?: 'Comment' }
    & FullCommentFragment
  )> }
);

type FullBlock_LinkPageBreakBlock_Fragment = (
  { __typename: 'LinkPageBreakBlock' }
  & Pick<LinkPageBreakBlock, 'text' | 'linkText' | 'linkURL' | 'styleOption' | 'richText' | 'linkTarget' | 'hideButton' | 'templateOption' | 'layoutOption'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )> }
);

type FullBlock_TitleBlock_Fragment = (
  { __typename: 'TitleBlock' }
  & Pick<TitleBlock, 'title' | 'lead'>
);

type FullBlock_QuoteBlock_Fragment = (
  { __typename: 'QuoteBlock' }
  & Pick<QuoteBlock, 'quote' | 'author'>
);

type FullBlock_TeaserGridBlock_Fragment = (
  { __typename: 'TeaserGridBlock' }
  & Pick<TeaserGridBlock, 'numColumns'>
  & { teasers: Array<Maybe<(
    { __typename?: 'ArticleTeaser' }
    & FullTeaser_ArticleTeaser_Fragment
  ) | (
    { __typename?: 'PeerArticleTeaser' }
    & FullTeaser_PeerArticleTeaser_Fragment
  ) | (
    { __typename?: 'PageTeaser' }
    & FullTeaser_PageTeaser_Fragment
  )>> }
);

type FullBlock_TeaserGridFlexBlock_Fragment = (
  { __typename: 'TeaserGridFlexBlock' }
  & { flexTeasers: Array<Maybe<(
    { __typename?: 'FlexTeaser' }
    & { alignment: (
      { __typename?: 'FlexAlignment' }
      & Pick<FlexAlignment, 'i' | 'x' | 'y' | 'w' | 'h' | 'static'>
    ), teaser?: Maybe<(
      { __typename?: 'ArticleTeaser' }
      & FullTeaser_ArticleTeaser_Fragment
    ) | (
      { __typename?: 'PeerArticleTeaser' }
      & FullTeaser_PeerArticleTeaser_Fragment
    ) | (
      { __typename?: 'PageTeaser' }
      & FullTeaser_PageTeaser_Fragment
    )> }
  )>> }
);

export type FullBlockFragment = FullBlock_RichTextBlock_Fragment | FullBlock_ImageBlock_Fragment | FullBlock_ImageGalleryBlock_Fragment | FullBlock_ListicleBlock_Fragment | FullBlock_FacebookPostBlock_Fragment | FullBlock_FacebookVideoBlock_Fragment | FullBlock_InstagramPostBlock_Fragment | FullBlock_TwitterTweetBlock_Fragment | FullBlock_VimeoVideoBlock_Fragment | FullBlock_YouTubeVideoBlock_Fragment | FullBlock_SoundCloudTrackBlock_Fragment | FullBlock_PolisConversationBlock_Fragment | FullBlock_TikTokVideoBlock_Fragment | FullBlock_BildwurfAdBlock_Fragment | FullBlock_EmbedBlock_Fragment | FullBlock_HtmlBlock_Fragment | FullBlock_PollBlock_Fragment | FullBlock_CommentBlock_Fragment | FullBlock_LinkPageBreakBlock_Fragment | FullBlock_TitleBlock_Fragment | FullBlock_QuoteBlock_Fragment | FullBlock_TeaserGridBlock_Fragment | FullBlock_TeaserGridFlexBlock_Fragment;

export type RatingSystemQueryVariables = Exact<{ [key: string]: never; }>;


export type RatingSystemQuery = (
  { __typename?: 'Query' }
  & { ratingSystem: (
    { __typename?: 'FullCommentRatingSystem' }
    & Pick<FullCommentRatingSystem, 'id' | 'name'>
    & { answers: Array<(
      { __typename?: 'CommentRatingSystemAnswer' }
      & Pick<CommentRatingSystemAnswer, 'id' | 'type' | 'answer' | 'ratingSystemId'>
    )> }
  ) }
);

export type UpdateRatingSystemMutationVariables = Exact<{
  ratingSystemId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<UpdateCommentRatingSystemAnswer> | UpdateCommentRatingSystemAnswer>;
}>;


export type UpdateRatingSystemMutation = (
  { __typename?: 'Mutation' }
  & { updateRatingSystem: (
    { __typename?: 'FullCommentRatingSystem' }
    & Pick<FullCommentRatingSystem, 'id' | 'name'>
    & { answers: Array<(
      { __typename?: 'CommentRatingSystemAnswer' }
      & Pick<CommentRatingSystemAnswer, 'id' | 'type' | 'answer' | 'ratingSystemId'>
    )> }
  ) }
);

export type CreateRatingSystemAnswerMutationVariables = Exact<{
  ratingSystemId: Scalars['ID'];
  type: RatingSystemType;
  answer?: Maybe<Scalars['String']>;
}>;


export type CreateRatingSystemAnswerMutation = (
  { __typename?: 'Mutation' }
  & { createRatingSystemAnswer: (
    { __typename?: 'CommentRatingSystemAnswer' }
    & Pick<CommentRatingSystemAnswer, 'answer' | 'id' | 'type' | 'ratingSystemId'>
  ) }
);

export type DeleteRatingSystemAnswerMutationVariables = Exact<{
  answerId: Scalars['ID'];
}>;


export type DeleteRatingSystemAnswerMutation = (
  { __typename?: 'Mutation' }
  & { deleteRatingSystemAnswer: (
    { __typename?: 'CommentRatingSystemAnswer' }
    & Pick<CommentRatingSystemAnswer, 'id'>
  ) }
);

export type CommentRevisionFragment = (
  { __typename?: 'CommentRevision' }
  & Pick<CommentRevision, 'text' | 'title' | 'lead' | 'createdAt'>
);

export type FullParentCommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'state' | 'rejectionReason' | 'guestUsername' | 'createdAt' | 'modifiedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )>, revisions: Array<(
    { __typename?: 'CommentRevision' }
    & CommentRevisionFragment
  )> }
);

export type FullCommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'state' | 'rejectionReason' | 'guestUsername' | 'source' | 'createdAt' | 'modifiedAt' | 'itemID' | 'itemType'>
  & { guestUserImage?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )>, user?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )>, revisions: Array<(
    { __typename?: 'CommentRevision' }
    & CommentRevisionFragment
  )>, parentComment?: Maybe<(
    { __typename?: 'Comment' }
    & FullParentCommentFragment
  )>, tags?: Maybe<Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag'>
  )>> }
);

export type CommentListQueryVariables = Exact<{
  filter?: Maybe<CommentFilter>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<CommentSort>;
}>;


export type CommentListQuery = (
  { __typename?: 'Query' }
  & { comments: (
    { __typename?: 'CommentConnection' }
    & Pick<CommentConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Comment' }
      & FullCommentFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type CommentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommentQuery = (
  { __typename?: 'Query' }
  & { comment?: Maybe<(
    { __typename?: 'Comment' }
    & FullCommentFragment
  )> }
);

export type ApproveCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveCommentMutation = (
  { __typename?: 'Mutation' }
  & { approveComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'state'>
  ) }
);

export type RejectCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  rejectionReason: CommentRejectionReason;
}>;


export type RejectCommentMutation = (
  { __typename?: 'Mutation' }
  & { rejectComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'state' | 'rejectionReason'>
  ) }
);

export type RequestChangesOnCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  rejectionReason: CommentRejectionReason;
}>;


export type RequestChangesOnCommentMutation = (
  { __typename?: 'Mutation' }
  & { requestChangesOnComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'state' | 'rejectionReason'>
  ) }
);

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  revision?: Maybe<CommentRevisionUpdateInput>;
  userID?: Maybe<Scalars['ID']>;
  guestUsername?: Maybe<Scalars['String']>;
  guestUserImageID?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
  tagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment: (
    { __typename?: 'Comment' }
    & FullCommentFragment
  ) }
);

export type CreateCommentMutationVariables = Exact<{
  itemID: Scalars['ID'];
  itemType: CommentItemType;
  parentID?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['RichText']>;
  tagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
  ) }
);

export type DeleteCommentMutationVariables = Exact<{
  deleteCommentId: Scalars['ID'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
  ) }
);

export type MetadataPropertyFragment = (
  { __typename?: 'Properties' }
  & Pick<Properties, 'key' | 'value' | 'public'>
);

export type PageInfoFragment = (
  { __typename?: 'PageInfo' }
  & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
);

export type ImageUrLsFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'url'>
  & { largeURL: Image['transformURL'], mediumURL: Image['transformURL'], thumbURL: Image['transformURL'], squareURL: Image['transformURL'], previewURL: Image['transformURL'], column1URL: Image['transformURL'], column6URL: Image['transformURL'] }
);

export type ImageRefFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'id' | 'filename' | 'extension' | 'title' | 'description' | 'width' | 'height'>
  & ImageUrLsFragment
);

export type FullImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'id' | 'createdAt' | 'modifiedAt' | 'filename' | 'extension' | 'width' | 'height' | 'fileSize' | 'description' | 'tags' | 'source' | 'link' | 'license'>
  & { focalPoint?: Maybe<(
    { __typename?: 'Point' }
    & Pick<Point, 'x' | 'y'>
  )> }
  & ImageRefFragment
);

export type ImageListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type ImageListQuery = (
  { __typename?: 'Query' }
  & { images: (
    { __typename?: 'ImageConnection' }
    & Pick<ImageConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Image' }
      & ImageRefFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type ImageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ImageQuery = (
  { __typename?: 'Query' }
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & FullImageFragment
  )> }
);

export type UploadImageMutationVariables = Exact<{
  input: UploadImageInput;
}>;


export type UploadImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadImage?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )> }
);

export type UpdateImageMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateImageInput;
}>;


export type UpdateImageMutation = (
  { __typename?: 'Mutation' }
  & { updateImage?: Maybe<(
    { __typename?: 'Image' }
    & FullImageFragment
  )> }
);

export type DeleteImageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteImage?: Maybe<(
    { __typename?: 'Image' }
    & FullImageFragment
  )> }
);

export type InvoiceFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'total' | 'paidAt' | 'description' | 'mail' | 'manuallySetAsPaidByUserId' | 'canceledAt' | 'modifiedAt' | 'createdAt'>
  & { items: Array<(
    { __typename?: 'InvoiceItem' }
    & Pick<InvoiceItem, 'createdAt' | 'modifiedAt' | 'name' | 'description' | 'quantity' | 'amount' | 'total'>
  )> }
);

export type InvoicesQueryVariables = Exact<{
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<InvoiceFilter>;
  sort?: Maybe<InvoiceSort>;
  order?: Maybe<SortOrder>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceConnection' }
    & Pick<InvoiceConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Invoice' }
      & InvoiceFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ) }
  ) }
);

export type UpdateInvoiceMutationVariables = Exact<{
  updateInvoiceId: Scalars['ID'];
  input: InvoiceInput;
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & InvoiceFragment
  )> }
);

export type MemberPlanRefFragment = (
  { __typename?: 'MemberPlan' }
  & Pick<MemberPlan, 'id' | 'name' | 'description' | 'slug' | 'active' | 'tags'>
  & { image?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )> }
);

export type FullMemberPlanFragment = (
  { __typename?: 'MemberPlan' }
  & Pick<MemberPlan, 'tags' | 'amountPerMonthMin'>
  & { availablePaymentMethods: Array<(
    { __typename?: 'AvailablePaymentMethod' }
    & Pick<AvailablePaymentMethod, 'paymentPeriodicities' | 'forceAutoRenewal'>
    & { paymentMethods: Array<(
      { __typename?: 'PaymentMethod' }
      & FullPaymentMethodFragment
    )> }
  )> }
  & MemberPlanRefFragment
);

export type MemberPlanListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type MemberPlanListQuery = (
  { __typename?: 'Query' }
  & { memberPlans: (
    { __typename?: 'MemberPlanConnection' }
    & Pick<MemberPlanConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'MemberPlan' }
      & FullMemberPlanFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type MemberPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MemberPlanQuery = (
  { __typename?: 'Query' }
  & { memberPlan?: Maybe<(
    { __typename?: 'MemberPlan' }
    & FullMemberPlanFragment
  )> }
);

export type CreateMemberPlanMutationVariables = Exact<{
  input: MemberPlanInput;
}>;


export type CreateMemberPlanMutation = (
  { __typename?: 'Mutation' }
  & { createMemberPlan?: Maybe<(
    { __typename?: 'MemberPlan' }
    & FullMemberPlanFragment
  )> }
);

export type UpdateMemberPlanMutationVariables = Exact<{
  id: Scalars['ID'];
  input: MemberPlanInput;
}>;


export type UpdateMemberPlanMutation = (
  { __typename?: 'Mutation' }
  & { updateMemberPlan?: Maybe<(
    { __typename?: 'MemberPlan' }
    & FullMemberPlanFragment
  )> }
);

export type DeleteMemberPlanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMemberPlanMutation = (
  { __typename?: 'Mutation' }
  & { deleteMemberPlan?: Maybe<(
    { __typename?: 'MemberPlan' }
    & FullMemberPlanFragment
  )> }
);

export type FullNavigationFragment = (
  { __typename?: 'Navigation' }
  & Pick<Navigation, 'id' | 'key' | 'name'>
  & { links: Array<(
    { __typename: 'PageNavigationLink' }
    & Pick<PageNavigationLink, 'label'>
    & { page?: Maybe<(
      { __typename?: 'Page' }
      & PageRefFragment
    )> }
  ) | (
    { __typename: 'ArticleNavigationLink' }
    & Pick<ArticleNavigationLink, 'label'>
    & { article?: Maybe<(
      { __typename?: 'Article' }
      & ArticleRefFragment
    )> }
  ) | (
    { __typename: 'ExternalNavigationLink' }
    & Pick<ExternalNavigationLink, 'label' | 'url'>
  )> }
);

export type NavigationListQueryVariables = Exact<{ [key: string]: never; }>;


export type NavigationListQuery = (
  { __typename?: 'Query' }
  & { navigations: Array<(
    { __typename?: 'Navigation' }
    & FullNavigationFragment
  )> }
);

export type NavigationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NavigationQuery = (
  { __typename?: 'Query' }
  & { navigation?: Maybe<(
    { __typename?: 'Navigation' }
    & FullNavigationFragment
  )> }
);

export type CreateNavigationMutationVariables = Exact<{
  input: NavigationInput;
}>;


export type CreateNavigationMutation = (
  { __typename?: 'Mutation' }
  & { createNavigation?: Maybe<(
    { __typename?: 'Navigation' }
    & FullNavigationFragment
  )> }
);

export type UpdateNavigationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: NavigationInput;
}>;


export type UpdateNavigationMutation = (
  { __typename?: 'Mutation' }
  & { updateNavigation?: Maybe<(
    { __typename?: 'Navigation' }
    & FullNavigationFragment
  )> }
);

export type DeleteNavigationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNavigationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNavigation?: Maybe<(
    { __typename?: 'Navigation' }
    & FullNavigationFragment
  )> }
);

export type MutationPageFragment = (
  { __typename?: 'Page' }
  & Pick<Page, 'id'>
  & { draft?: Maybe<(
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'publishedAt' | 'updatedAt' | 'revision'>
  )>, pending?: Maybe<(
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'publishAt' | 'revision'>
  )>, published?: Maybe<(
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'publishedAt' | 'updatedAt' | 'publishAt' | 'revision'>
  )>, latest: (
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'publishedAt' | 'updatedAt' | 'publishAt' | 'revision'>
  ) }
);

export type PageRefFragment = (
  { __typename?: 'Page' }
  & Pick<Page, 'id' | 'createdAt' | 'modifiedAt'>
  & { draft?: Maybe<(
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'revision'>
  )>, pending?: Maybe<(
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'publishAt' | 'revision'>
  )>, published?: Maybe<(
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'publishedAt' | 'updatedAt' | 'revision'>
  )>, latest: (
    { __typename?: 'PageRevision' }
    & Pick<PageRevision, 'publishedAt' | 'updatedAt' | 'revision' | 'title' | 'description'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & ImageRefFragment
    )> }
  ) }
);

export type PageListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<PageSort>;
}>;


export type PageListQuery = (
  { __typename?: 'Query' }
  & { pages: (
    { __typename?: 'PageConnection' }
    & Pick<PageConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Page' }
      & PageRefFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type CreatePageMutationVariables = Exact<{
  input: PageInput;
}>;


export type CreatePageMutation = (
  { __typename?: 'Mutation' }
  & { createPage: (
    { __typename?: 'Page' }
    & MutationPageFragment
  ) }
);

export type UpdatePageMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PageInput;
}>;


export type UpdatePageMutation = (
  { __typename?: 'Mutation' }
  & { updatePage?: Maybe<(
    { __typename?: 'Page' }
    & MutationPageFragment
  )> }
);

export type PublishPageMutationVariables = Exact<{
  id: Scalars['ID'];
  publishAt?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
}>;


export type PublishPageMutation = (
  { __typename?: 'Mutation' }
  & { publishPage?: Maybe<(
    { __typename?: 'Page' }
    & MutationPageFragment
  )> }
);

export type UnpublishPageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnpublishPageMutation = (
  { __typename?: 'Mutation' }
  & { unpublishPage?: Maybe<(
    { __typename?: 'Page' }
    & MutationPageFragment
  )> }
);

export type DeletePageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePageMutation = (
  { __typename?: 'Mutation' }
  & { deletePage?: Maybe<(
    { __typename?: 'Page' }
    & MutationPageFragment
  )> }
);

export type DuplicatePageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicatePageMutation = (
  { __typename?: 'Mutation' }
  & { duplicatePage: (
    { __typename?: 'Page' }
    & MutationPageFragment
  ) }
);

export type PagePreviewLinkQueryVariables = Exact<{
  id: Scalars['ID'];
  hours: Scalars['Int'];
}>;


export type PagePreviewLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pagePreviewLink'>
);

export type PageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PageQuery = (
  { __typename?: 'Query' }
  & { page?: Maybe<(
    { __typename?: 'Page' }
    & Pick<Page, 'id'>
    & { draft?: Maybe<(
      { __typename?: 'PageRevision' }
      & Pick<PageRevision, 'publishAt'>
    )>, pending?: Maybe<(
      { __typename?: 'PageRevision' }
      & Pick<PageRevision, 'publishAt'>
    )>, published?: Maybe<(
      { __typename?: 'PageRevision' }
      & Pick<PageRevision, 'publishedAt' | 'updatedAt' | 'publishAt'>
    )>, latest: (
      { __typename?: 'PageRevision' }
      & Pick<PageRevision, 'publishedAt' | 'updatedAt' | 'publishAt' | 'slug' | 'title' | 'description' | 'tags' | 'url' | 'socialMediaTitle' | 'socialMediaDescription'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & ImageRefFragment
      )>, properties: Array<(
        { __typename?: 'Properties' }
        & Pick<Properties, 'key' | 'value' | 'public'>
      )>, socialMediaImage?: Maybe<(
        { __typename?: 'Image' }
        & ImageRefFragment
      )>, blocks: Array<(
        { __typename?: 'RichTextBlock' }
        & FullBlock_RichTextBlock_Fragment
      ) | (
        { __typename?: 'ImageBlock' }
        & FullBlock_ImageBlock_Fragment
      ) | (
        { __typename?: 'ImageGalleryBlock' }
        & FullBlock_ImageGalleryBlock_Fragment
      ) | (
        { __typename?: 'ListicleBlock' }
        & FullBlock_ListicleBlock_Fragment
      ) | (
        { __typename?: 'FacebookPostBlock' }
        & FullBlock_FacebookPostBlock_Fragment
      ) | (
        { __typename?: 'FacebookVideoBlock' }
        & FullBlock_FacebookVideoBlock_Fragment
      ) | (
        { __typename?: 'InstagramPostBlock' }
        & FullBlock_InstagramPostBlock_Fragment
      ) | (
        { __typename?: 'TwitterTweetBlock' }
        & FullBlock_TwitterTweetBlock_Fragment
      ) | (
        { __typename?: 'VimeoVideoBlock' }
        & FullBlock_VimeoVideoBlock_Fragment
      ) | (
        { __typename?: 'YouTubeVideoBlock' }
        & FullBlock_YouTubeVideoBlock_Fragment
      ) | (
        { __typename?: 'SoundCloudTrackBlock' }
        & FullBlock_SoundCloudTrackBlock_Fragment
      ) | (
        { __typename?: 'PolisConversationBlock' }
        & FullBlock_PolisConversationBlock_Fragment
      ) | (
        { __typename?: 'TikTokVideoBlock' }
        & FullBlock_TikTokVideoBlock_Fragment
      ) | (
        { __typename?: 'BildwurfAdBlock' }
        & FullBlock_BildwurfAdBlock_Fragment
      ) | (
        { __typename?: 'EmbedBlock' }
        & FullBlock_EmbedBlock_Fragment
      ) | (
        { __typename?: 'HTMLBlock' }
        & FullBlock_HtmlBlock_Fragment
      ) | (
        { __typename?: 'PollBlock' }
        & FullBlock_PollBlock_Fragment
      ) | (
        { __typename?: 'CommentBlock' }
        & FullBlock_CommentBlock_Fragment
      ) | (
        { __typename?: 'LinkPageBreakBlock' }
        & FullBlock_LinkPageBreakBlock_Fragment
      ) | (
        { __typename?: 'TitleBlock' }
        & FullBlock_TitleBlock_Fragment
      ) | (
        { __typename?: 'QuoteBlock' }
        & FullBlock_QuoteBlock_Fragment
      ) | (
        { __typename?: 'TeaserGridBlock' }
        & FullBlock_TeaserGridBlock_Fragment
      ) | (
        { __typename?: 'TeaserGridFlexBlock' }
        & FullBlock_TeaserGridFlexBlock_Fragment
      )> }
    ) }
  )> }
);

export type FullPaymentProviderFragment = (
  { __typename?: 'PaymentProvider' }
  & Pick<PaymentProvider, 'id' | 'name'>
);

export type FullPaymentMethodFragment = (
  { __typename?: 'PaymentMethod' }
  & Pick<PaymentMethod, 'id' | 'name' | 'slug' | 'createdAt' | 'modifiedAt' | 'description' | 'active'>
  & { paymentProvider: (
    { __typename?: 'PaymentProvider' }
    & FullPaymentProviderFragment
  ) }
);

export type PaymentProviderListQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentProviderListQuery = (
  { __typename?: 'Query' }
  & { paymentProviders: Array<(
    { __typename?: 'PaymentProvider' }
    & FullPaymentProviderFragment
  )> }
);

export type PaymentMethodListQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentMethodListQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Array<(
    { __typename?: 'PaymentMethod' }
    & FullPaymentMethodFragment
  )> }
);

export type PaymentMethodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PaymentMethodQuery = (
  { __typename?: 'Query' }
  & { paymentMethod?: Maybe<(
    { __typename?: 'PaymentMethod' }
    & FullPaymentMethodFragment
  )> }
);

export type CreatePaymentMethodMutationVariables = Exact<{
  input: PaymentMethodInput;
}>;


export type CreatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentMethod?: Maybe<(
    { __typename?: 'PaymentMethod' }
    & FullPaymentMethodFragment
  )> }
);

export type UpdatePaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
  input: PaymentMethodInput;
}>;


export type UpdatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentMethod?: Maybe<(
    { __typename?: 'PaymentMethod' }
    & FullPaymentMethodFragment
  )> }
);

export type DeletePaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { deletePaymentMethod?: Maybe<(
    { __typename?: 'PaymentMethod' }
    & FullPaymentMethodFragment
  )> }
);

export type FullPeerProfileFragment = (
  { __typename?: 'PeerProfile' }
  & Pick<PeerProfile, 'name' | 'hostURL' | 'themeColor' | 'themeFontColor' | 'callToActionText' | 'callToActionURL' | 'callToActionImageURL'>
  & { logo?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )>, callToActionImage?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )> }
);

export type PeerRefFragment = (
  { __typename?: 'Peer' }
  & Pick<Peer, 'id' | 'name' | 'slug' | 'isDisabled' | 'hostURL'>
  & { profile?: Maybe<(
    { __typename?: 'PeerProfile' }
    & FullPeerProfileFragment
  )> }
);

export type PeerWithProfileFragment = (
  { __typename?: 'Peer' }
  & { profile?: Maybe<(
    { __typename?: 'PeerProfile' }
    & FullPeerProfileFragment
  )> }
  & PeerRefFragment
);

export type PeerProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type PeerProfileQuery = (
  { __typename?: 'Query' }
  & { peerProfile: (
    { __typename?: 'PeerProfile' }
    & FullPeerProfileFragment
  ) }
);

export type RemotePeerProfileQueryVariables = Exact<{
  hostURL: Scalars['String'];
  token: Scalars['String'];
}>;


export type RemotePeerProfileQuery = (
  { __typename?: 'Query' }
  & { remotePeerProfile?: Maybe<(
    { __typename?: 'PeerProfile' }
    & FullPeerProfileFragment
  )> }
);

export type UpdatePeerProfileMutationVariables = Exact<{
  input: PeerProfileInput;
}>;


export type UpdatePeerProfileMutation = (
  { __typename?: 'Mutation' }
  & { updatePeerProfile: (
    { __typename?: 'PeerProfile' }
    & FullPeerProfileFragment
  ) }
);

export type PeerListQueryVariables = Exact<{ [key: string]: never; }>;


export type PeerListQuery = (
  { __typename?: 'Query' }
  & { peers?: Maybe<Array<(
    { __typename?: 'Peer' }
    & PeerWithProfileFragment
  )>> }
);

export type PeerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PeerQuery = (
  { __typename?: 'Query' }
  & { peer?: Maybe<(
    { __typename?: 'Peer' }
    & PeerRefFragment
  )> }
);

export type CreatePeerMutationVariables = Exact<{
  input: CreatePeerInput;
}>;


export type CreatePeerMutation = (
  { __typename?: 'Mutation' }
  & { createPeer: (
    { __typename?: 'Peer' }
    & PeerRefFragment
  ) }
);

export type UpdatePeerMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdatePeerInput;
}>;


export type UpdatePeerMutation = (
  { __typename?: 'Mutation' }
  & { updatePeer: (
    { __typename?: 'Peer' }
    & PeerRefFragment
  ) }
);

export type DeletePeerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePeerMutation = (
  { __typename?: 'Mutation' }
  & { deletePeer?: Maybe<(
    { __typename?: 'Peer' }
    & PeerRefFragment
  )> }
);

export type PollExternalVoteSourceFragment = (
  { __typename?: 'PollExternalVoteSource' }
  & Pick<PollExternalVoteSource, 'id' | 'source'>
  & { voteAmounts?: Maybe<Array<(
    { __typename?: 'PollExternalVote' }
    & Pick<PollExternalVote, 'id' | 'answerId' | 'amount'>
  )>> }
);

export type CreatePollMutationVariables = Exact<{
  opensAt?: Maybe<Scalars['DateTime']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
}>;


export type CreatePollMutation = (
  { __typename?: 'Mutation' }
  & { createPoll?: Maybe<(
    { __typename?: 'PollWithAnswers' }
    & Pick<PollWithAnswers, 'id' | 'question' | 'opensAt' | 'closedAt'>
    & { answers?: Maybe<Array<(
      { __typename?: 'PollAnswer' }
      & Pick<PollAnswer, 'id' | 'pollId' | 'answer'>
    )>> }
  )> }
);

export type UpdatePollMutationVariables = Exact<{
  pollId: Scalars['ID'];
  opensAt?: Maybe<Scalars['DateTime']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  question?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<UpdatePollAnswer> | UpdatePollAnswer>;
  externalVoteSources?: Maybe<Array<UpdatePollExternalVoteSources> | UpdatePollExternalVoteSources>;
}>;


export type UpdatePollMutation = (
  { __typename?: 'Mutation' }
  & { updatePoll?: Maybe<(
    { __typename?: 'FullPoll' }
    & Pick<FullPoll, 'id' | 'question' | 'opensAt' | 'closedAt'>
    & { answers?: Maybe<Array<(
      { __typename?: 'PollAnswerWithVoteCount' }
      & Pick<PollAnswerWithVoteCount, 'id' | 'pollId' | 'answer' | 'votes'>
    )>>, externalVoteSources?: Maybe<Array<(
      { __typename?: 'PollExternalVoteSource' }
      & PollExternalVoteSourceFragment
    )>> }
  )> }
);

export type DeletePollMutationVariables = Exact<{
  deletePollId: Scalars['ID'];
}>;


export type DeletePollMutation = (
  { __typename?: 'Mutation' }
  & { deletePoll?: Maybe<(
    { __typename?: 'FullPoll' }
    & Pick<FullPoll, 'id'>
  )> }
);

export type CreatePollAnswerMutationVariables = Exact<{
  pollId: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
}>;


export type CreatePollAnswerMutation = (
  { __typename?: 'Mutation' }
  & { createPollAnswer?: Maybe<(
    { __typename?: 'PollAnswer' }
    & Pick<PollAnswer, 'answer' | 'id' | 'pollId'>
  )> }
);

export type DeletePollAnswerMutationVariables = Exact<{
  deletePollAnswerId: Scalars['ID'];
}>;


export type DeletePollAnswerMutation = (
  { __typename?: 'Mutation' }
  & { deletePollAnswer?: Maybe<(
    { __typename?: 'PollAnswerWithVoteCount' }
    & Pick<PollAnswerWithVoteCount, 'id'>
  )> }
);

export type CreatePollExternalVoteSourceMutationVariables = Exact<{
  pollId: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
}>;


export type CreatePollExternalVoteSourceMutation = (
  { __typename?: 'Mutation' }
  & { createPollExternalVoteSource?: Maybe<(
    { __typename?: 'PollExternalVoteSource' }
    & PollExternalVoteSourceFragment
  )> }
);

export type DeletePollExternalVoteSourceMutationVariables = Exact<{
  deletePollExternalVoteSourceId: Scalars['ID'];
}>;


export type DeletePollExternalVoteSourceMutation = (
  { __typename?: 'Mutation' }
  & { deletePollExternalVoteSource?: Maybe<(
    { __typename?: 'PollExternalVoteSource' }
    & PollExternalVoteSourceFragment
  )> }
);

export type PollsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<PollFilter>;
  sort?: Maybe<PollSort>;
  order?: Maybe<SortOrder>;
}>;


export type PollsQuery = (
  { __typename?: 'Query' }
  & { polls?: Maybe<(
    { __typename?: 'PollConnection' }
    & Pick<PollConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Poll' }
      & Pick<Poll, 'id' | 'question' | 'opensAt' | 'closedAt'>
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type PollQueryVariables = Exact<{
  pollId?: Maybe<Scalars['ID']>;
}>;


export type PollQuery = (
  { __typename?: 'Query' }
  & { poll?: Maybe<(
    { __typename?: 'FullPoll' }
    & Pick<FullPoll, 'id' | 'question' | 'opensAt' | 'closedAt'>
    & { answers?: Maybe<Array<(
      { __typename?: 'PollAnswerWithVoteCount' }
      & Pick<PollAnswerWithVoteCount, 'id' | 'pollId' | 'answer' | 'votes'>
    )>>, externalVoteSources?: Maybe<Array<(
      { __typename?: 'PollExternalVoteSource' }
      & PollExternalVoteSourceFragment
    )>> }
  )> }
);

export type FullSettingFragment = (
  { __typename?: 'Setting' }
  & Pick<Setting, 'id' | 'name' | 'value'>
  & { settingRestriction?: Maybe<(
    { __typename?: 'SettingRestriction' }
    & Pick<SettingRestriction, 'maxValue' | 'minValue' | 'inputLength'>
  )> }
);

export type SettingListQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingListQuery = (
  { __typename?: 'Query' }
  & { settings: Array<(
    { __typename?: 'Setting' }
    & Pick<Setting, 'id' | 'name' | 'value'>
    & { settingRestriction?: Maybe<(
      { __typename?: 'SettingRestriction' }
      & Pick<SettingRestriction, 'maxValue' | 'minValue' | 'inputLength'>
      & { allowedValues?: Maybe<(
        { __typename?: 'AllowedSettingVals' }
        & Pick<AllowedSettingVals, 'stringChoice' | 'boolChoice'>
      )> }
    )> }
  )> }
);

export type UpdateSettingListMutationVariables = Exact<{
  input?: Maybe<Array<UpdateSettingArgs> | UpdateSettingArgs>;
}>;


export type UpdateSettingListMutation = (
  { __typename?: 'Mutation' }
  & { updateSettingList?: Maybe<Array<Maybe<(
    { __typename?: 'Setting' }
    & Pick<Setting, 'value'>
  )>>> }
);

export type FullSubscriptionFragment = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'id' | 'createdAt' | 'modifiedAt' | 'paymentPeriodicity' | 'monthlyAmount' | 'autoRenew' | 'startsAt' | 'paidUntil'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )>, memberPlan: (
    { __typename?: 'MemberPlan' }
    & FullMemberPlanFragment
  ), properties: Array<(
    { __typename?: 'Properties' }
    & MetadataPropertyFragment
  )>, paymentMethod: (
    { __typename?: 'PaymentMethod' }
    & FullPaymentMethodFragment
  ), deactivation?: Maybe<(
    { __typename?: 'SubscriptionDeactivation' }
    & DeactivationFragment
  )> }
);

export type DeactivationFragment = (
  { __typename?: 'SubscriptionDeactivation' }
  & Pick<SubscriptionDeactivation, 'date' | 'reason'>
);

export type SubscriptionListQueryVariables = Exact<{
  filter?: Maybe<SubscriptionFilter>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<SubscriptionSort>;
}>;


export type SubscriptionListQuery = (
  { __typename?: 'Query' }
  & { subscriptions: (
    { __typename?: 'SubscriptionConnection' }
    & Pick<SubscriptionConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Subscription' }
      & FullSubscriptionFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type SubscriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubscriptionQuery = (
  { __typename?: 'Query' }
  & { subscription?: Maybe<(
    { __typename?: 'Subscription' }
    & FullSubscriptionFragment
  )> }
);

export type SubscriptionsAsCsvQueryVariables = Exact<{
  filter?: Maybe<SubscriptionFilter>;
}>;


export type SubscriptionsAsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subscriptionsAsCsv'>
);

export type CreateSubscriptionMutationVariables = Exact<{
  input: SubscriptionInput;
}>;


export type CreateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { createSubscription?: Maybe<(
    { __typename?: 'Subscription' }
    & FullSubscriptionFragment
  )> }
);

export type UpdateSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
  input: SubscriptionInput;
}>;


export type UpdateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateSubscription?: Maybe<(
    { __typename?: 'Subscription' }
    & FullSubscriptionFragment
  )> }
);

export type DeleteSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { deleteSubscription?: Maybe<(
    { __typename?: 'Subscription' }
    & FullSubscriptionFragment
  )> }
);

export type TagListQueryVariables = Exact<{
  filter?: Maybe<TagFilter>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<TagSort>;
}>;


export type TagListQuery = (
  { __typename?: 'Query' }
  & { tags?: Maybe<(
    { __typename?: 'TagConnection' }
    & Pick<TagConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'tag'>
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type CreateTagMutationVariables = Exact<{
  tag?: Maybe<Scalars['String']>;
  type: TagType;
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag'>
  )> }
);

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['ID'];
  tag?: Maybe<Scalars['String']>;
}>;


export type UpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { updateTag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag'>
  )> }
);

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteTag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'tag'>
  )> }
);

export type TokenRefFragment = (
  { __typename?: 'Token' }
  & Pick<Token, 'id' | 'name'>
);

export type TokenListQueryVariables = Exact<{ [key: string]: never; }>;


export type TokenListQuery = (
  { __typename?: 'Query' }
  & { tokens: Array<(
    { __typename?: 'Token' }
    & TokenRefFragment
  )> }
);

export type CreateTokenMutationVariables = Exact<{
  input: TokenInput;
}>;


export type CreateTokenMutation = (
  { __typename?: 'Mutation' }
  & { createToken: (
    { __typename?: 'CreatedToken' }
    & Pick<CreatedToken, 'id' | 'name' | 'token'>
  ) }
);

export type DeleteTokenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTokenMutation = (
  { __typename?: 'Mutation' }
  & { deleteToken?: Maybe<(
    { __typename?: 'CreatedToken' }
    & Pick<CreatedToken, 'id' | 'name' | 'token'>
  )> }
);

export type FullUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'createdAt' | 'modifiedAt' | 'name' | 'firstName' | 'preferredName' | 'active' | 'lastLogin' | 'email' | 'emailVerifiedAt'>
  & { address?: Maybe<(
    { __typename?: 'UserAddress' }
    & Pick<UserAddress, 'company' | 'streetAddress' | 'streetAddress2' | 'zipCode' | 'city' | 'country'>
  )>, userImage?: Maybe<(
    { __typename?: 'Image' }
    & ImageRefFragment
  )>, properties: Array<(
    { __typename?: 'Properties' }
    & Pick<Properties, 'key' | 'value' | 'public'>
  )>, roles: Array<(
    { __typename?: 'UserRole' }
    & FullUserRoleFragment
  )>, subscriptions: Array<(
    { __typename?: 'UserSubscription' }
    & UserSubscriptionFragment
  )> }
);

export type UserSubscriptionFragment = (
  { __typename?: 'UserSubscription' }
  & Pick<UserSubscription, 'id' | 'createdAt' | 'modifiedAt' | 'paymentPeriodicity' | 'monthlyAmount' | 'autoRenew' | 'startsAt' | 'paidUntil'>
  & { periods: Array<(
    { __typename?: 'SubscriptionPeriod' }
    & Pick<SubscriptionPeriod, 'id' | 'amount' | 'createdAt' | 'endsAt' | 'invoiceID' | 'paymentPeriodicity' | 'startsAt'>
  )>, properties: Array<(
    { __typename?: 'Properties' }
    & Pick<Properties, 'key' | 'value' | 'public'>
  )>, deactivation?: Maybe<(
    { __typename?: 'SubscriptionDeactivation' }
    & DeactivationFragment
  )>, memberPlan: (
    { __typename?: 'MemberPlan' }
    & MemberPlanRefFragment
  ), invoices: Array<(
    { __typename?: 'Invoice' }
    & InvoiceFragment
  )> }
);

export type UserListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order?: Maybe<SortOrder>;
  sort?: Maybe<UserSort>;
}>;


export type UserListQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserConnection' }
    & Pick<UserConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'User' }
      & FullUserFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
  password: Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )> }
);

export type ResetUserPasswordMutationVariables = Exact<{
  id: Scalars['ID'];
  password: Scalars['String'];
}>;


export type ResetUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetUserPassword?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & FullUserFragment
  )> }
);

export type SendWebsiteLoginMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendWebsiteLoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendWebsiteLogin'>
);

export type FullPermissionFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'id' | 'description' | 'deprecated'>
);

export type FullUserRoleFragment = (
  { __typename?: 'UserRole' }
  & Pick<UserRole, 'id' | 'name' | 'description' | 'systemRole'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & FullPermissionFragment
  )> }
);

export type UserRoleListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type UserRoleListQuery = (
  { __typename?: 'Query' }
  & { userRoles: (
    { __typename?: 'UserRoleConnection' }
    & Pick<UserRoleConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'UserRole' }
      & FullUserRoleFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  ) }
);

export type PermissionListQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionListQuery = (
  { __typename?: 'Query' }
  & { permissions?: Maybe<Array<(
    { __typename?: 'Permission' }
    & FullPermissionFragment
  )>> }
);

export type UserRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserRoleQuery = (
  { __typename?: 'Query' }
  & { userRole?: Maybe<(
    { __typename?: 'UserRole' }
    & FullUserRoleFragment
  )> }
);

export type CreateUserRoleMutationVariables = Exact<{
  input: UserRoleInput;
}>;


export type CreateUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { createUserRole?: Maybe<(
    { __typename?: 'UserRole' }
    & FullUserRoleFragment
  )> }
);

export type UpdateUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserRoleInput;
}>;


export type UpdateUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateUserRole?: Maybe<(
    { __typename?: 'UserRole' }
    & FullUserRoleFragment
  )> }
);

export type DeleteUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserRole?: Maybe<(
    { __typename?: 'UserRole' }
    & FullUserRoleFragment
  )> }
);

export const MutationArticleFragmentDoc = gql`
    fragment MutationArticle on Article {
  id
  draft {
    publishedAt
    updatedAt
    revision
  }
  pending {
    publishAt
    revision
  }
  published {
    publishedAt
    updatedAt
    publishAt
    revision
  }
  latest {
    publishedAt
    updatedAt
    publishAt
    revision
  }
}
    `;
export const ImageUrLsFragmentDoc = gql`
    fragment ImageURLs on Image {
  url
  largeURL: transformURL(input: {width: 500})
  mediumURL: transformURL(input: {width: 300})
  thumbURL: transformURL(input: {width: 280, height: 200})
  squareURL: transformURL(input: {width: 100, height: 100})
  previewURL: transformURL(input: {width: 400, height: 200})
  column1URL: transformURL(input: {width: 800, height: 300})
  column6URL: transformURL(input: {width: 260, height: 300})
}
    `;
export const ImageRefFragmentDoc = gql`
    fragment ImageRef on Image {
  id
  filename
  extension
  title
  description
  width
  height
  ...ImageURLs
}
    ${ImageUrLsFragmentDoc}`;
export const AuthorRefFragmentDoc = gql`
    fragment AuthorRef on Author {
  id
  name
  jobTitle
  image {
    ...ImageRef
  }
}
    ${ImageRefFragmentDoc}`;
export const FullAuthorFragmentDoc = gql`
    fragment FullAuthor on Author {
  slug
  links {
    title
    url
  }
  bio
  createdAt
  ...AuthorRef
}
    ${AuthorRefFragmentDoc}`;
export const FullPermissionFragmentDoc = gql`
    fragment FullPermission on Permission {
  id
  description
  deprecated
}
    `;
export const FullUserRoleFragmentDoc = gql`
    fragment FullUserRole on UserRole {
  id
  name
  description
  systemRole
  permissions {
    ...FullPermission
  }
}
    ${FullPermissionFragmentDoc}`;
export const DeactivationFragmentDoc = gql`
    fragment Deactivation on SubscriptionDeactivation {
  date
  reason
}
    `;
export const MemberPlanRefFragmentDoc = gql`
    fragment MemberPlanRef on MemberPlan {
  id
  name
  description
  slug
  active
  tags
  image {
    ...ImageRef
  }
}
    ${ImageRefFragmentDoc}`;
export const InvoiceFragmentDoc = gql`
    fragment Invoice on Invoice {
  id
  total
  items {
    createdAt
    modifiedAt
    name
    description
    quantity
    amount
    total
  }
  paidAt
  description
  mail
  manuallySetAsPaidByUserId
  canceledAt
  modifiedAt
  createdAt
}
    `;
export const UserSubscriptionFragmentDoc = gql`
    fragment UserSubscription on UserSubscription {
  id
  createdAt
  modifiedAt
  paymentPeriodicity
  monthlyAmount
  autoRenew
  startsAt
  paidUntil
  periods {
    id
    amount
    createdAt
    endsAt
    invoiceID
    paymentPeriodicity
    startsAt
  }
  properties {
    key
    value
    public
  }
  deactivation {
    ...Deactivation
  }
  memberPlan {
    ...MemberPlanRef
  }
  invoices {
    ...Invoice
  }
}
    ${DeactivationFragmentDoc}
${MemberPlanRefFragmentDoc}
${InvoiceFragmentDoc}`;
export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  id
  createdAt
  modifiedAt
  name
  firstName
  preferredName
  address {
    company
    streetAddress
    streetAddress2
    zipCode
    city
    country
  }
  userImage {
    ...ImageRef
  }
  active
  lastLogin
  properties {
    key
    value
    public
  }
  email
  emailVerifiedAt
  roles {
    ...FullUserRole
  }
  subscriptions {
    ...UserSubscription
  }
}
    ${ImageRefFragmentDoc}
${FullUserRoleFragmentDoc}
${UserSubscriptionFragmentDoc}`;
export const CommentRevisionFragmentDoc = gql`
    fragment CommentRevision on CommentRevision {
  text
  title
  lead
  createdAt
}
    `;
export const FullParentCommentFragmentDoc = gql`
    fragment FullParentComment on Comment {
  id
  state
  rejectionReason
  user {
    ...FullUser
  }
  guestUsername
  revisions {
    ...CommentRevision
  }
  createdAt
  modifiedAt
}
    ${FullUserFragmentDoc}
${CommentRevisionFragmentDoc}`;
export const FullCommentFragmentDoc = gql`
    fragment FullComment on Comment {
  id
  state
  rejectionReason
  guestUsername
  guestUserImage {
    ...ImageRef
  }
  user {
    ...FullUser
  }
  revisions {
    ...CommentRevision
  }
  source
  createdAt
  modifiedAt
  itemID
  itemType
  parentComment {
    ...FullParentComment
  }
  tags {
    id
    tag
  }
}
    ${ImageRefFragmentDoc}
${FullUserFragmentDoc}
${CommentRevisionFragmentDoc}
${FullParentCommentFragmentDoc}`;
export const ArticleRefFragmentDoc = gql`
    fragment ArticleRef on Article {
  id
  createdAt
  modifiedAt
  draft {
    revision
  }
  pending {
    publishAt
    revision
  }
  published {
    publishedAt
    updatedAt
    revision
  }
  latest {
    publishedAt
    updatedAt
    revision
    preTitle
    title
    lead
    authors {
      name
    }
    image {
      ...ImageRef
    }
    canonicalUrl
  }
}
    ${ImageRefFragmentDoc}`;
export const FullPeerProfileFragmentDoc = gql`
    fragment FullPeerProfile on PeerProfile {
  name
  hostURL
  themeColor
  themeFontColor
  logo {
    ...ImageRef
  }
  callToActionText
  callToActionURL
  callToActionImage {
    ...ImageRef
  }
  callToActionImageURL
}
    ${ImageRefFragmentDoc}`;
export const PeerRefFragmentDoc = gql`
    fragment PeerRef on Peer {
  id
  name
  slug
  isDisabled
  hostURL
  profile {
    ...FullPeerProfile
  }
}
    ${FullPeerProfileFragmentDoc}`;
export const PeerWithProfileFragmentDoc = gql`
    fragment PeerWithProfile on Peer {
  ...PeerRef
  profile {
    ...FullPeerProfile
  }
}
    ${PeerRefFragmentDoc}
${FullPeerProfileFragmentDoc}`;
export const PageRefFragmentDoc = gql`
    fragment PageRef on Page {
  id
  createdAt
  modifiedAt
  draft {
    revision
  }
  pending {
    publishAt
    revision
  }
  published {
    publishedAt
    updatedAt
    revision
  }
  latest {
    publishedAt
    updatedAt
    revision
    title
    description
    image {
      ...ImageRef
    }
  }
}
    ${ImageRefFragmentDoc}`;
export const FullTeaserFragmentDoc = gql`
    fragment FullTeaser on Teaser {
  ... on ArticleTeaser {
    style
    image {
      ...ImageRef
    }
    preTitle
    title
    lead
    article {
      ...ArticleRef
    }
  }
  ... on PeerArticleTeaser {
    style
    image {
      ...ImageRef
    }
    preTitle
    title
    lead
    peer {
      ...PeerWithProfile
    }
    articleID
    article {
      ...ArticleRef
    }
  }
  ... on PageTeaser {
    style
    image {
      ...ImageRef
    }
    preTitle
    title
    lead
    page {
      ...PageRef
    }
  }
}
    ${ImageRefFragmentDoc}
${ArticleRefFragmentDoc}
${PeerWithProfileFragmentDoc}
${PageRefFragmentDoc}`;
export const FullBlockFragmentDoc = gql`
    fragment FullBlock on Block {
  __typename
  ... on TitleBlock {
    title
    lead
  }
  ... on HTMLBlock {
    html
  }
  ... on RichTextBlock {
    richText
  }
  ... on QuoteBlock {
    quote
    author
  }
  ... on LinkPageBreakBlock {
    text
    linkText
    linkURL
    styleOption
    richText
    linkTarget
    hideButton
    templateOption
    layoutOption
    image {
      ...ImageRef
    }
  }
  ... on PollBlock {
    poll {
      id
      question
    }
  }
  ... on CommentBlock {
    filter {
      item
      tags
      comments
    }
    comments {
      ...FullComment
    }
  }
  ... on ImageBlock {
    caption
    image {
      ...ImageRef
    }
  }
  ... on ImageGalleryBlock {
    images {
      caption
      image {
        ...ImageRef
      }
    }
  }
  ... on ListicleBlock {
    items {
      title
      image {
        ...ImageRef
      }
      richText
    }
  }
  ... on FacebookPostBlock {
    userID
    postID
  }
  ... on FacebookVideoBlock {
    userID
    videoID
  }
  ... on InstagramPostBlock {
    postID
  }
  ... on TwitterTweetBlock {
    userID
    tweetID
  }
  ... on VimeoVideoBlock {
    videoID
  }
  ... on YouTubeVideoBlock {
    videoID
  }
  ... on SoundCloudTrackBlock {
    trackID
  }
  ... on PolisConversationBlock {
    conversationID
  }
  ... on TikTokVideoBlock {
    userID
    videoID
  }
  ... on BildwurfAdBlock {
    zoneID
  }
  ... on EmbedBlock {
    url
    title
    width
    height
    styleCustom
    sandbox
  }
  ... on TeaserGridBlock {
    teasers {
      ...FullTeaser
    }
    numColumns
  }
  ... on TeaserGridFlexBlock {
    flexTeasers {
      alignment {
        i
        x
        y
        w
        h
        static
      }
      teaser {
        ...FullTeaser
      }
    }
  }
}
    ${ImageRefFragmentDoc}
${FullCommentFragmentDoc}
${FullTeaserFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const FullImageFragmentDoc = gql`
    fragment FullImage on Image {
  id
  createdAt
  modifiedAt
  filename
  extension
  width
  height
  fileSize
  description
  tags
  source
  link
  license
  focalPoint {
    x
    y
  }
  ...ImageRef
}
    ${ImageRefFragmentDoc}`;
export const FullNavigationFragmentDoc = gql`
    fragment FullNavigation on Navigation {
  id
  key
  name
  links {
    __typename
    ... on PageNavigationLink {
      label
      page {
        ...PageRef
      }
    }
    ... on ArticleNavigationLink {
      label
      article {
        ...ArticleRef
      }
    }
    ... on ExternalNavigationLink {
      label
      url
    }
  }
}
    ${PageRefFragmentDoc}
${ArticleRefFragmentDoc}`;
export const MutationPageFragmentDoc = gql`
    fragment MutationPage on Page {
  id
  draft {
    publishedAt
    updatedAt
    revision
  }
  pending {
    publishAt
    revision
  }
  published {
    publishedAt
    updatedAt
    publishAt
    revision
  }
  latest {
    publishedAt
    updatedAt
    publishAt
    revision
  }
}
    `;
export const PollExternalVoteSourceFragmentDoc = gql`
    fragment PollExternalVoteSource on PollExternalVoteSource {
  id
  source
  voteAmounts {
    id
    answerId
    amount
  }
}
    `;
export const FullSettingFragmentDoc = gql`
    fragment FullSetting on Setting {
  id
  name
  value
  settingRestriction {
    maxValue
    minValue
    inputLength
  }
}
    `;
export const FullPaymentProviderFragmentDoc = gql`
    fragment FullPaymentProvider on PaymentProvider {
  id
  name
}
    `;
export const FullPaymentMethodFragmentDoc = gql`
    fragment FullPaymentMethod on PaymentMethod {
  id
  name
  slug
  createdAt
  modifiedAt
  paymentProvider {
    ...FullPaymentProvider
  }
  description
  active
}
    ${FullPaymentProviderFragmentDoc}`;
export const FullMemberPlanFragmentDoc = gql`
    fragment FullMemberPlan on MemberPlan {
  tags
  amountPerMonthMin
  availablePaymentMethods {
    paymentMethods {
      ...FullPaymentMethod
    }
    paymentPeriodicities
    forceAutoRenewal
  }
  ...MemberPlanRef
}
    ${FullPaymentMethodFragmentDoc}
${MemberPlanRefFragmentDoc}`;
export const MetadataPropertyFragmentDoc = gql`
    fragment MetadataProperty on Properties {
  key
  value
  public
}
    `;
export const FullSubscriptionFragmentDoc = gql`
    fragment FullSubscription on Subscription {
  id
  createdAt
  modifiedAt
  user {
    ...FullUser
  }
  memberPlan {
    ...FullMemberPlan
  }
  paymentPeriodicity
  monthlyAmount
  autoRenew
  startsAt
  paidUntil
  properties {
    ...MetadataProperty
  }
  paymentMethod {
    ...FullPaymentMethod
  }
  deactivation {
    ...Deactivation
  }
}
    ${FullUserFragmentDoc}
${FullMemberPlanFragmentDoc}
${MetadataPropertyFragmentDoc}
${FullPaymentMethodFragmentDoc}
${DeactivationFragmentDoc}`;
export const TokenRefFragmentDoc = gql`
    fragment TokenRef on Token {
  id
  name
}
    `;
export const ArticleListDocument = gql`
    query ArticleList($filter: ArticleFilter, $cursor: ID, $take: Int, $skip: Int, $order: SortOrder, $sort: ArticleSort) {
  articles(filter: $filter, cursor: $cursor, take: $take, skip: $skip, order: $order, sort: $sort) {
    nodes {
      ...ArticleRef
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${ArticleRefFragmentDoc}`;

/**
 * __useArticleListQuery__
 *
 * To run a query within a React component, call `useArticleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useArticleListQuery(baseOptions?: Apollo.QueryHookOptions<ArticleListQuery, ArticleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleListQuery, ArticleListQueryVariables>(ArticleListDocument, options);
      }
export function useArticleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleListQuery, ArticleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleListQuery, ArticleListQueryVariables>(ArticleListDocument, options);
        }
export type ArticleListQueryHookResult = ReturnType<typeof useArticleListQuery>;
export type ArticleListLazyQueryHookResult = ReturnType<typeof useArticleListLazyQuery>;
export type ArticleListQueryResult = Apollo.QueryResult<ArticleListQuery, ArticleListQueryVariables>;
export const PeerArticleListDocument = gql`
    query PeerArticleList($filter: ArticleFilter, $cursors: String, $peerFilter: String, $order: SortOrder, $sort: ArticleSort) {
  peerArticles(cursors: $cursors, peerFilter: $peerFilter, order: $order, sort: $sort, filter: $filter) {
    nodes {
      peer {
        ...PeerWithProfile
      }
      peeredArticleURL
      article {
        ...ArticleRef
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
  }
}
    ${PeerWithProfileFragmentDoc}
${ArticleRefFragmentDoc}`;

/**
 * __usePeerArticleListQuery__
 *
 * To run a query within a React component, call `usePeerArticleListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeerArticleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeerArticleListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursors: // value for 'cursors'
 *      peerFilter: // value for 'peerFilter'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePeerArticleListQuery(baseOptions?: Apollo.QueryHookOptions<PeerArticleListQuery, PeerArticleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PeerArticleListQuery, PeerArticleListQueryVariables>(PeerArticleListDocument, options);
      }
export function usePeerArticleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeerArticleListQuery, PeerArticleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PeerArticleListQuery, PeerArticleListQueryVariables>(PeerArticleListDocument, options);
        }
export type PeerArticleListQueryHookResult = ReturnType<typeof usePeerArticleListQuery>;
export type PeerArticleListLazyQueryHookResult = ReturnType<typeof usePeerArticleListLazyQuery>;
export type PeerArticleListQueryResult = Apollo.QueryResult<PeerArticleListQuery, PeerArticleListQueryVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($input: ArticleInput!) {
  createArticle(input: $input) {
    ...MutationArticle
  }
}
    ${MutationArticleFragmentDoc}`;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($id: ID!, $input: ArticleInput!) {
  updateArticle(id: $id, input: $input) {
    ...MutationArticle
  }
}
    ${MutationArticleFragmentDoc}`;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const PublishArticleDocument = gql`
    mutation PublishArticle($id: ID!, $publishAt: DateTime!, $publishedAt: DateTime!, $updatedAt: DateTime!) {
  publishArticle(id: $id, publishAt: $publishAt, publishedAt: $publishedAt, updatedAt: $updatedAt) {
    ...MutationArticle
  }
}
    ${MutationArticleFragmentDoc}`;
export type PublishArticleMutationFn = Apollo.MutationFunction<PublishArticleMutation, PublishArticleMutationVariables>;

/**
 * __usePublishArticleMutation__
 *
 * To run a mutation, you first call `usePublishArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishArticleMutation, { data, loading, error }] = usePublishArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publishAt: // value for 'publishAt'
 *      publishedAt: // value for 'publishedAt'
 *      updatedAt: // value for 'updatedAt'
 *   },
 * });
 */
export function usePublishArticleMutation(baseOptions?: Apollo.MutationHookOptions<PublishArticleMutation, PublishArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishArticleMutation, PublishArticleMutationVariables>(PublishArticleDocument, options);
      }
export type PublishArticleMutationHookResult = ReturnType<typeof usePublishArticleMutation>;
export type PublishArticleMutationResult = Apollo.MutationResult<PublishArticleMutation>;
export type PublishArticleMutationOptions = Apollo.BaseMutationOptions<PublishArticleMutation, PublishArticleMutationVariables>;
export const UnpublishArticleDocument = gql`
    mutation UnpublishArticle($id: ID!) {
  unpublishArticle(id: $id) {
    ...MutationArticle
  }
}
    ${MutationArticleFragmentDoc}`;
export type UnpublishArticleMutationFn = Apollo.MutationFunction<UnpublishArticleMutation, UnpublishArticleMutationVariables>;

/**
 * __useUnpublishArticleMutation__
 *
 * To run a mutation, you first call `useUnpublishArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishArticleMutation, { data, loading, error }] = useUnpublishArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpublishArticleMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishArticleMutation, UnpublishArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishArticleMutation, UnpublishArticleMutationVariables>(UnpublishArticleDocument, options);
      }
export type UnpublishArticleMutationHookResult = ReturnType<typeof useUnpublishArticleMutation>;
export type UnpublishArticleMutationResult = Apollo.MutationResult<UnpublishArticleMutation>;
export type UnpublishArticleMutationOptions = Apollo.BaseMutationOptions<UnpublishArticleMutation, UnpublishArticleMutationVariables>;
export const DeleteArticleDocument = gql`
    mutation DeleteArticle($id: ID!) {
  deleteArticle(id: $id) {
    ...MutationArticle
  }
}
    ${MutationArticleFragmentDoc}`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<DeleteArticleMutation, DeleteArticleMutationVariables>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, options);
      }
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<DeleteArticleMutation, DeleteArticleMutationVariables>;
export const DuplicateArticleDocument = gql`
    mutation DuplicateArticle($id: ID!) {
  duplicateArticle(id: $id) {
    ...MutationArticle
  }
}
    ${MutationArticleFragmentDoc}`;
export type DuplicateArticleMutationFn = Apollo.MutationFunction<DuplicateArticleMutation, DuplicateArticleMutationVariables>;

/**
 * __useDuplicateArticleMutation__
 *
 * To run a mutation, you first call `useDuplicateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateArticleMutation, { data, loading, error }] = useDuplicateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateArticleMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateArticleMutation, DuplicateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateArticleMutation, DuplicateArticleMutationVariables>(DuplicateArticleDocument, options);
      }
export type DuplicateArticleMutationHookResult = ReturnType<typeof useDuplicateArticleMutation>;
export type DuplicateArticleMutationResult = Apollo.MutationResult<DuplicateArticleMutation>;
export type DuplicateArticleMutationOptions = Apollo.BaseMutationOptions<DuplicateArticleMutation, DuplicateArticleMutationVariables>;
export const ArticlePreviewLinkDocument = gql`
    query ArticlePreviewLink($id: ID!, $hours: Int!) {
  articlePreviewLink(id: $id, hours: $hours)
}
    `;

/**
 * __useArticlePreviewLinkQuery__
 *
 * To run a query within a React component, call `useArticlePreviewLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlePreviewLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlePreviewLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function useArticlePreviewLinkQuery(baseOptions: Apollo.QueryHookOptions<ArticlePreviewLinkQuery, ArticlePreviewLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlePreviewLinkQuery, ArticlePreviewLinkQueryVariables>(ArticlePreviewLinkDocument, options);
      }
export function useArticlePreviewLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlePreviewLinkQuery, ArticlePreviewLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlePreviewLinkQuery, ArticlePreviewLinkQueryVariables>(ArticlePreviewLinkDocument, options);
        }
export type ArticlePreviewLinkQueryHookResult = ReturnType<typeof useArticlePreviewLinkQuery>;
export type ArticlePreviewLinkLazyQueryHookResult = ReturnType<typeof useArticlePreviewLinkLazyQuery>;
export type ArticlePreviewLinkQueryResult = Apollo.QueryResult<ArticlePreviewLinkQuery, ArticlePreviewLinkQueryVariables>;
export const ArticleDocument = gql`
    query Article($id: ID!) {
  article(id: $id) {
    id
    shared
    draft {
      publishAt
    }
    pending {
      publishAt
    }
    published {
      publishedAt
      updatedAt
      publishAt
    }
    latest {
      publishedAt
      updatedAt
      publishAt
      revision
      slug
      preTitle
      title
      lead
      seoTitle
      image {
        ...ImageRef
      }
      tags
      url
      canonicalUrl
      properties {
        key
        value
        public
      }
      authors {
        ...AuthorRef
      }
      hideAuthor
      breaking
      socialMediaTitle
      socialMediaDescription
      socialMediaAuthors {
        ...AuthorRef
      }
      socialMediaImage {
        ...ImageRef
      }
      blocks {
        ...FullBlock
      }
    }
  }
}
    ${ImageRefFragmentDoc}
${AuthorRefFragmentDoc}
${FullBlockFragmentDoc}`;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const CreateSessionDocument = gql`
    mutation CreateSession($email: String!, $password: String!) {
  createSession(email: $email, password: $password) {
    user {
      email
      roles {
        ...FullUserRole
      }
    }
    token
  }
}
    ${FullUserRoleFragmentDoc}`;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
export const GetAuthProvidersDocument = gql`
    query GetAuthProviders($redirectUri: String!) {
  authProviders(redirectUri: $redirectUri) {
    name
    url
  }
}
    `;

/**
 * __useGetAuthProvidersQuery__
 *
 * To run a query within a React component, call `useGetAuthProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthProvidersQuery({
 *   variables: {
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useGetAuthProvidersQuery(baseOptions: Apollo.QueryHookOptions<GetAuthProvidersQuery, GetAuthProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthProvidersQuery, GetAuthProvidersQueryVariables>(GetAuthProvidersDocument, options);
      }
export function useGetAuthProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthProvidersQuery, GetAuthProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthProvidersQuery, GetAuthProvidersQueryVariables>(GetAuthProvidersDocument, options);
        }
export type GetAuthProvidersQueryHookResult = ReturnType<typeof useGetAuthProvidersQuery>;
export type GetAuthProvidersLazyQueryHookResult = ReturnType<typeof useGetAuthProvidersLazyQuery>;
export type GetAuthProvidersQueryResult = Apollo.QueryResult<GetAuthProvidersQuery, GetAuthProvidersQueryVariables>;
export const CreateSessionWithOAuth2CodeDocument = gql`
    mutation CreateSessionWithOAuth2Code($redirectUri: String!, $name: String!, $code: String!) {
  createSessionWithOAuth2Code(redirectUri: $redirectUri, name: $name, code: $code) {
    user {
      email
      roles {
        ...FullUserRole
      }
    }
    token
  }
}
    ${FullUserRoleFragmentDoc}`;
export type CreateSessionWithOAuth2CodeMutationFn = Apollo.MutationFunction<CreateSessionWithOAuth2CodeMutation, CreateSessionWithOAuth2CodeMutationVariables>;

/**
 * __useCreateSessionWithOAuth2CodeMutation__
 *
 * To run a mutation, you first call `useCreateSessionWithOAuth2CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionWithOAuth2CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionWithOAuth2CodeMutation, { data, loading, error }] = useCreateSessionWithOAuth2CodeMutation({
 *   variables: {
 *      redirectUri: // value for 'redirectUri'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateSessionWithOAuth2CodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionWithOAuth2CodeMutation, CreateSessionWithOAuth2CodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionWithOAuth2CodeMutation, CreateSessionWithOAuth2CodeMutationVariables>(CreateSessionWithOAuth2CodeDocument, options);
      }
export type CreateSessionWithOAuth2CodeMutationHookResult = ReturnType<typeof useCreateSessionWithOAuth2CodeMutation>;
export type CreateSessionWithOAuth2CodeMutationResult = Apollo.MutationResult<CreateSessionWithOAuth2CodeMutation>;
export type CreateSessionWithOAuth2CodeMutationOptions = Apollo.BaseMutationOptions<CreateSessionWithOAuth2CodeMutation, CreateSessionWithOAuth2CodeMutationVariables>;
export const CreateSessionWithJwtDocument = gql`
    mutation CreateSessionWithJWT($jwt: String!) {
  createSessionWithJWT(jwt: $jwt) {
    user {
      email
      roles {
        ...FullUserRole
      }
    }
    token
  }
}
    ${FullUserRoleFragmentDoc}`;
export type CreateSessionWithJwtMutationFn = Apollo.MutationFunction<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>;

/**
 * __useCreateSessionWithJwtMutation__
 *
 * To run a mutation, you first call `useCreateSessionWithJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionWithJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionWithJwtMutation, { data, loading, error }] = useCreateSessionWithJwtMutation({
 *   variables: {
 *      jwt: // value for 'jwt'
 *   },
 * });
 */
export function useCreateSessionWithJwtMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>(CreateSessionWithJwtDocument, options);
      }
export type CreateSessionWithJwtMutationHookResult = ReturnType<typeof useCreateSessionWithJwtMutation>;
export type CreateSessionWithJwtMutationResult = Apollo.MutationResult<CreateSessionWithJwtMutation>;
export type CreateSessionWithJwtMutationOptions = Apollo.BaseMutationOptions<CreateSessionWithJwtMutation, CreateSessionWithJwtMutationVariables>;
export const AuthorListDocument = gql`
    query AuthorList($filter: String, $cursor: ID, $take: Int, $skip: Int, $order: SortOrder, $sort: AuthorSort) {
  authors(filter: {name: $filter}, cursor: $cursor, take: $take, skip: $skip, order: $order, sort: $sort) {
    nodes {
      ...FullAuthor
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullAuthorFragmentDoc}`;

/**
 * __useAuthorListQuery__
 *
 * To run a query within a React component, call `useAuthorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAuthorListQuery(baseOptions?: Apollo.QueryHookOptions<AuthorListQuery, AuthorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorListQuery, AuthorListQueryVariables>(AuthorListDocument, options);
      }
export function useAuthorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorListQuery, AuthorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorListQuery, AuthorListQueryVariables>(AuthorListDocument, options);
        }
export type AuthorListQueryHookResult = ReturnType<typeof useAuthorListQuery>;
export type AuthorListLazyQueryHookResult = ReturnType<typeof useAuthorListLazyQuery>;
export type AuthorListQueryResult = Apollo.QueryResult<AuthorListQuery, AuthorListQueryVariables>;
export const AuthorDocument = gql`
    query Author($id: ID!) {
  author(id: $id) {
    ...FullAuthor
  }
}
    ${FullAuthorFragmentDoc}`;

/**
 * __useAuthorQuery__
 *
 * To run a query within a React component, call `useAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuthorQuery(baseOptions: Apollo.QueryHookOptions<AuthorQuery, AuthorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorQuery, AuthorQueryVariables>(AuthorDocument, options);
      }
export function useAuthorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorQuery, AuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorQuery, AuthorQueryVariables>(AuthorDocument, options);
        }
export type AuthorQueryHookResult = ReturnType<typeof useAuthorQuery>;
export type AuthorLazyQueryHookResult = ReturnType<typeof useAuthorLazyQuery>;
export type AuthorQueryResult = Apollo.QueryResult<AuthorQuery, AuthorQueryVariables>;
export const CreateAuthorDocument = gql`
    mutation CreateAuthor($input: AuthorInput!) {
  createAuthor(input: $input) {
    ...FullAuthor
  }
}
    ${FullAuthorFragmentDoc}`;
export type CreateAuthorMutationFn = Apollo.MutationFunction<CreateAuthorMutation, CreateAuthorMutationVariables>;

/**
 * __useCreateAuthorMutation__
 *
 * To run a mutation, you first call `useCreateAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthorMutation, { data, loading, error }] = useCreateAuthorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthorMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthorMutation, CreateAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthorMutation, CreateAuthorMutationVariables>(CreateAuthorDocument, options);
      }
export type CreateAuthorMutationHookResult = ReturnType<typeof useCreateAuthorMutation>;
export type CreateAuthorMutationResult = Apollo.MutationResult<CreateAuthorMutation>;
export type CreateAuthorMutationOptions = Apollo.BaseMutationOptions<CreateAuthorMutation, CreateAuthorMutationVariables>;
export const UpdateAuthorDocument = gql`
    mutation UpdateAuthor($id: ID!, $input: AuthorInput!) {
  updateAuthor(id: $id, input: $input) {
    ...FullAuthor
  }
}
    ${FullAuthorFragmentDoc}`;
export type UpdateAuthorMutationFn = Apollo.MutationFunction<UpdateAuthorMutation, UpdateAuthorMutationVariables>;

/**
 * __useUpdateAuthorMutation__
 *
 * To run a mutation, you first call `useUpdateAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthorMutation, { data, loading, error }] = useUpdateAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAuthorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuthorMutation, UpdateAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuthorMutation, UpdateAuthorMutationVariables>(UpdateAuthorDocument, options);
      }
export type UpdateAuthorMutationHookResult = ReturnType<typeof useUpdateAuthorMutation>;
export type UpdateAuthorMutationResult = Apollo.MutationResult<UpdateAuthorMutation>;
export type UpdateAuthorMutationOptions = Apollo.BaseMutationOptions<UpdateAuthorMutation, UpdateAuthorMutationVariables>;
export const DeleteAuthorDocument = gql`
    mutation DeleteAuthor($id: ID!) {
  deleteAuthor(id: $id) {
    ...FullAuthor
  }
}
    ${FullAuthorFragmentDoc}`;
export type DeleteAuthorMutationFn = Apollo.MutationFunction<DeleteAuthorMutation, DeleteAuthorMutationVariables>;

/**
 * __useDeleteAuthorMutation__
 *
 * To run a mutation, you first call `useDeleteAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuthorMutation, { data, loading, error }] = useDeleteAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAuthorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuthorMutation, DeleteAuthorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuthorMutation, DeleteAuthorMutationVariables>(DeleteAuthorDocument, options);
      }
export type DeleteAuthorMutationHookResult = ReturnType<typeof useDeleteAuthorMutation>;
export type DeleteAuthorMutationResult = Apollo.MutationResult<DeleteAuthorMutation>;
export type DeleteAuthorMutationOptions = Apollo.BaseMutationOptions<DeleteAuthorMutation, DeleteAuthorMutationVariables>;
export const RatingSystemDocument = gql`
    query RatingSystem {
  ratingSystem {
    id
    name
    answers {
      id
      type
      answer
      ratingSystemId
    }
  }
}
    `;

/**
 * __useRatingSystemQuery__
 *
 * To run a query within a React component, call `useRatingSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingSystemQuery({
 *   variables: {
 *   },
 * });
 */
export function useRatingSystemQuery(baseOptions?: Apollo.QueryHookOptions<RatingSystemQuery, RatingSystemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RatingSystemQuery, RatingSystemQueryVariables>(RatingSystemDocument, options);
      }
export function useRatingSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatingSystemQuery, RatingSystemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RatingSystemQuery, RatingSystemQueryVariables>(RatingSystemDocument, options);
        }
export type RatingSystemQueryHookResult = ReturnType<typeof useRatingSystemQuery>;
export type RatingSystemLazyQueryHookResult = ReturnType<typeof useRatingSystemLazyQuery>;
export type RatingSystemQueryResult = Apollo.QueryResult<RatingSystemQuery, RatingSystemQueryVariables>;
export const UpdateRatingSystemDocument = gql`
    mutation UpdateRatingSystem($ratingSystemId: ID!, $name: String, $answers: [UpdateCommentRatingSystemAnswer!]) {
  updateRatingSystem(ratingSystemId: $ratingSystemId, name: $name, answers: $answers) {
    id
    name
    answers {
      id
      type
      answer
      ratingSystemId
    }
  }
}
    `;
export type UpdateRatingSystemMutationFn = Apollo.MutationFunction<UpdateRatingSystemMutation, UpdateRatingSystemMutationVariables>;

/**
 * __useUpdateRatingSystemMutation__
 *
 * To run a mutation, you first call `useUpdateRatingSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatingSystemMutation, { data, loading, error }] = useUpdateRatingSystemMutation({
 *   variables: {
 *      ratingSystemId: // value for 'ratingSystemId'
 *      name: // value for 'name'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useUpdateRatingSystemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRatingSystemMutation, UpdateRatingSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRatingSystemMutation, UpdateRatingSystemMutationVariables>(UpdateRatingSystemDocument, options);
      }
export type UpdateRatingSystemMutationHookResult = ReturnType<typeof useUpdateRatingSystemMutation>;
export type UpdateRatingSystemMutationResult = Apollo.MutationResult<UpdateRatingSystemMutation>;
export type UpdateRatingSystemMutationOptions = Apollo.BaseMutationOptions<UpdateRatingSystemMutation, UpdateRatingSystemMutationVariables>;
export const CreateRatingSystemAnswerDocument = gql`
    mutation CreateRatingSystemAnswer($ratingSystemId: ID!, $type: RatingSystemType!, $answer: String) {
  createRatingSystemAnswer(ratingSystemId: $ratingSystemId, type: $type, answer: $answer) {
    answer
    id
    type
    ratingSystemId
  }
}
    `;
export type CreateRatingSystemAnswerMutationFn = Apollo.MutationFunction<CreateRatingSystemAnswerMutation, CreateRatingSystemAnswerMutationVariables>;

/**
 * __useCreateRatingSystemAnswerMutation__
 *
 * To run a mutation, you first call `useCreateRatingSystemAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingSystemAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingSystemAnswerMutation, { data, loading, error }] = useCreateRatingSystemAnswerMutation({
 *   variables: {
 *      ratingSystemId: // value for 'ratingSystemId'
 *      type: // value for 'type'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useCreateRatingSystemAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreateRatingSystemAnswerMutation, CreateRatingSystemAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRatingSystemAnswerMutation, CreateRatingSystemAnswerMutationVariables>(CreateRatingSystemAnswerDocument, options);
      }
export type CreateRatingSystemAnswerMutationHookResult = ReturnType<typeof useCreateRatingSystemAnswerMutation>;
export type CreateRatingSystemAnswerMutationResult = Apollo.MutationResult<CreateRatingSystemAnswerMutation>;
export type CreateRatingSystemAnswerMutationOptions = Apollo.BaseMutationOptions<CreateRatingSystemAnswerMutation, CreateRatingSystemAnswerMutationVariables>;
export const DeleteRatingSystemAnswerDocument = gql`
    mutation DeleteRatingSystemAnswer($answerId: ID!) {
  deleteRatingSystemAnswer(id: $answerId) {
    id
  }
}
    `;
export type DeleteRatingSystemAnswerMutationFn = Apollo.MutationFunction<DeleteRatingSystemAnswerMutation, DeleteRatingSystemAnswerMutationVariables>;

/**
 * __useDeleteRatingSystemAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteRatingSystemAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRatingSystemAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRatingSystemAnswerMutation, { data, loading, error }] = useDeleteRatingSystemAnswerMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useDeleteRatingSystemAnswerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRatingSystemAnswerMutation, DeleteRatingSystemAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRatingSystemAnswerMutation, DeleteRatingSystemAnswerMutationVariables>(DeleteRatingSystemAnswerDocument, options);
      }
export type DeleteRatingSystemAnswerMutationHookResult = ReturnType<typeof useDeleteRatingSystemAnswerMutation>;
export type DeleteRatingSystemAnswerMutationResult = Apollo.MutationResult<DeleteRatingSystemAnswerMutation>;
export type DeleteRatingSystemAnswerMutationOptions = Apollo.BaseMutationOptions<DeleteRatingSystemAnswerMutation, DeleteRatingSystemAnswerMutationVariables>;
export const CommentListDocument = gql`
    query CommentList($filter: CommentFilter, $cursor: ID, $take: Int, $skip: Int, $order: SortOrder, $sort: CommentSort) {
  comments(filter: $filter, cursor: $cursor, take: $take, skip: $skip, order: $order, sort: $sort) {
    nodes {
      ...FullComment
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullCommentFragmentDoc}`;

/**
 * __useCommentListQuery__
 *
 * To run a query within a React component, call `useCommentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCommentListQuery(baseOptions?: Apollo.QueryHookOptions<CommentListQuery, CommentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentListQuery, CommentListQueryVariables>(CommentListDocument, options);
      }
export function useCommentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentListQuery, CommentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentListQuery, CommentListQueryVariables>(CommentListDocument, options);
        }
export type CommentListQueryHookResult = ReturnType<typeof useCommentListQuery>;
export type CommentListLazyQueryHookResult = ReturnType<typeof useCommentListLazyQuery>;
export type CommentListQueryResult = Apollo.QueryResult<CommentListQuery, CommentListQueryVariables>;
export const CommentDocument = gql`
    query Comment($id: ID!) {
  comment(id: $id) {
    ...FullComment
  }
}
    ${FullCommentFragmentDoc}`;

/**
 * __useCommentQuery__
 *
 * To run a query within a React component, call `useCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentQuery(baseOptions: Apollo.QueryHookOptions<CommentQuery, CommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
      }
export function useCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentQuery, CommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
        }
export type CommentQueryHookResult = ReturnType<typeof useCommentQuery>;
export type CommentLazyQueryHookResult = ReturnType<typeof useCommentLazyQuery>;
export type CommentQueryResult = Apollo.QueryResult<CommentQuery, CommentQueryVariables>;
export const ApproveCommentDocument = gql`
    mutation ApproveComment($id: ID!) {
  approveComment(id: $id) {
    state
  }
}
    `;
export type ApproveCommentMutationFn = Apollo.MutationFunction<ApproveCommentMutation, ApproveCommentMutationVariables>;

/**
 * __useApproveCommentMutation__
 *
 * To run a mutation, you first call `useApproveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCommentMutation, { data, loading, error }] = useApproveCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveCommentMutation(baseOptions?: Apollo.MutationHookOptions<ApproveCommentMutation, ApproveCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveCommentMutation, ApproveCommentMutationVariables>(ApproveCommentDocument, options);
      }
export type ApproveCommentMutationHookResult = ReturnType<typeof useApproveCommentMutation>;
export type ApproveCommentMutationResult = Apollo.MutationResult<ApproveCommentMutation>;
export type ApproveCommentMutationOptions = Apollo.BaseMutationOptions<ApproveCommentMutation, ApproveCommentMutationVariables>;
export const RejectCommentDocument = gql`
    mutation RejectComment($id: ID!, $rejectionReason: CommentRejectionReason!) {
  rejectComment(id: $id, rejectionReason: $rejectionReason) {
    state
    rejectionReason
  }
}
    `;
export type RejectCommentMutationFn = Apollo.MutationFunction<RejectCommentMutation, RejectCommentMutationVariables>;

/**
 * __useRejectCommentMutation__
 *
 * To run a mutation, you first call `useRejectCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectCommentMutation, { data, loading, error }] = useRejectCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useRejectCommentMutation(baseOptions?: Apollo.MutationHookOptions<RejectCommentMutation, RejectCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectCommentMutation, RejectCommentMutationVariables>(RejectCommentDocument, options);
      }
export type RejectCommentMutationHookResult = ReturnType<typeof useRejectCommentMutation>;
export type RejectCommentMutationResult = Apollo.MutationResult<RejectCommentMutation>;
export type RejectCommentMutationOptions = Apollo.BaseMutationOptions<RejectCommentMutation, RejectCommentMutationVariables>;
export const RequestChangesOnCommentDocument = gql`
    mutation RequestChangesOnComment($id: ID!, $rejectionReason: CommentRejectionReason!) {
  requestChangesOnComment(id: $id, rejectionReason: $rejectionReason) {
    state
    rejectionReason
  }
}
    `;
export type RequestChangesOnCommentMutationFn = Apollo.MutationFunction<RequestChangesOnCommentMutation, RequestChangesOnCommentMutationVariables>;

/**
 * __useRequestChangesOnCommentMutation__
 *
 * To run a mutation, you first call `useRequestChangesOnCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangesOnCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangesOnCommentMutation, { data, loading, error }] = useRequestChangesOnCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rejectionReason: // value for 'rejectionReason'
 *   },
 * });
 */
export function useRequestChangesOnCommentMutation(baseOptions?: Apollo.MutationHookOptions<RequestChangesOnCommentMutation, RequestChangesOnCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestChangesOnCommentMutation, RequestChangesOnCommentMutationVariables>(RequestChangesOnCommentDocument, options);
      }
export type RequestChangesOnCommentMutationHookResult = ReturnType<typeof useRequestChangesOnCommentMutation>;
export type RequestChangesOnCommentMutationResult = Apollo.MutationResult<RequestChangesOnCommentMutation>;
export type RequestChangesOnCommentMutationOptions = Apollo.BaseMutationOptions<RequestChangesOnCommentMutation, RequestChangesOnCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation updateComment($id: ID!, $revision: CommentRevisionUpdateInput, $userID: ID, $guestUsername: String, $guestUserImageID: ID, $source: String, $tagIds: [ID!]) {
  updateComment(id: $id, revision: $revision, userID: $userID, guestUsername: $guestUsername, guestUserImageID: $guestUserImageID, source: $source, tagIds: $tagIds) {
    ...FullComment
  }
}
    ${FullCommentFragmentDoc}`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      revision: // value for 'revision'
 *      userID: // value for 'userID'
 *      guestUsername: // value for 'guestUsername'
 *      guestUserImageID: // value for 'guestUserImageID'
 *      source: // value for 'source'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($itemID: ID!, $itemType: CommentItemType!, $parentID: ID, $text: RichText, $tagIds: [ID!]) {
  createComment(itemID: $itemID, itemType: $itemType, parentID: $parentID, text: $text, tagIds: $tagIds) {
    id
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      itemID: // value for 'itemID'
 *      itemType: // value for 'itemType'
 *      parentID: // value for 'parentID'
 *      text: // value for 'text'
 *      tagIds: // value for 'tagIds'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($deleteCommentId: ID!) {
  deleteComment(id: $deleteCommentId) {
    id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      deleteCommentId: // value for 'deleteCommentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const ImageListDocument = gql`
    query ImageList($filter: String, $cursor: ID, $take: Int, $skip: Int) {
  images(filter: {title: $filter}, cursor: $cursor, take: $take, skip: $skip) {
    nodes {
      ...ImageRef
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${ImageRefFragmentDoc}`;

/**
 * __useImageListQuery__
 *
 * To run a query within a React component, call `useImageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useImageListQuery(baseOptions?: Apollo.QueryHookOptions<ImageListQuery, ImageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageListQuery, ImageListQueryVariables>(ImageListDocument, options);
      }
export function useImageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageListQuery, ImageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageListQuery, ImageListQueryVariables>(ImageListDocument, options);
        }
export type ImageListQueryHookResult = ReturnType<typeof useImageListQuery>;
export type ImageListLazyQueryHookResult = ReturnType<typeof useImageListLazyQuery>;
export type ImageListQueryResult = Apollo.QueryResult<ImageListQuery, ImageListQueryVariables>;
export const ImageDocument = gql`
    query Image($id: ID!) {
  image(id: $id) {
    ...FullImage
  }
}
    ${FullImageFragmentDoc}`;

/**
 * __useImageQuery__
 *
 * To run a query within a React component, call `useImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImageQuery(baseOptions: Apollo.QueryHookOptions<ImageQuery, ImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageQuery, ImageQueryVariables>(ImageDocument, options);
      }
export function useImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageQuery, ImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageQuery, ImageQueryVariables>(ImageDocument, options);
        }
export type ImageQueryHookResult = ReturnType<typeof useImageQuery>;
export type ImageLazyQueryHookResult = ReturnType<typeof useImageLazyQuery>;
export type ImageQueryResult = Apollo.QueryResult<ImageQuery, ImageQueryVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($input: UploadImageInput!) {
  uploadImage(input: $input) {
    ...ImageRef
  }
}
    ${ImageRefFragmentDoc}`;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const UpdateImageDocument = gql`
    mutation UpdateImage($id: ID!, $input: UpdateImageInput!) {
  updateImage(id: $id, input: $input) {
    ...FullImage
  }
}
    ${FullImageFragmentDoc}`;
export type UpdateImageMutationFn = Apollo.MutationFunction<UpdateImageMutation, UpdateImageMutationVariables>;

/**
 * __useUpdateImageMutation__
 *
 * To run a mutation, you first call `useUpdateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImageMutation, { data, loading, error }] = useUpdateImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateImageMutation, UpdateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateImageMutation, UpdateImageMutationVariables>(UpdateImageDocument, options);
      }
export type UpdateImageMutationHookResult = ReturnType<typeof useUpdateImageMutation>;
export type UpdateImageMutationResult = Apollo.MutationResult<UpdateImageMutation>;
export type UpdateImageMutationOptions = Apollo.BaseMutationOptions<UpdateImageMutation, UpdateImageMutationVariables>;
export const DeleteImageDocument = gql`
    mutation DeleteImage($id: ID!) {
  deleteImage(id: $id) {
    ...FullImage
  }
}
    ${FullImageFragmentDoc}`;
export type DeleteImageMutationFn = Apollo.MutationFunction<DeleteImageMutation, DeleteImageMutationVariables>;

/**
 * __useDeleteImageMutation__
 *
 * To run a mutation, you first call `useDeleteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImageMutation, { data, loading, error }] = useDeleteImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteImageMutation, DeleteImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteImageMutation, DeleteImageMutationVariables>(DeleteImageDocument, options);
      }
export type DeleteImageMutationHookResult = ReturnType<typeof useDeleteImageMutation>;
export type DeleteImageMutationResult = Apollo.MutationResult<DeleteImageMutation>;
export type DeleteImageMutationOptions = Apollo.BaseMutationOptions<DeleteImageMutation, DeleteImageMutationVariables>;
export const InvoicesDocument = gql`
    query Invoices($cursor: ID, $take: Int, $skip: Int, $filter: InvoiceFilter, $sort: InvoiceSort, $order: SortOrder) {
  invoices(cursor: $cursor, take: $take, skip: $skip, filter: $filter, sort: $sort, order: $order) {
    nodes {
      ...Invoice
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${InvoiceFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($updateInvoiceId: ID!, $input: InvoiceInput!) {
  updateInvoice(id: $updateInvoiceId, input: $input) {
    ...Invoice
  }
}
    ${InvoiceFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      updateInvoiceId: // value for 'updateInvoiceId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const MemberPlanListDocument = gql`
    query MemberPlanList($filter: String, $cursor: ID, $take: Int, $skip: Int) {
  memberPlans(filter: {name: $filter}, cursor: $cursor, take: $take, skip: $skip) {
    nodes {
      ...FullMemberPlan
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullMemberPlanFragmentDoc}`;

/**
 * __useMemberPlanListQuery__
 *
 * To run a query within a React component, call `useMemberPlanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPlanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPlanListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useMemberPlanListQuery(baseOptions?: Apollo.QueryHookOptions<MemberPlanListQuery, MemberPlanListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberPlanListQuery, MemberPlanListQueryVariables>(MemberPlanListDocument, options);
      }
export function useMemberPlanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberPlanListQuery, MemberPlanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberPlanListQuery, MemberPlanListQueryVariables>(MemberPlanListDocument, options);
        }
export type MemberPlanListQueryHookResult = ReturnType<typeof useMemberPlanListQuery>;
export type MemberPlanListLazyQueryHookResult = ReturnType<typeof useMemberPlanListLazyQuery>;
export type MemberPlanListQueryResult = Apollo.QueryResult<MemberPlanListQuery, MemberPlanListQueryVariables>;
export const MemberPlanDocument = gql`
    query MemberPlan($id: ID!) {
  memberPlan(id: $id) {
    ...FullMemberPlan
  }
}
    ${FullMemberPlanFragmentDoc}`;

/**
 * __useMemberPlanQuery__
 *
 * To run a query within a React component, call `useMemberPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberPlanQuery(baseOptions: Apollo.QueryHookOptions<MemberPlanQuery, MemberPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberPlanQuery, MemberPlanQueryVariables>(MemberPlanDocument, options);
      }
export function useMemberPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberPlanQuery, MemberPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberPlanQuery, MemberPlanQueryVariables>(MemberPlanDocument, options);
        }
export type MemberPlanQueryHookResult = ReturnType<typeof useMemberPlanQuery>;
export type MemberPlanLazyQueryHookResult = ReturnType<typeof useMemberPlanLazyQuery>;
export type MemberPlanQueryResult = Apollo.QueryResult<MemberPlanQuery, MemberPlanQueryVariables>;
export const CreateMemberPlanDocument = gql`
    mutation CreateMemberPlan($input: MemberPlanInput!) {
  createMemberPlan(input: $input) {
    ...FullMemberPlan
  }
}
    ${FullMemberPlanFragmentDoc}`;
export type CreateMemberPlanMutationFn = Apollo.MutationFunction<CreateMemberPlanMutation, CreateMemberPlanMutationVariables>;

/**
 * __useCreateMemberPlanMutation__
 *
 * To run a mutation, you first call `useCreateMemberPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberPlanMutation, { data, loading, error }] = useCreateMemberPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberPlanMutation(baseOptions?: Apollo.MutationHookOptions<CreateMemberPlanMutation, CreateMemberPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMemberPlanMutation, CreateMemberPlanMutationVariables>(CreateMemberPlanDocument, options);
      }
export type CreateMemberPlanMutationHookResult = ReturnType<typeof useCreateMemberPlanMutation>;
export type CreateMemberPlanMutationResult = Apollo.MutationResult<CreateMemberPlanMutation>;
export type CreateMemberPlanMutationOptions = Apollo.BaseMutationOptions<CreateMemberPlanMutation, CreateMemberPlanMutationVariables>;
export const UpdateMemberPlanDocument = gql`
    mutation UpdateMemberPlan($id: ID!, $input: MemberPlanInput!) {
  updateMemberPlan(id: $id, input: $input) {
    ...FullMemberPlan
  }
}
    ${FullMemberPlanFragmentDoc}`;
export type UpdateMemberPlanMutationFn = Apollo.MutationFunction<UpdateMemberPlanMutation, UpdateMemberPlanMutationVariables>;

/**
 * __useUpdateMemberPlanMutation__
 *
 * To run a mutation, you first call `useUpdateMemberPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberPlanMutation, { data, loading, error }] = useUpdateMemberPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberPlanMutation, UpdateMemberPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberPlanMutation, UpdateMemberPlanMutationVariables>(UpdateMemberPlanDocument, options);
      }
export type UpdateMemberPlanMutationHookResult = ReturnType<typeof useUpdateMemberPlanMutation>;
export type UpdateMemberPlanMutationResult = Apollo.MutationResult<UpdateMemberPlanMutation>;
export type UpdateMemberPlanMutationOptions = Apollo.BaseMutationOptions<UpdateMemberPlanMutation, UpdateMemberPlanMutationVariables>;
export const DeleteMemberPlanDocument = gql`
    mutation DeleteMemberPlan($id: ID!) {
  deleteMemberPlan(id: $id) {
    ...FullMemberPlan
  }
}
    ${FullMemberPlanFragmentDoc}`;
export type DeleteMemberPlanMutationFn = Apollo.MutationFunction<DeleteMemberPlanMutation, DeleteMemberPlanMutationVariables>;

/**
 * __useDeleteMemberPlanMutation__
 *
 * To run a mutation, you first call `useDeleteMemberPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberPlanMutation, { data, loading, error }] = useDeleteMemberPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberPlanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberPlanMutation, DeleteMemberPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMemberPlanMutation, DeleteMemberPlanMutationVariables>(DeleteMemberPlanDocument, options);
      }
export type DeleteMemberPlanMutationHookResult = ReturnType<typeof useDeleteMemberPlanMutation>;
export type DeleteMemberPlanMutationResult = Apollo.MutationResult<DeleteMemberPlanMutation>;
export type DeleteMemberPlanMutationOptions = Apollo.BaseMutationOptions<DeleteMemberPlanMutation, DeleteMemberPlanMutationVariables>;
export const NavigationListDocument = gql`
    query NavigationList {
  navigations {
    ...FullNavigation
  }
}
    ${FullNavigationFragmentDoc}`;

/**
 * __useNavigationListQuery__
 *
 * To run a query within a React component, call `useNavigationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationListQuery(baseOptions?: Apollo.QueryHookOptions<NavigationListQuery, NavigationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationListQuery, NavigationListQueryVariables>(NavigationListDocument, options);
      }
export function useNavigationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationListQuery, NavigationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationListQuery, NavigationListQueryVariables>(NavigationListDocument, options);
        }
export type NavigationListQueryHookResult = ReturnType<typeof useNavigationListQuery>;
export type NavigationListLazyQueryHookResult = ReturnType<typeof useNavigationListLazyQuery>;
export type NavigationListQueryResult = Apollo.QueryResult<NavigationListQuery, NavigationListQueryVariables>;
export const NavigationDocument = gql`
    query Navigation($id: ID!) {
  navigation(id: $id) {
    ...FullNavigation
  }
}
    ${FullNavigationFragmentDoc}`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNavigationQuery(baseOptions: Apollo.QueryHookOptions<NavigationQuery, NavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
      }
export function useNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
        }
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const CreateNavigationDocument = gql`
    mutation CreateNavigation($input: NavigationInput!) {
  createNavigation(input: $input) {
    ...FullNavigation
  }
}
    ${FullNavigationFragmentDoc}`;
export type CreateNavigationMutationFn = Apollo.MutationFunction<CreateNavigationMutation, CreateNavigationMutationVariables>;

/**
 * __useCreateNavigationMutation__
 *
 * To run a mutation, you first call `useCreateNavigationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNavigationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNavigationMutation, { data, loading, error }] = useCreateNavigationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNavigationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNavigationMutation, CreateNavigationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNavigationMutation, CreateNavigationMutationVariables>(CreateNavigationDocument, options);
      }
export type CreateNavigationMutationHookResult = ReturnType<typeof useCreateNavigationMutation>;
export type CreateNavigationMutationResult = Apollo.MutationResult<CreateNavigationMutation>;
export type CreateNavigationMutationOptions = Apollo.BaseMutationOptions<CreateNavigationMutation, CreateNavigationMutationVariables>;
export const UpdateNavigationDocument = gql`
    mutation UpdateNavigation($id: ID!, $input: NavigationInput!) {
  updateNavigation(id: $id, input: $input) {
    ...FullNavigation
  }
}
    ${FullNavigationFragmentDoc}`;
export type UpdateNavigationMutationFn = Apollo.MutationFunction<UpdateNavigationMutation, UpdateNavigationMutationVariables>;

/**
 * __useUpdateNavigationMutation__
 *
 * To run a mutation, you first call `useUpdateNavigationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNavigationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNavigationMutation, { data, loading, error }] = useUpdateNavigationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNavigationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNavigationMutation, UpdateNavigationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNavigationMutation, UpdateNavigationMutationVariables>(UpdateNavigationDocument, options);
      }
export type UpdateNavigationMutationHookResult = ReturnType<typeof useUpdateNavigationMutation>;
export type UpdateNavigationMutationResult = Apollo.MutationResult<UpdateNavigationMutation>;
export type UpdateNavigationMutationOptions = Apollo.BaseMutationOptions<UpdateNavigationMutation, UpdateNavigationMutationVariables>;
export const DeleteNavigationDocument = gql`
    mutation DeleteNavigation($id: ID!) {
  deleteNavigation(id: $id) {
    ...FullNavigation
  }
}
    ${FullNavigationFragmentDoc}`;
export type DeleteNavigationMutationFn = Apollo.MutationFunction<DeleteNavigationMutation, DeleteNavigationMutationVariables>;

/**
 * __useDeleteNavigationMutation__
 *
 * To run a mutation, you first call `useDeleteNavigationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNavigationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNavigationMutation, { data, loading, error }] = useDeleteNavigationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNavigationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNavigationMutation, DeleteNavigationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNavigationMutation, DeleteNavigationMutationVariables>(DeleteNavigationDocument, options);
      }
export type DeleteNavigationMutationHookResult = ReturnType<typeof useDeleteNavigationMutation>;
export type DeleteNavigationMutationResult = Apollo.MutationResult<DeleteNavigationMutation>;
export type DeleteNavigationMutationOptions = Apollo.BaseMutationOptions<DeleteNavigationMutation, DeleteNavigationMutationVariables>;
export const PageListDocument = gql`
    query PageList($filter: String, $cursor: ID, $take: Int, $skip: Int, $order: SortOrder, $sort: PageSort) {
  pages(filter: {title: $filter}, cursor: $cursor, take: $take, skip: $skip, order: $order, sort: $sort) {
    nodes {
      ...PageRef
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${PageRefFragmentDoc}`;

/**
 * __usePageListQuery__
 *
 * To run a query within a React component, call `usePageListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePageListQuery(baseOptions?: Apollo.QueryHookOptions<PageListQuery, PageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageListQuery, PageListQueryVariables>(PageListDocument, options);
      }
export function usePageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageListQuery, PageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageListQuery, PageListQueryVariables>(PageListDocument, options);
        }
export type PageListQueryHookResult = ReturnType<typeof usePageListQuery>;
export type PageListLazyQueryHookResult = ReturnType<typeof usePageListLazyQuery>;
export type PageListQueryResult = Apollo.QueryResult<PageListQuery, PageListQueryVariables>;
export const CreatePageDocument = gql`
    mutation CreatePage($input: PageInput!) {
  createPage(input: $input) {
    ...MutationPage
  }
}
    ${MutationPageFragmentDoc}`;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const UpdatePageDocument = gql`
    mutation UpdatePage($id: ID!, $input: PageInput!) {
  updatePage(id: $id, input: $input) {
    ...MutationPage
  }
}
    ${MutationPageFragmentDoc}`;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const PublishPageDocument = gql`
    mutation PublishPage($id: ID!, $publishAt: DateTime, $publishedAt: DateTime, $updatedAt: DateTime) {
  publishPage(id: $id, publishAt: $publishAt, publishedAt: $publishedAt, updatedAt: $updatedAt) {
    ...MutationPage
  }
}
    ${MutationPageFragmentDoc}`;
export type PublishPageMutationFn = Apollo.MutationFunction<PublishPageMutation, PublishPageMutationVariables>;

/**
 * __usePublishPageMutation__
 *
 * To run a mutation, you first call `usePublishPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPageMutation, { data, loading, error }] = usePublishPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publishAt: // value for 'publishAt'
 *      publishedAt: // value for 'publishedAt'
 *      updatedAt: // value for 'updatedAt'
 *   },
 * });
 */
export function usePublishPageMutation(baseOptions?: Apollo.MutationHookOptions<PublishPageMutation, PublishPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishPageMutation, PublishPageMutationVariables>(PublishPageDocument, options);
      }
export type PublishPageMutationHookResult = ReturnType<typeof usePublishPageMutation>;
export type PublishPageMutationResult = Apollo.MutationResult<PublishPageMutation>;
export type PublishPageMutationOptions = Apollo.BaseMutationOptions<PublishPageMutation, PublishPageMutationVariables>;
export const UnpublishPageDocument = gql`
    mutation UnpublishPage($id: ID!) {
  unpublishPage(id: $id) {
    ...MutationPage
  }
}
    ${MutationPageFragmentDoc}`;
export type UnpublishPageMutationFn = Apollo.MutationFunction<UnpublishPageMutation, UnpublishPageMutationVariables>;

/**
 * __useUnpublishPageMutation__
 *
 * To run a mutation, you first call `useUnpublishPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishPageMutation, { data, loading, error }] = useUnpublishPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpublishPageMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishPageMutation, UnpublishPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishPageMutation, UnpublishPageMutationVariables>(UnpublishPageDocument, options);
      }
export type UnpublishPageMutationHookResult = ReturnType<typeof useUnpublishPageMutation>;
export type UnpublishPageMutationResult = Apollo.MutationResult<UnpublishPageMutation>;
export type UnpublishPageMutationOptions = Apollo.BaseMutationOptions<UnpublishPageMutation, UnpublishPageMutationVariables>;
export const DeletePageDocument = gql`
    mutation DeletePage($id: ID!) {
  deletePage(id: $id) {
    ...MutationPage
  }
}
    ${MutationPageFragmentDoc}`;
export type DeletePageMutationFn = Apollo.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, options);
      }
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const DuplicatePageDocument = gql`
    mutation DuplicatePage($id: ID!) {
  duplicatePage(id: $id) {
    ...MutationPage
  }
}
    ${MutationPageFragmentDoc}`;
export type DuplicatePageMutationFn = Apollo.MutationFunction<DuplicatePageMutation, DuplicatePageMutationVariables>;

/**
 * __useDuplicatePageMutation__
 *
 * To run a mutation, you first call `useDuplicatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePageMutation, { data, loading, error }] = useDuplicatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicatePageMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePageMutation, DuplicatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicatePageMutation, DuplicatePageMutationVariables>(DuplicatePageDocument, options);
      }
export type DuplicatePageMutationHookResult = ReturnType<typeof useDuplicatePageMutation>;
export type DuplicatePageMutationResult = Apollo.MutationResult<DuplicatePageMutation>;
export type DuplicatePageMutationOptions = Apollo.BaseMutationOptions<DuplicatePageMutation, DuplicatePageMutationVariables>;
export const PagePreviewLinkDocument = gql`
    query PagePreviewLink($id: ID!, $hours: Int!) {
  pagePreviewLink(id: $id, hours: $hours)
}
    `;

/**
 * __usePagePreviewLinkQuery__
 *
 * To run a query within a React component, call `usePagePreviewLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagePreviewLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagePreviewLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hours: // value for 'hours'
 *   },
 * });
 */
export function usePagePreviewLinkQuery(baseOptions: Apollo.QueryHookOptions<PagePreviewLinkQuery, PagePreviewLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PagePreviewLinkQuery, PagePreviewLinkQueryVariables>(PagePreviewLinkDocument, options);
      }
export function usePagePreviewLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PagePreviewLinkQuery, PagePreviewLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PagePreviewLinkQuery, PagePreviewLinkQueryVariables>(PagePreviewLinkDocument, options);
        }
export type PagePreviewLinkQueryHookResult = ReturnType<typeof usePagePreviewLinkQuery>;
export type PagePreviewLinkLazyQueryHookResult = ReturnType<typeof usePagePreviewLinkLazyQuery>;
export type PagePreviewLinkQueryResult = Apollo.QueryResult<PagePreviewLinkQuery, PagePreviewLinkQueryVariables>;
export const PageDocument = gql`
    query Page($id: ID!) {
  page(id: $id) {
    id
    draft {
      publishAt
    }
    pending {
      publishAt
    }
    published {
      publishedAt
      updatedAt
      publishAt
    }
    latest {
      publishedAt
      updatedAt
      publishAt
      slug
      title
      description
      image {
        ...ImageRef
      }
      tags
      url
      properties {
        key
        value
        public
      }
      socialMediaTitle
      socialMediaDescription
      socialMediaImage {
        ...ImageRef
      }
      blocks {
        ...FullBlock
      }
    }
  }
}
    ${ImageRefFragmentDoc}
${FullBlockFragmentDoc}`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PaymentProviderListDocument = gql`
    query PaymentProviderList {
  paymentProviders {
    ...FullPaymentProvider
  }
}
    ${FullPaymentProviderFragmentDoc}`;

/**
 * __usePaymentProviderListQuery__
 *
 * To run a query within a React component, call `usePaymentProviderListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProviderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProviderListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentProviderListQuery(baseOptions?: Apollo.QueryHookOptions<PaymentProviderListQuery, PaymentProviderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentProviderListQuery, PaymentProviderListQueryVariables>(PaymentProviderListDocument, options);
      }
export function usePaymentProviderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentProviderListQuery, PaymentProviderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentProviderListQuery, PaymentProviderListQueryVariables>(PaymentProviderListDocument, options);
        }
export type PaymentProviderListQueryHookResult = ReturnType<typeof usePaymentProviderListQuery>;
export type PaymentProviderListLazyQueryHookResult = ReturnType<typeof usePaymentProviderListLazyQuery>;
export type PaymentProviderListQueryResult = Apollo.QueryResult<PaymentProviderListQuery, PaymentProviderListQueryVariables>;
export const PaymentMethodListDocument = gql`
    query PaymentMethodList {
  paymentMethods {
    ...FullPaymentMethod
  }
}
    ${FullPaymentMethodFragmentDoc}`;

/**
 * __usePaymentMethodListQuery__
 *
 * To run a query within a React component, call `usePaymentMethodListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentMethodListQuery(baseOptions?: Apollo.QueryHookOptions<PaymentMethodListQuery, PaymentMethodListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodListQuery, PaymentMethodListQueryVariables>(PaymentMethodListDocument, options);
      }
export function usePaymentMethodListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodListQuery, PaymentMethodListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodListQuery, PaymentMethodListQueryVariables>(PaymentMethodListDocument, options);
        }
export type PaymentMethodListQueryHookResult = ReturnType<typeof usePaymentMethodListQuery>;
export type PaymentMethodListLazyQueryHookResult = ReturnType<typeof usePaymentMethodListLazyQuery>;
export type PaymentMethodListQueryResult = Apollo.QueryResult<PaymentMethodListQuery, PaymentMethodListQueryVariables>;
export const PaymentMethodDocument = gql`
    query PaymentMethod($id: ID!) {
  paymentMethod(id: $id) {
    ...FullPaymentMethod
  }
}
    ${FullPaymentMethodFragmentDoc}`;

/**
 * __usePaymentMethodQuery__
 *
 * To run a query within a React component, call `usePaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<PaymentMethodQuery, PaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentMethodQuery, PaymentMethodQueryVariables>(PaymentMethodDocument, options);
      }
export function usePaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentMethodQuery, PaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentMethodQuery, PaymentMethodQueryVariables>(PaymentMethodDocument, options);
        }
export type PaymentMethodQueryHookResult = ReturnType<typeof usePaymentMethodQuery>;
export type PaymentMethodLazyQueryHookResult = ReturnType<typeof usePaymentMethodLazyQuery>;
export type PaymentMethodQueryResult = Apollo.QueryResult<PaymentMethodQuery, PaymentMethodQueryVariables>;
export const CreatePaymentMethodDocument = gql`
    mutation CreatePaymentMethod($input: PaymentMethodInput!) {
  createPaymentMethod(input: $input) {
    ...FullPaymentMethod
  }
}
    ${FullPaymentMethodFragmentDoc}`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, options);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const UpdatePaymentMethodDocument = gql`
    mutation UpdatePaymentMethod($id: ID!, $input: PaymentMethodInput!) {
  updatePaymentMethod(id: $id, input: $input) {
    ...FullPaymentMethod
  }
}
    ${FullPaymentMethodFragmentDoc}`;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument, options);
      }
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation DeletePaymentMethod($id: ID!) {
  deletePaymentMethod(id: $id) {
    ...FullPaymentMethod
  }
}
    ${FullPaymentMethodFragmentDoc}`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, options);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const PeerProfileDocument = gql`
    query PeerProfile {
  peerProfile {
    ...FullPeerProfile
  }
}
    ${FullPeerProfileFragmentDoc}`;

/**
 * __usePeerProfileQuery__
 *
 * To run a query within a React component, call `usePeerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeerProfileQuery(baseOptions?: Apollo.QueryHookOptions<PeerProfileQuery, PeerProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PeerProfileQuery, PeerProfileQueryVariables>(PeerProfileDocument, options);
      }
export function usePeerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeerProfileQuery, PeerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PeerProfileQuery, PeerProfileQueryVariables>(PeerProfileDocument, options);
        }
export type PeerProfileQueryHookResult = ReturnType<typeof usePeerProfileQuery>;
export type PeerProfileLazyQueryHookResult = ReturnType<typeof usePeerProfileLazyQuery>;
export type PeerProfileQueryResult = Apollo.QueryResult<PeerProfileQuery, PeerProfileQueryVariables>;
export const RemotePeerProfileDocument = gql`
    query RemotePeerProfile($hostURL: String!, $token: String!) {
  remotePeerProfile(hostURL: $hostURL, token: $token) {
    ...FullPeerProfile
  }
}
    ${FullPeerProfileFragmentDoc}`;

/**
 * __useRemotePeerProfileQuery__
 *
 * To run a query within a React component, call `useRemotePeerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemotePeerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemotePeerProfileQuery({
 *   variables: {
 *      hostURL: // value for 'hostURL'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRemotePeerProfileQuery(baseOptions: Apollo.QueryHookOptions<RemotePeerProfileQuery, RemotePeerProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemotePeerProfileQuery, RemotePeerProfileQueryVariables>(RemotePeerProfileDocument, options);
      }
export function useRemotePeerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemotePeerProfileQuery, RemotePeerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemotePeerProfileQuery, RemotePeerProfileQueryVariables>(RemotePeerProfileDocument, options);
        }
export type RemotePeerProfileQueryHookResult = ReturnType<typeof useRemotePeerProfileQuery>;
export type RemotePeerProfileLazyQueryHookResult = ReturnType<typeof useRemotePeerProfileLazyQuery>;
export type RemotePeerProfileQueryResult = Apollo.QueryResult<RemotePeerProfileQuery, RemotePeerProfileQueryVariables>;
export const UpdatePeerProfileDocument = gql`
    mutation UpdatePeerProfile($input: PeerProfileInput!) {
  updatePeerProfile(input: $input) {
    ...FullPeerProfile
  }
}
    ${FullPeerProfileFragmentDoc}`;
export type UpdatePeerProfileMutationFn = Apollo.MutationFunction<UpdatePeerProfileMutation, UpdatePeerProfileMutationVariables>;

/**
 * __useUpdatePeerProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePeerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeerProfileMutation, { data, loading, error }] = useUpdatePeerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeerProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePeerProfileMutation, UpdatePeerProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePeerProfileMutation, UpdatePeerProfileMutationVariables>(UpdatePeerProfileDocument, options);
      }
export type UpdatePeerProfileMutationHookResult = ReturnType<typeof useUpdatePeerProfileMutation>;
export type UpdatePeerProfileMutationResult = Apollo.MutationResult<UpdatePeerProfileMutation>;
export type UpdatePeerProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePeerProfileMutation, UpdatePeerProfileMutationVariables>;
export const PeerListDocument = gql`
    query PeerList {
  peers {
    ...PeerWithProfile
  }
}
    ${PeerWithProfileFragmentDoc}`;

/**
 * __usePeerListQuery__
 *
 * To run a query within a React component, call `usePeerListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeerListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeerListQuery(baseOptions?: Apollo.QueryHookOptions<PeerListQuery, PeerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PeerListQuery, PeerListQueryVariables>(PeerListDocument, options);
      }
export function usePeerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeerListQuery, PeerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PeerListQuery, PeerListQueryVariables>(PeerListDocument, options);
        }
export type PeerListQueryHookResult = ReturnType<typeof usePeerListQuery>;
export type PeerListLazyQueryHookResult = ReturnType<typeof usePeerListLazyQuery>;
export type PeerListQueryResult = Apollo.QueryResult<PeerListQuery, PeerListQueryVariables>;
export const PeerDocument = gql`
    query Peer($id: ID!) {
  peer(id: $id) {
    ...PeerRef
  }
}
    ${PeerRefFragmentDoc}`;

/**
 * __usePeerQuery__
 *
 * To run a query within a React component, call `usePeerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePeerQuery(baseOptions: Apollo.QueryHookOptions<PeerQuery, PeerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PeerQuery, PeerQueryVariables>(PeerDocument, options);
      }
export function usePeerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeerQuery, PeerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PeerQuery, PeerQueryVariables>(PeerDocument, options);
        }
export type PeerQueryHookResult = ReturnType<typeof usePeerQuery>;
export type PeerLazyQueryHookResult = ReturnType<typeof usePeerLazyQuery>;
export type PeerQueryResult = Apollo.QueryResult<PeerQuery, PeerQueryVariables>;
export const CreatePeerDocument = gql`
    mutation CreatePeer($input: CreatePeerInput!) {
  createPeer(input: $input) {
    ...PeerRef
  }
}
    ${PeerRefFragmentDoc}`;
export type CreatePeerMutationFn = Apollo.MutationFunction<CreatePeerMutation, CreatePeerMutationVariables>;

/**
 * __useCreatePeerMutation__
 *
 * To run a mutation, you first call `useCreatePeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeerMutation, { data, loading, error }] = useCreatePeerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePeerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePeerMutation, CreatePeerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePeerMutation, CreatePeerMutationVariables>(CreatePeerDocument, options);
      }
export type CreatePeerMutationHookResult = ReturnType<typeof useCreatePeerMutation>;
export type CreatePeerMutationResult = Apollo.MutationResult<CreatePeerMutation>;
export type CreatePeerMutationOptions = Apollo.BaseMutationOptions<CreatePeerMutation, CreatePeerMutationVariables>;
export const UpdatePeerDocument = gql`
    mutation UpdatePeer($id: ID!, $input: UpdatePeerInput!) {
  updatePeer(id: $id, input: $input) {
    ...PeerRef
  }
}
    ${PeerRefFragmentDoc}`;
export type UpdatePeerMutationFn = Apollo.MutationFunction<UpdatePeerMutation, UpdatePeerMutationVariables>;

/**
 * __useUpdatePeerMutation__
 *
 * To run a mutation, you first call `useUpdatePeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeerMutation, { data, loading, error }] = useUpdatePeerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePeerMutation, UpdatePeerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePeerMutation, UpdatePeerMutationVariables>(UpdatePeerDocument, options);
      }
export type UpdatePeerMutationHookResult = ReturnType<typeof useUpdatePeerMutation>;
export type UpdatePeerMutationResult = Apollo.MutationResult<UpdatePeerMutation>;
export type UpdatePeerMutationOptions = Apollo.BaseMutationOptions<UpdatePeerMutation, UpdatePeerMutationVariables>;
export const DeletePeerDocument = gql`
    mutation DeletePeer($id: ID!) {
  deletePeer(id: $id) {
    ...PeerRef
  }
}
    ${PeerRefFragmentDoc}`;
export type DeletePeerMutationFn = Apollo.MutationFunction<DeletePeerMutation, DeletePeerMutationVariables>;

/**
 * __useDeletePeerMutation__
 *
 * To run a mutation, you first call `useDeletePeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeerMutation, { data, loading, error }] = useDeletePeerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePeerMutation(baseOptions?: Apollo.MutationHookOptions<DeletePeerMutation, DeletePeerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePeerMutation, DeletePeerMutationVariables>(DeletePeerDocument, options);
      }
export type DeletePeerMutationHookResult = ReturnType<typeof useDeletePeerMutation>;
export type DeletePeerMutationResult = Apollo.MutationResult<DeletePeerMutation>;
export type DeletePeerMutationOptions = Apollo.BaseMutationOptions<DeletePeerMutation, DeletePeerMutationVariables>;
export const CreatePollDocument = gql`
    mutation CreatePoll($opensAt: DateTime, $closedAt: DateTime, $question: String) {
  createPoll(opensAt: $opensAt, closedAt: $closedAt, question: $question) {
    id
    question
    opensAt
    closedAt
    answers {
      id
      pollId
      answer
    }
  }
}
    `;
export type CreatePollMutationFn = Apollo.MutationFunction<CreatePollMutation, CreatePollMutationVariables>;

/**
 * __useCreatePollMutation__
 *
 * To run a mutation, you first call `useCreatePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollMutation, { data, loading, error }] = useCreatePollMutation({
 *   variables: {
 *      opensAt: // value for 'opensAt'
 *      closedAt: // value for 'closedAt'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useCreatePollMutation(baseOptions?: Apollo.MutationHookOptions<CreatePollMutation, CreatePollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePollMutation, CreatePollMutationVariables>(CreatePollDocument, options);
      }
export type CreatePollMutationHookResult = ReturnType<typeof useCreatePollMutation>;
export type CreatePollMutationResult = Apollo.MutationResult<CreatePollMutation>;
export type CreatePollMutationOptions = Apollo.BaseMutationOptions<CreatePollMutation, CreatePollMutationVariables>;
export const UpdatePollDocument = gql`
    mutation UpdatePoll($pollId: ID!, $opensAt: DateTime, $closedAt: DateTime, $question: String, $answers: [UpdatePollAnswer!], $externalVoteSources: [UpdatePollExternalVoteSources!]) {
  updatePoll(pollId: $pollId, opensAt: $opensAt, closedAt: $closedAt, question: $question, answers: $answers, externalVoteSources: $externalVoteSources) {
    id
    question
    opensAt
    closedAt
    answers {
      id
      pollId
      answer
      votes
    }
    externalVoteSources {
      ...PollExternalVoteSource
    }
  }
}
    ${PollExternalVoteSourceFragmentDoc}`;
export type UpdatePollMutationFn = Apollo.MutationFunction<UpdatePollMutation, UpdatePollMutationVariables>;

/**
 * __useUpdatePollMutation__
 *
 * To run a mutation, you first call `useUpdatePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePollMutation, { data, loading, error }] = useUpdatePollMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      opensAt: // value for 'opensAt'
 *      closedAt: // value for 'closedAt'
 *      question: // value for 'question'
 *      answers: // value for 'answers'
 *      externalVoteSources: // value for 'externalVoteSources'
 *   },
 * });
 */
export function useUpdatePollMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePollMutation, UpdatePollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePollMutation, UpdatePollMutationVariables>(UpdatePollDocument, options);
      }
export type UpdatePollMutationHookResult = ReturnType<typeof useUpdatePollMutation>;
export type UpdatePollMutationResult = Apollo.MutationResult<UpdatePollMutation>;
export type UpdatePollMutationOptions = Apollo.BaseMutationOptions<UpdatePollMutation, UpdatePollMutationVariables>;
export const DeletePollDocument = gql`
    mutation DeletePoll($deletePollId: ID!) {
  deletePoll(id: $deletePollId) {
    id
  }
}
    `;
export type DeletePollMutationFn = Apollo.MutationFunction<DeletePollMutation, DeletePollMutationVariables>;

/**
 * __useDeletePollMutation__
 *
 * To run a mutation, you first call `useDeletePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePollMutation, { data, loading, error }] = useDeletePollMutation({
 *   variables: {
 *      deletePollId: // value for 'deletePollId'
 *   },
 * });
 */
export function useDeletePollMutation(baseOptions?: Apollo.MutationHookOptions<DeletePollMutation, DeletePollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePollMutation, DeletePollMutationVariables>(DeletePollDocument, options);
      }
export type DeletePollMutationHookResult = ReturnType<typeof useDeletePollMutation>;
export type DeletePollMutationResult = Apollo.MutationResult<DeletePollMutation>;
export type DeletePollMutationOptions = Apollo.BaseMutationOptions<DeletePollMutation, DeletePollMutationVariables>;
export const CreatePollAnswerDocument = gql`
    mutation CreatePollAnswer($pollId: ID!, $answer: String) {
  createPollAnswer(pollId: $pollId, answer: $answer) {
    answer
    id
    pollId
  }
}
    `;
export type CreatePollAnswerMutationFn = Apollo.MutationFunction<CreatePollAnswerMutation, CreatePollAnswerMutationVariables>;

/**
 * __useCreatePollAnswerMutation__
 *
 * To run a mutation, you first call `useCreatePollAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollAnswerMutation, { data, loading, error }] = useCreatePollAnswerMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useCreatePollAnswerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePollAnswerMutation, CreatePollAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePollAnswerMutation, CreatePollAnswerMutationVariables>(CreatePollAnswerDocument, options);
      }
export type CreatePollAnswerMutationHookResult = ReturnType<typeof useCreatePollAnswerMutation>;
export type CreatePollAnswerMutationResult = Apollo.MutationResult<CreatePollAnswerMutation>;
export type CreatePollAnswerMutationOptions = Apollo.BaseMutationOptions<CreatePollAnswerMutation, CreatePollAnswerMutationVariables>;
export const DeletePollAnswerDocument = gql`
    mutation DeletePollAnswer($deletePollAnswerId: ID!) {
  deletePollAnswer(id: $deletePollAnswerId) {
    id
  }
}
    `;
export type DeletePollAnswerMutationFn = Apollo.MutationFunction<DeletePollAnswerMutation, DeletePollAnswerMutationVariables>;

/**
 * __useDeletePollAnswerMutation__
 *
 * To run a mutation, you first call `useDeletePollAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePollAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePollAnswerMutation, { data, loading, error }] = useDeletePollAnswerMutation({
 *   variables: {
 *      deletePollAnswerId: // value for 'deletePollAnswerId'
 *   },
 * });
 */
export function useDeletePollAnswerMutation(baseOptions?: Apollo.MutationHookOptions<DeletePollAnswerMutation, DeletePollAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePollAnswerMutation, DeletePollAnswerMutationVariables>(DeletePollAnswerDocument, options);
      }
export type DeletePollAnswerMutationHookResult = ReturnType<typeof useDeletePollAnswerMutation>;
export type DeletePollAnswerMutationResult = Apollo.MutationResult<DeletePollAnswerMutation>;
export type DeletePollAnswerMutationOptions = Apollo.BaseMutationOptions<DeletePollAnswerMutation, DeletePollAnswerMutationVariables>;
export const CreatePollExternalVoteSourceDocument = gql`
    mutation CreatePollExternalVoteSource($pollId: ID!, $source: String) {
  createPollExternalVoteSource(pollId: $pollId, source: $source) {
    ...PollExternalVoteSource
  }
}
    ${PollExternalVoteSourceFragmentDoc}`;
export type CreatePollExternalVoteSourceMutationFn = Apollo.MutationFunction<CreatePollExternalVoteSourceMutation, CreatePollExternalVoteSourceMutationVariables>;

/**
 * __useCreatePollExternalVoteSourceMutation__
 *
 * To run a mutation, you first call `useCreatePollExternalVoteSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollExternalVoteSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollExternalVoteSourceMutation, { data, loading, error }] = useCreatePollExternalVoteSourceMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useCreatePollExternalVoteSourceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePollExternalVoteSourceMutation, CreatePollExternalVoteSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePollExternalVoteSourceMutation, CreatePollExternalVoteSourceMutationVariables>(CreatePollExternalVoteSourceDocument, options);
      }
export type CreatePollExternalVoteSourceMutationHookResult = ReturnType<typeof useCreatePollExternalVoteSourceMutation>;
export type CreatePollExternalVoteSourceMutationResult = Apollo.MutationResult<CreatePollExternalVoteSourceMutation>;
export type CreatePollExternalVoteSourceMutationOptions = Apollo.BaseMutationOptions<CreatePollExternalVoteSourceMutation, CreatePollExternalVoteSourceMutationVariables>;
export const DeletePollExternalVoteSourceDocument = gql`
    mutation DeletePollExternalVoteSource($deletePollExternalVoteSourceId: ID!) {
  deletePollExternalVoteSource(id: $deletePollExternalVoteSourceId) {
    ...PollExternalVoteSource
  }
}
    ${PollExternalVoteSourceFragmentDoc}`;
export type DeletePollExternalVoteSourceMutationFn = Apollo.MutationFunction<DeletePollExternalVoteSourceMutation, DeletePollExternalVoteSourceMutationVariables>;

/**
 * __useDeletePollExternalVoteSourceMutation__
 *
 * To run a mutation, you first call `useDeletePollExternalVoteSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePollExternalVoteSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePollExternalVoteSourceMutation, { data, loading, error }] = useDeletePollExternalVoteSourceMutation({
 *   variables: {
 *      deletePollExternalVoteSourceId: // value for 'deletePollExternalVoteSourceId'
 *   },
 * });
 */
export function useDeletePollExternalVoteSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePollExternalVoteSourceMutation, DeletePollExternalVoteSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePollExternalVoteSourceMutation, DeletePollExternalVoteSourceMutationVariables>(DeletePollExternalVoteSourceDocument, options);
      }
export type DeletePollExternalVoteSourceMutationHookResult = ReturnType<typeof useDeletePollExternalVoteSourceMutation>;
export type DeletePollExternalVoteSourceMutationResult = Apollo.MutationResult<DeletePollExternalVoteSourceMutation>;
export type DeletePollExternalVoteSourceMutationOptions = Apollo.BaseMutationOptions<DeletePollExternalVoteSourceMutation, DeletePollExternalVoteSourceMutationVariables>;
export const PollsDocument = gql`
    query Polls($cursor: ID, $take: Int, $skip: Int, $filter: PollFilter, $sort: PollSort, $order: SortOrder) {
  polls(cursor: $cursor, take: $take, skip: $skip, filter: $filter, sort: $sort, order: $order) {
    nodes {
      id
      question
      opensAt
      closedAt
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __usePollsQuery__
 *
 * To run a query within a React component, call `usePollsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePollsQuery(baseOptions?: Apollo.QueryHookOptions<PollsQuery, PollsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PollsQuery, PollsQueryVariables>(PollsDocument, options);
      }
export function usePollsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PollsQuery, PollsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PollsQuery, PollsQueryVariables>(PollsDocument, options);
        }
export type PollsQueryHookResult = ReturnType<typeof usePollsQuery>;
export type PollsLazyQueryHookResult = ReturnType<typeof usePollsLazyQuery>;
export type PollsQueryResult = Apollo.QueryResult<PollsQuery, PollsQueryVariables>;
export const PollDocument = gql`
    query Poll($pollId: ID) {
  poll(id: $pollId) {
    id
    question
    opensAt
    closedAt
    answers {
      id
      pollId
      answer
      votes
    }
    externalVoteSources {
      ...PollExternalVoteSource
    }
  }
}
    ${PollExternalVoteSourceFragmentDoc}`;

/**
 * __usePollQuery__
 *
 * To run a query within a React component, call `usePollQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollQuery({
 *   variables: {
 *      pollId: // value for 'pollId'
 *   },
 * });
 */
export function usePollQuery(baseOptions?: Apollo.QueryHookOptions<PollQuery, PollQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PollQuery, PollQueryVariables>(PollDocument, options);
      }
export function usePollLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PollQuery, PollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PollQuery, PollQueryVariables>(PollDocument, options);
        }
export type PollQueryHookResult = ReturnType<typeof usePollQuery>;
export type PollLazyQueryHookResult = ReturnType<typeof usePollLazyQuery>;
export type PollQueryResult = Apollo.QueryResult<PollQuery, PollQueryVariables>;
export const SettingListDocument = gql`
    query SettingList {
  settings {
    id
    name
    value
    settingRestriction {
      maxValue
      minValue
      inputLength
      allowedValues {
        stringChoice
        boolChoice
      }
    }
  }
}
    `;

/**
 * __useSettingListQuery__
 *
 * To run a query within a React component, call `useSettingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingListQuery(baseOptions?: Apollo.QueryHookOptions<SettingListQuery, SettingListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingListQuery, SettingListQueryVariables>(SettingListDocument, options);
      }
export function useSettingListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingListQuery, SettingListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingListQuery, SettingListQueryVariables>(SettingListDocument, options);
        }
export type SettingListQueryHookResult = ReturnType<typeof useSettingListQuery>;
export type SettingListLazyQueryHookResult = ReturnType<typeof useSettingListLazyQuery>;
export type SettingListQueryResult = Apollo.QueryResult<SettingListQuery, SettingListQueryVariables>;
export const UpdateSettingListDocument = gql`
    mutation UpdateSettingList($input: [UpdateSettingArgs!]) {
  updateSettingList(value: $input) {
    value
  }
}
    `;
export type UpdateSettingListMutationFn = Apollo.MutationFunction<UpdateSettingListMutation, UpdateSettingListMutationVariables>;

/**
 * __useUpdateSettingListMutation__
 *
 * To run a mutation, you first call `useUpdateSettingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingListMutation, { data, loading, error }] = useUpdateSettingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSettingListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingListMutation, UpdateSettingListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingListMutation, UpdateSettingListMutationVariables>(UpdateSettingListDocument, options);
      }
export type UpdateSettingListMutationHookResult = ReturnType<typeof useUpdateSettingListMutation>;
export type UpdateSettingListMutationResult = Apollo.MutationResult<UpdateSettingListMutation>;
export type UpdateSettingListMutationOptions = Apollo.BaseMutationOptions<UpdateSettingListMutation, UpdateSettingListMutationVariables>;
export const SubscriptionListDocument = gql`
    query SubscriptionList($filter: SubscriptionFilter, $cursor: ID, $take: Int, $skip: Int, $order: SortOrder, $sort: SubscriptionSort) {
  subscriptions(filter: $filter, cursor: $cursor, take: $take, skip: $skip, order: $order, sort: $sort) {
    nodes {
      ...FullSubscription
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullSubscriptionFragmentDoc}`;

/**
 * __useSubscriptionListQuery__
 *
 * To run a query within a React component, call `useSubscriptionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSubscriptionListQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionListQuery, SubscriptionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionListQuery, SubscriptionListQueryVariables>(SubscriptionListDocument, options);
      }
export function useSubscriptionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionListQuery, SubscriptionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionListQuery, SubscriptionListQueryVariables>(SubscriptionListDocument, options);
        }
export type SubscriptionListQueryHookResult = ReturnType<typeof useSubscriptionListQuery>;
export type SubscriptionListLazyQueryHookResult = ReturnType<typeof useSubscriptionListLazyQuery>;
export type SubscriptionListQueryResult = Apollo.QueryResult<SubscriptionListQuery, SubscriptionListQueryVariables>;
export const SubscriptionDocument = gql`
    query Subscription($id: ID!) {
  subscription(id: $id) {
    ...FullSubscription
  }
}
    ${FullSubscriptionFragmentDoc}`;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
      }
export function useSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionQueryResult = Apollo.QueryResult<SubscriptionQuery, SubscriptionQueryVariables>;
export const SubscriptionsAsCsvDocument = gql`
    query SubscriptionsAsCsv($filter: SubscriptionFilter) {
  subscriptionsAsCsv(filter: $filter)
}
    `;

/**
 * __useSubscriptionsAsCsvQuery__
 *
 * To run a query within a React component, call `useSubscriptionsAsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsAsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsAsCsvQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubscriptionsAsCsvQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionsAsCsvQuery, SubscriptionsAsCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsAsCsvQuery, SubscriptionsAsCsvQueryVariables>(SubscriptionsAsCsvDocument, options);
      }
export function useSubscriptionsAsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsAsCsvQuery, SubscriptionsAsCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsAsCsvQuery, SubscriptionsAsCsvQueryVariables>(SubscriptionsAsCsvDocument, options);
        }
export type SubscriptionsAsCsvQueryHookResult = ReturnType<typeof useSubscriptionsAsCsvQuery>;
export type SubscriptionsAsCsvLazyQueryHookResult = ReturnType<typeof useSubscriptionsAsCsvLazyQuery>;
export type SubscriptionsAsCsvQueryResult = Apollo.QueryResult<SubscriptionsAsCsvQuery, SubscriptionsAsCsvQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: SubscriptionInput!) {
  createSubscription(input: $input) {
    ...FullSubscription
  }
}
    ${FullSubscriptionFragmentDoc}`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($id: ID!, $input: SubscriptionInput!) {
  updateSubscription(id: $id, input: $input) {
    ...FullSubscription
  }
}
    ${FullSubscriptionFragmentDoc}`;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($id: ID!) {
  deleteSubscription(id: $id) {
    ...FullSubscription
  }
}
    ${FullSubscriptionFragmentDoc}`;
export type DeleteSubscriptionMutationFn = Apollo.MutationFunction<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;

/**
 * __useDeleteSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscriptionMutation, { data, loading, error }] = useDeleteSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>(DeleteSubscriptionDocument, options);
      }
export type DeleteSubscriptionMutationHookResult = ReturnType<typeof useDeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationResult = Apollo.MutationResult<DeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;
export const TagListDocument = gql`
    query TagList($filter: TagFilter, $cursor: ID, $take: Int, $skip: Int, $order: SortOrder, $sort: TagSort) {
  tags(filter: $filter, cursor: $cursor, take: $take, skip: $skip, order: $order, sort: $sort) {
    nodes {
      id
      tag
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __useTagListQuery__
 *
 * To run a query within a React component, call `useTagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTagListQuery(baseOptions?: Apollo.QueryHookOptions<TagListQuery, TagListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagListQuery, TagListQueryVariables>(TagListDocument, options);
      }
export function useTagListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagListQuery, TagListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagListQuery, TagListQueryVariables>(TagListDocument, options);
        }
export type TagListQueryHookResult = ReturnType<typeof useTagListQuery>;
export type TagListLazyQueryHookResult = ReturnType<typeof useTagListLazyQuery>;
export type TagListQueryResult = Apollo.QueryResult<TagListQuery, TagListQueryVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($tag: String, $type: TagType!) {
  createTag(tag: $tag, type: $type) {
    id
    tag
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($id: ID!, $tag: String) {
  updateTag(id: $id, tag: $tag) {
    id
    tag
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(id: $id) {
    id
    tag
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const TokenListDocument = gql`
    query TokenList {
  tokens {
    ...TokenRef
  }
}
    ${TokenRefFragmentDoc}`;

/**
 * __useTokenListQuery__
 *
 * To run a query within a React component, call `useTokenListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenListQuery(baseOptions?: Apollo.QueryHookOptions<TokenListQuery, TokenListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenListQuery, TokenListQueryVariables>(TokenListDocument, options);
      }
export function useTokenListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenListQuery, TokenListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenListQuery, TokenListQueryVariables>(TokenListDocument, options);
        }
export type TokenListQueryHookResult = ReturnType<typeof useTokenListQuery>;
export type TokenListLazyQueryHookResult = ReturnType<typeof useTokenListLazyQuery>;
export type TokenListQueryResult = Apollo.QueryResult<TokenListQuery, TokenListQueryVariables>;
export const CreateTokenDocument = gql`
    mutation CreateToken($input: TokenInput!) {
  createToken(input: $input) {
    id
    name
    token
  }
}
    `;
export type CreateTokenMutationFn = Apollo.MutationFunction<CreateTokenMutation, CreateTokenMutationVariables>;

/**
 * __useCreateTokenMutation__
 *
 * To run a mutation, you first call `useCreateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTokenMutation, { data, loading, error }] = useCreateTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateTokenMutation, CreateTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTokenMutation, CreateTokenMutationVariables>(CreateTokenDocument, options);
      }
export type CreateTokenMutationHookResult = ReturnType<typeof useCreateTokenMutation>;
export type CreateTokenMutationResult = Apollo.MutationResult<CreateTokenMutation>;
export type CreateTokenMutationOptions = Apollo.BaseMutationOptions<CreateTokenMutation, CreateTokenMutationVariables>;
export const DeleteTokenDocument = gql`
    mutation DeleteToken($id: ID!) {
  deleteToken(id: $id) {
    id
    name
    token
  }
}
    `;
export type DeleteTokenMutationFn = Apollo.MutationFunction<DeleteTokenMutation, DeleteTokenMutationVariables>;

/**
 * __useDeleteTokenMutation__
 *
 * To run a mutation, you first call `useDeleteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTokenMutation, { data, loading, error }] = useDeleteTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTokenMutation, DeleteTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTokenMutation, DeleteTokenMutationVariables>(DeleteTokenDocument, options);
      }
export type DeleteTokenMutationHookResult = ReturnType<typeof useDeleteTokenMutation>;
export type DeleteTokenMutationResult = Apollo.MutationResult<DeleteTokenMutation>;
export type DeleteTokenMutationOptions = Apollo.BaseMutationOptions<DeleteTokenMutation, DeleteTokenMutationVariables>;
export const UserListDocument = gql`
    query UserList($filter: String, $cursor: ID, $take: Int, $skip: Int, $order: SortOrder, $sort: UserSort) {
  users(filter: {text: $filter}, cursor: $cursor, take: $take, skip: $skip, order: $order, sort: $sort) {
    nodes {
      ...FullUser
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserListQuery(baseOptions?: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
      }
export function useUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
        }
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: UserInput!, $password: String!) {
  createUser(input: $input, password: $password) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UserInput!) {
  updateUser(id: $id, input: $input) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation ResetUserPassword($id: ID!, $password: String!) {
  resetUserPassword(id: $id, password: $password) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const SendWebsiteLoginDocument = gql`
    mutation SendWebsiteLogin($email: String!) {
  sendWebsiteLogin(email: $email)
}
    `;
export type SendWebsiteLoginMutationFn = Apollo.MutationFunction<SendWebsiteLoginMutation, SendWebsiteLoginMutationVariables>;

/**
 * __useSendWebsiteLoginMutation__
 *
 * To run a mutation, you first call `useSendWebsiteLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWebsiteLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWebsiteLoginMutation, { data, loading, error }] = useSendWebsiteLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendWebsiteLoginMutation(baseOptions?: Apollo.MutationHookOptions<SendWebsiteLoginMutation, SendWebsiteLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWebsiteLoginMutation, SendWebsiteLoginMutationVariables>(SendWebsiteLoginDocument, options);
      }
export type SendWebsiteLoginMutationHookResult = ReturnType<typeof useSendWebsiteLoginMutation>;
export type SendWebsiteLoginMutationResult = Apollo.MutationResult<SendWebsiteLoginMutation>;
export type SendWebsiteLoginMutationOptions = Apollo.BaseMutationOptions<SendWebsiteLoginMutation, SendWebsiteLoginMutationVariables>;
export const UserRoleListDocument = gql`
    query UserRoleList($filter: String, $cursor: ID, $take: Int, $skip: Int) {
  userRoles(filter: {name: $filter}, cursor: $cursor, take: $take, skip: $skip) {
    nodes {
      ...FullUserRole
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${FullUserRoleFragmentDoc}`;

/**
 * __useUserRoleListQuery__
 *
 * To run a query within a React component, call `useUserRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useUserRoleListQuery(baseOptions?: Apollo.QueryHookOptions<UserRoleListQuery, UserRoleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRoleListQuery, UserRoleListQueryVariables>(UserRoleListDocument, options);
      }
export function useUserRoleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleListQuery, UserRoleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRoleListQuery, UserRoleListQueryVariables>(UserRoleListDocument, options);
        }
export type UserRoleListQueryHookResult = ReturnType<typeof useUserRoleListQuery>;
export type UserRoleListLazyQueryHookResult = ReturnType<typeof useUserRoleListLazyQuery>;
export type UserRoleListQueryResult = Apollo.QueryResult<UserRoleListQuery, UserRoleListQueryVariables>;
export const PermissionListDocument = gql`
    query PermissionList {
  permissions {
    ...FullPermission
  }
}
    ${FullPermissionFragmentDoc}`;

/**
 * __usePermissionListQuery__
 *
 * To run a query within a React component, call `usePermissionListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionListQuery(baseOptions?: Apollo.QueryHookOptions<PermissionListQuery, PermissionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionListQuery, PermissionListQueryVariables>(PermissionListDocument, options);
      }
export function usePermissionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionListQuery, PermissionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionListQuery, PermissionListQueryVariables>(PermissionListDocument, options);
        }
export type PermissionListQueryHookResult = ReturnType<typeof usePermissionListQuery>;
export type PermissionListLazyQueryHookResult = ReturnType<typeof usePermissionListLazyQuery>;
export type PermissionListQueryResult = Apollo.QueryResult<PermissionListQuery, PermissionListQueryVariables>;
export const UserRoleDocument = gql`
    query UserRole($id: ID!) {
  userRole(id: $id) {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions: Apollo.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
      }
export function useUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options);
        }
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>;
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>;
export type UserRoleQueryResult = Apollo.QueryResult<UserRoleQuery, UserRoleQueryVariables>;
export const CreateUserRoleDocument = gql`
    mutation CreateUserRole($input: UserRoleInput!) {
  createUserRole(input: $input) {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;
export type CreateUserRoleMutationFn = Apollo.MutationFunction<CreateUserRoleMutation, CreateUserRoleMutationVariables>;

/**
 * __useCreateUserRoleMutation__
 *
 * To run a mutation, you first call `useCreateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRoleMutation, { data, loading, error }] = useCreateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserRoleMutation, CreateUserRoleMutationVariables>(CreateUserRoleDocument, options);
      }
export type CreateUserRoleMutationHookResult = ReturnType<typeof useCreateUserRoleMutation>;
export type CreateUserRoleMutationResult = Apollo.MutationResult<CreateUserRoleMutation>;
export type CreateUserRoleMutationOptions = Apollo.BaseMutationOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($id: ID!, $input: UserRoleInput!) {
  updateUserRole(id: $id, input: $input) {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const DeleteUserRoleDocument = gql`
    mutation DeleteUserRole($id: ID!) {
  deleteUserRole(id: $id) {
    ...FullUserRole
  }
}
    ${FullUserRoleFragmentDoc}`;
export type DeleteUserRoleMutationFn = Apollo.MutationFunction<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>;

/**
 * __useDeleteUserRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserRoleMutation, { data, loading, error }] = useDeleteUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>(DeleteUserRoleDocument, options);
      }
export type DeleteUserRoleMutationHookResult = ReturnType<typeof useDeleteUserRoleMutation>;
export type DeleteUserRoleMutationResult = Apollo.MutationResult<DeleteUserRoleMutation>;
export type DeleteUserRoleMutationOptions = Apollo.BaseMutationOptions<DeleteUserRoleMutation, DeleteUserRoleMutationVariables>;